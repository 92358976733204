import React, { useState, useEffect } from 'react'
import { MaterialTableCustom } from '../components/table/material-table-custom'
import { makeStyles, alpha } from '@material-ui/core/styles'
import perfilService from '../Services/Perfil/perfil-service'
import MenuComponent from '../components/menu/menu-component'
import { Paper, Grid, CircularProgress, TablePagination,Box } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/core/styles'
import CustomAlert from '../components/alert/CustomAlert'
import authService from '../Services/auth/auth-service'

const useStyles = makeStyles((theme) => ({
	button: {
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	perfilBox: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		marginBottom: theme.spacing(2)
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	paper: {
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'column',
		padding: '15px 0px 15px',
		borderRadius: 10,
		width: '100%',
	},
	tituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
		marginBottom: '5px'
	},
	mensagemStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
	},
	subtituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#7F7F7F',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px'
	},
	subtituloPaperStyle: {
		fontFamily: 'Mont, sans-serif',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px'
	},
}))

export default function PerfilOuro() {
	const classes = useStyles();
	let [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(true);
	const [perfilInfo, setPerfilInfo] = useState({});
	const mobile = useMediaQuery(useTheme().breakpoints.down(800));
	const [carregandoTable, setCarregandoTable] = useState(false);
	const [loadDados, setLoadDados] = useState(false);
	const [abrirAlert, setAbrirAlert] = useState(false);
	const [mensagemAlert, setMensagemAlert] = useState('');
	const [tipoAlert, setTipoAlert] = useState('error');
	const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;

	useEffect(() => {
		perfil();
	}, [mobile]);

	const totalDados = perfilInfo.tabelaDePrecos ? perfilInfo.tabelaDePrecos.length : 0;

	const perfil = async () => {
		try {
			setLoadDados(true);

			const info = await perfilService.getInformacoesPerfilOuro();

			if (info) {
				setPerfilInfo({
					nome: info.perfil.nome,
					valoresServico: info.perfil.tabelaDePrecosCodigo || '',
					categoria: info.perfil.tabelaDePrecosDescricao || '',
					tabelaDePrecos: info.tabelaDePrecos || []
				});
			}
		} catch (error) {
			setMensagemAlert({ title: 'Erro!', body: 'Erro ao buscar informações de perfil:', error });
			setTipoAlert('error');
			setAbrirAlert(true);
			setLoadDados(false);
		} finally {
			setLoading(false);
			setLoadDados(false);
		}
	};

	const dadosPaginados = perfilInfo.tabelaDePrecos
		? perfilInfo.tabelaDePrecos.slice(page * pageSize, page * pageSize + pageSize)
		: [];

	const mudarTamanhoPagina = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
	};

	const mudarPagina = (event, newPage) => {
		setPage(newPage);
	};

	return (
		<>
			<MenuComponent />
			<CustomAlert
				open={abrirAlert}
				message={mensagemAlert}
				type={tipoAlert}
				onClose={() => setAbrirAlert(false)}
			/>
			{tecnicoPrestador == null || tecnicoPrestador == false ? (
				<div style={{ margin: 30 }}>
					<h3 className={classes.tituloStyle}>Financeiro</h3>
					<h4 className={classes.subtituloStyle}>
						<span
							onClick={() => window.location.href = '/agenda'}
							className={classes.linkStyle}
							style={{ cursor: 'pointer' }}
						>
							Home
						</span> {'>'} Financeiro
					</h4>

					<Paper className={classes.paper}>
						{loadDados ? (
							<Grid container alignItems="center" justifyContent="center">
								<CircularProgress color="primary" />
							</Grid>
						) : (
							<>
								<Grid container spacing={2} style={{ padding: 15 }}>
									<Grid item xs={12} className={classes.subtituloPaperStyle}>
										<strong>Razão Social:</strong> {perfilInfo.nome}
									</Grid>
									<Grid item xs={12} className={classes.subtituloPaperStyle}>
										<strong>Categoria:</strong> {perfilInfo.categoria}
									</Grid>
									<Grid item xs={12} className={classes.subtituloPaperStyle}>
										<strong>Valores Serviço:</strong> {perfilInfo.valoresServico}
									</Grid>
								</Grid>
							</>
						)}
					</Paper>

					<Paper className={classes.paper}>
						<div style={{ marginBottom: '16px', overflowX: 'auto' }}>
							<MaterialTableCustom
								title=""
								columns={[
									{ title: 'Produto', field: 'produto' },
									{ title: 'Descrição', field: 'descricao' },
									{
										title: 'Valor',
										field: 'valor',
										render: (rowData) => <span>R$ {rowData.valor.toFixed(2)}</span>,
										hidden: mobile
									}
								]}
								data={dadosPaginados}
								options={{
									pageSize: pageSize,
									pageSizeOptions: [10, 25, 50, 100],
								}}
								localization={{
									body: {
										emptyDataSourceMessage: carregandoTable || loading ? (
											<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
												<CircularProgress />
												<span style={{ marginLeft: '10px' }}>Carregando...</span>
											</div>
										) : 'Nenhum registro para exibir'
									},
									toolbar: {
										searchTooltip: 'Pesquisar',
										searchPlaceholder: 'Pesquisar'
									},
									pagination: {
										labelRowsSelect: 'linhas',
										labelDisplayedRows: '{from}-{to} de {count}',
										firstTooltip: 'Primeira página',
										previousTooltip: 'Página anterior',
										nextTooltip: 'Próxima página',
										lastTooltip: 'Última página'
									}
								}}
								components={{
									Pagination: () => (
										<TablePagination
											style={{ padding: '0 0px' }}
											onPageChange={mudarPagina}
											count={perfilInfo.tabelaDePrecos ? perfilInfo.tabelaDePrecos.length : 0}
											page={page}
											labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
											rowsPerPage={pageSize}
											labelRowsPerPage="Linhas"
											rowsPerPageOptions={[10, 25, 50, 100]}
											onRowsPerPageChange={mudarTamanhoPagina}
										/>
									),
								}}
								detailPanel={[
									(rowData) => ({
										disabled: !mobile,
										render: () => (
											<div hidden={!mobile}>
												<Box display="flex" justifyContent="center" alignItems="center" p={2}>
													<Paper style={{ width: '100%', textAlign: 'center', padding: '10px', backgroundColor: '#fff' }}>
														<strong>Preço:</strong> R$ {rowData.valor.toFixed(2)}
													</Paper>
												</Box>
											</div>
										),
									}),
								]}
							/>
						</div>
					</Paper>
				</div >
			) : (
				<div style={{ margin: 30 }}>
					<h3 className={classes.tituloStyle}>Financeiro</h3>
					<h4 className={classes.subtituloStyle}>
						<span
							onClick={() => window.location.href = '/agenda'}
							className={classes.linkStyle}
							style={{ cursor: 'pointer' }}
						>
							Home
						</span> {'>'} Financeiro
					</h4>

					<Paper className={classes.paper}>
						<Grid container justifyContent="center">
							<h2 className={classes.mensagemStyle} style={{ padding: 10 }}>Você não tem permissão para acessar esta página!</h2>
						</Grid>
					</Paper>
				</div>
			)}
		</>
	);
}
