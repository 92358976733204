import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class ComunicacaoServico {
  async comunicacaoLastTrack(id) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(
        `${_configAPITecnico.URI_API}/lasttrack/${id}`,
        {
          method: 'GET',
          headers: _headerToken,
        }
      );

      if (response.status === 200) return await response.json();
      return {
        error: true,
        message: await response.json(),
      };
    } catch (error) {
      console.log(error);
    }
  }

  async rastreamentoIndividual(serial) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(
        `${_configAPITecnico.URI_API}/lasttrack/equipamentosListarChips/${serial}`,
        {
          method: 'GET',
          headers: _headerToken,
        }
      );

      if (response.status === 200) return await response.json();
      return {
        error: true,
        message: await response.json(),
      };
    } catch (error) {
      console.log(error);
    }
  }
}
export default new ComunicacaoServico();
