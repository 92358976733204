import React, { useState, useEffect, useCallback, Children, cloneElement } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import { dateStrings } from '../../util/dateStrings';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import momentTimezone from 'moment-timezone';
import { LinearProgressBlue } from '../../components/progress/LinearProgressBlue';
import authService from '../../Services/auth/auth-service';
import agendaService from '../../Services/Agenda/agenda-service';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTheme,
  useMediaQuery,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import DailyAgenda from './daily-agenda';

export const useStyles = makeStyles((theme) => ({
  dateCell: {
    borderRight: '0.5px solid #DDDDDD',
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
  },
}));

const EventCalendar = () => {

  const { WEEK_DAYS, MONTHS } = dateStrings;
  const theme = useTheme();
  const classes = useStyles();
  const localizer = momentLocalizer(moment);
  const [loading, setLoading] = useState(false);
  const [eventos, setEventos] = useState([]);
  const [eventosAgenda, setEventosAgenda] = useState([]);
  const [eventosAgendaDoDia, setEventosAgendaDoDia] = useState([]);
  const [dataAgenda, setDataAgenda] = useState(new Date());
  const [ordensServicoAtrasadas, setOrdensServicoAtrasadas] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [totalEventosAtrasados, setTotalEventosAtrasados] = useState(0);

  const formats = {
    monthHeaderFormat: (day) => `${MONTHS[day.getMonth()]} de ${day.getFullYear()}`,
    dayHeaderFormat: (day) =>
      `${WEEK_DAYS[day.getDay()]}, ${day.getDate()} de ${MONTHS[day.getMonth()]}`,
    dayRangeHeaderFormat: ({ start, end }) =>
      `${start.getDate()} à ${end.getDate()} de ${MONTHS[start.getMonth()]}`,
    agendaDateFormat: (day) =>
      `${WEEK_DAYS[day.getDay()].slice(0, 3)}, ${day.getDate()} de ${MONTHS[day.getMonth()]}`
  };

  const messages = {
    allDay: 'Todos os dias',
    previous: '< Anterior',
    next: 'Próximo >',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'Não foram encontradas atividades nesse período.',
  };

  useEffect(() => {
    loadCalendario();
    loadEventosTotal();
    setOpenDialog();
  }, []);

  useEffect(() => {
    setOpenDialog(false);
  }, [dataAgenda]);

  useEffect(() => {
    verificaServicosPendentes(); // Contar ordens de serviço pendentes
    verificaEventosDoDia(); // Verifica se há eventos para o dia atual
  }, [eventos, eventosAgenda]); // Monitora a lista de eventos para chamadas posteriores

  const verificaEventosDoDia = () => {
    let atividadesDoDia = eventos.filter((evento) => {
      const dataEvento = moment(evento.previsaoInicio).add(-3, 'hours').toDate();
      dataEvento.setHours(0, 0, 0, 0);
      dataAgenda.setHours(0, 0, 0, 0);

      return (
        dataEvento.getTime() === dataAgenda.getTime()
      );
    });

    atividadesDoDia.sort(function (a, b) {
      const dataHoraA = a.inicio ? new Date(a.inicio) : new Date(a.previsaoInicio);
      const dataHoraB = b.inicio ? new Date(b.inicio) : new Date(b.previsaoInicio);

      return dataHoraA - dataHoraB;
    });

    setEventosAgendaDoDia(atividadesDoDia);
  }

  const verificaServicosPendentes = () => {
    const today = moment().startOf('day');
    const pastDueOrders = eventosAgenda.filter(
      (evento) => evento.status === 3 && moment(evento.end).isBefore(today)
    );

    setOrdensServicoAtrasadas(pastDueOrders.length);
  };

  //Filtra atividades que correspondem ao dia que foi selecionado
  const abreAtividadesAgenda = useCallback(async ({ action, slots }) => {
    if (action !== 'click') return;

    const selectedDate = slots[0];
    selectedDate.setHours(0, 0, 0, 0);

    let atividadesDoDia = eventos.filter((evento) => {
      const previsaoInicio = moment(evento.previsaoInicio).add(-3, 'hours').toDate();

      if (previsaoInicio == null) return false; // Ignora eventos sem previsaoInicio

      const dataEvento = new Date(previsaoInicio);
      dataEvento.setHours(0, 0, 0, 0);

      return dataEvento.getTime() === selectedDate.getTime();
    });

    // Ordena atividades em ordem crescente pela data de inicio, caso ela seja null a 
    // ordenação é feita utilizando a previsaoInicio
    atividadesDoDia.sort(function (a, b) {
      const dataHoraA = a.inicio ? new Date(a.inicio) : new Date(a.previsaoInicio);
      const dataHoraB = b.inicio ? new Date(b.inicio) : new Date(b.previsaoInicio);

      return dataHoraA - dataHoraB;
    });

    setEventosAgendaDoDia(atividadesDoDia);
    setDataAgenda(selectedDate);
  }, [eventos]);

  const dayPropGetter = useCallback(
    (date) => {
      const dayEvents = eventosAgenda.filter((evento) =>
        moment(evento.start).isSame(date, 'day')
      );

      return {
        ...(dayEvents.length > 0 && {
          className: 'event-day',
          style: {
            backgroundColor: '#209BDE',
            color: 'white',
            cursor: 'pointer'
          },
        }),
      };
    },
    [eventosAgenda]
  );

  const eventPropGetter = (event) => {
    return {
      style: {
        display: 'none',
      },
    };
  };

  const handleEventClickForMobile = (dataSelecionada) => {
    if (!mobile) return;

    abreAtividadesAgenda({ action: 'click', slots: [dataSelecionada] });
  };

  const EventWrapper = ({ children, value, onSelectSlot }) => {
    const dayEvents = eventosAgenda.filter(
      (evento) => moment(evento.start).isSame(value, 'day')
    );

    const wrappedChildren = cloneElement(Children.only(children), {
      onTouchEnd: () => onSelectSlot({ action: 'click', slots: [value] }),
      style: {
        className: `${children}`
      }
    });

    if (dayEvents.length > 0) {
      return (
        <div onClick={handleEventClickForMobile.bind(this, value)} className={`${classes.dateCell}`}
          style={{ backgroundColor: '#209BDE', cursor: 'pointer' }}>
          {wrappedChildren}
          <p style={{ color: 'white' }}>{dayEvents.length} <span>Serv.</span></p>
        </div>
      );
    } else {
      return (
        <div onClick={handleEventClickForMobile.bind(this, value)} className={`${classes.dateCell}`}
          style={{ cursor: 'pointer' }}>
          {wrappedChildren}
        </div>
      );
    }
  }

  const handleNavigate = async (e) => {
    const inicio = moment(e).format('YYYY-MM-01T00:00:00');
    const fim = moment(e).format('YYYY-MM-') + moment(e).daysInMonth() + 'T23:59:59';

    await consultaEventos(inicio, fim);
  };

  const consultaEventosTotal = async () => {
    setLoading(true);
    let areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    let eventosResult = await agendaService.AgendaTecnicoTotal(areaAtendimentoID);

    setTotalEventosAtrasados(eventosResult);

    if (eventosResult > 0) {
      setOpenDialog(true);
    }
    setLoading(false);
  };

  const consultaEventos = async (dtIni, dtFim) => {
    try {
      setLoading(true);
      let areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

      let eventosResult = await agendaService.AgendaTecnico(areaAtendimentoID, dtIni, dtFim);

      if (eventosResult) {
        let _eventos = [];
        eventosResult.map((e) => {
          let evento = {
            id: e.atividadeID,
            title: e.assunto,
            status: e.status,
            start: moment(e.previsaoInicio).add(-3, 'hours').toDate(),
            end: moment(e.previsaoTermino).add(-3, 'hours').toDate(),
            cliente: e.nomeCliente,
            rastreador: e.numSerieRastreadorInstalado,
            placa: e.veiculoIdName,
            na: e.na,
            servico: e.servico,
          };

          _eventos.push(evento);
        });
        setEventosAgenda(_eventos);
        setEventos(eventosResult);
        verificaEventosDoDia();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const inicioFimMes = () => {
    const inicio = momentTimezone()
      .startOf('month')
      .add(-3, 'hours')
      .format('YYYY-MM-DD HH:mm:ss');

    const fim = momentTimezone()
      .endOf('month')
      .add(-3, 'hours')
      .format('YYYY-MM-DD HH:mm:ss');

    return { inicio, fim };
  };

  const loadCalendario = async () => {
    const { inicio, fim } = inicioFimMes();
    await consultaEventos(inicio, fim);
  };

  const loadEventosTotal = async () => {
    await consultaEventosTotal();
  };

  // Customizando o header da data
  const myCustomMonthDateHeader = ({ label }) => {
    return (
      <div>
        {label}
      </div>
    );
  };

  // sobrescrevendo a classe do toolbar para aplicar responsividade em dispositivos menores
  class CustomToolbar extends Toolbar {
    render() {
      return (
        <div className="rbc-toolbar">
          <span className="rbc-btn-group">
            <button type="button" onClick={() => this.navigate('PREV')}>
              {'< Anterior'}
            </button>
            <button type="button" onClick={() => this.navigate('TODAY')}>
              {'Hoje'}
            </button>
            <button type="button" onClick={() => this.navigate('NEXT')}>
              {'Próximo >'}
            </button>
          </span>
          <span className="rbc-toolbar-label" style={mobile ? { width: '100%', margin: '10px 0' } : {}}>
            {this.props.label}
          </span>
        </div>
      );
    }
  }

  return (
    <div>
      {loading && <LinearProgressBlue style={{ marginBottom: 20 }} />}

      <Grid container spacing={2}>
        <Grid item lg={7} xs={12}>
          <Calendar
            localizer={localizer}
            events={eventosAgenda}
            defaultView="month"
            messages={messages}
            formats={formats}
            dayPropGetter={dayPropGetter}
            eventPropGetter={eventPropGetter}
            style={{ height: 500 }}
            onSelectSlot={abreAtividadesAgenda}
            selectable={true}
            onNavigate={loading ? () => { } : handleNavigate}
            components={{
              dateCellWrapper: (props) => (
                <EventWrapper {...props} onSelectSlot={abreAtividadesAgenda} />
              ),
              toolbar: CustomToolbar,
              month: {
                dateHeader: myCustomMonthDateHeader,
              },
            }}
          />
        </Grid>
        <Grid item lg={5} xs={12}>
          <DailyAgenda eventos={eventosAgendaDoDia} dataSelecionada={dataAgenda} loading={loading} />
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Serviço Atrasado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Há {totalEventosAtrasados} eventos atrasados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default EventCalendar;
