import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import {
    TextField, useMediaQuery, IconButton,
    useTheme, Box, InputAdornment,
    CircularProgress, Paper,
    Grid, Typography, debounce
} from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import auditoriaService from '../Services/Auditoria/auditoria-service';
import BlueButton from '../components/button/BlueButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import authService from '../Services/auth/auth-service';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';
import CustomTablePagination from '../components/pagination/pagination-component';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import LoginIcon from '@mui/icons-material/Login';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0 80px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px 40px',
        },
    },
    searchIcon: {
        marginRight: 4,
    },
    searchInput: {
        flex: 1,
        paddingVertical: 8,
        fontSize: 18,
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    infos: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    search: {
        marginBottom: 40,
    },
    item: {
        marginBottom: 30,
    },
    avatar: {
        padding: 6,
        borderRadius: 50,
        backgroundColor: theme.palette.grey[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 5,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
}));

export default function AuditoriaPage() {
    const classes = useStyles();
    const mobile = useMediaQuery(useTheme().breakpoints.down(800))
    const [auditoriaDados, setAuditoriaDados] = useState([]);
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [tecnicoID, setTecnicoID] = useState('');
    const [tecnicos, setTecnicos] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(8);
    const [totalDados, setTotalDados] = useState(0);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [permissaoGestor, setPermissaoGestor] = useState(0);
    const [tableKey, setTableKey] = useState(0)
    const [pesquisa, setPesquisa] = useState('');
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');
    const [loading, setLoading] = useState(true);
    const [carregandoBotaoLimpar, setCarregandoBotaoLimpar] = useState(false);
    const [carregandoBotaoPesquisar, setCarregandoBotaoPesquisar] = useState(false);

    const handlePesquisar = async () => {
        setPage(0); 
        
        if (!dataInicial || !dataFinal) {
            setMensagemAlert({
                title: 'Atenção!',
                body: 'Por favor, selecione as datas de início e fim antes de pesquisar.',
            });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
        }

        if (moment(dataInicial).isAfter(dataFinal)) {
            setMensagemAlert({
                title: 'Falha!',
                body: 'Data inicial não pode ser maior que a data final.',
            });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
        }

        if (moment(dataInicial).isAfter(moment())) {
            setMensagemAlert({
                title: 'Falha!',
                body: 'Data inicial não pode ser maior que a data atual.',
            });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
        }

        setCarregandoBotaoPesquisar(true);
        await dadosAuditoria(dataInicial, dataFinal, tecnicoID, 0, pageSize, pesquisa);
        setCarregandoBotaoPesquisar(false);
    };

    const dadosAuditoria = async (dataInicial, dataFinal, tecnicoID, page, pageSize, pesquisa) => {
        setCarregandoTable(true);
        const dados = {
            dataInicial: dataInicial ? dataInicial : '',
            dataFinal: dataFinal ? dataFinal : '',
            tecnicoID: tecnicoID ? tecnicoID : '',
            pagina: page ? page : 0,
            limite: pageSize ? pageSize : 8,
            nome: pesquisa ? pesquisa : '',
        }

        const response = await auditoriaService.auditoria(dados);

        if (response.dados) {
            let _auditoriaDados = [];
            let dados = response.dados;

            dados.map((nt) => {
                _auditoriaDados.push({
                    id: nt.id,
                    login: nt.login,
                    descricao: nt.descricao,
                    data: nt.data,
                    acao: nt.acao
                });
            });

            setAuditoriaDados(_auditoriaDados);
            setTotalDados(response.total);
        }
        setCarregandoTable(false);
    }

    const usuarios = async () => {
        const response = await auditoriaService.listarTecnicosAuditoria();
        if (response.error) {
            setMensagemAlert({ title: 'Erro!', body: response.message });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
        } else {
            setTecnicos(response);
        }
    }

    const delayPesquisa = useCallback(
        debounce((nome) => {
            dadosAuditoria(dataInicial, dataFinal, tecnicoID, 0, pageSize, nome);
        }, 700), []
    );
    
    const valorPesquisa = (nome) => {
        setPesquisa(nome);
        if (nome !== pesquisa) {
            setPesquisa(nome);
            delayPesquisa(nome);
        }
    };

    const limparPesquisa = () => {
        setPesquisa('');
        dadosAuditoria(dataInicial, dataFinal, tecnicoID, 0, pageSize, '');
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        dadosAuditoria(dataInicial, dataFinal, tecnicoID, newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        setPage(0);
        dadosAuditoria(dataInicial, dataFinal, tecnicoID, 0, newPageSize, pesquisa);
    };

    const AcaoBadge = ({ acao }) => {

        let acaoText = '';
        let bgColor = '';
        let IconComponent = null;

        const acaoFormatada = acao;

        switch (acaoFormatada) {
            case 'Adicao':
                acaoText = 'Adição';
                bgColor = 'rgba(3, 201, 169, 1)';
                IconComponent = AddCircleIcon;
                break;
            case 'Edicao':
                acaoText = 'Edição';
                bgColor = '#FFA726';
                IconComponent = EditIcon;
                break;
            case 'Exclusao':
                acaoText = 'Exclusão';
                bgColor = 'rgba(255, 99, 132, 1)';
                IconComponent = DeleteIcon;
                break;
            case 'Modulo':
                acaoText = 'Login';
                bgColor = 'rgba(52, 119, 235)';
                IconComponent = LoginIcon;
                break;
            default:
                acaoText = 'Desconhecido';
                bgColor = '#000';
                IconComponent = InfoIcon;
                break;
        }

        return (
            <div style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                width: '100%',
                height: 44,
                marginTop: '10px',
                borderRadius: 15,
                backgroundColor: bgColor,
            }}>
                <IconComponent style={{ marginRight: '8px', color: '#fff' }} />
                {acaoText}
            </div>
        );
    };

    const limparCampos = async () => {
        setCarregandoBotaoLimpar(true);
        setDataInicial('');
        setDataFinal('');
        setTecnicoID('');
        setPesquisa('');
        setPage(0);
        await dadosAuditoria('', '', '', 0, pageSize, '');
        setCarregandoBotaoLimpar(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dadosAuditoria();
            await usuarios();
            setPermissaoGestor(authService.getUserInfo().usuario.gestor);
            setTableKey(tableKey + 1)
            setLoading(false);
        };

        fetchData();
    }, [mobile]);

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            <>
                {permissaoGestor == true ? (
                    <>
                        <div style={{ margin: 30 }}>
                            <h3 className={classes.tituloStyle}>Auditoria</h3>
                            <h4 className={classes.subtituloStyle}>
                                <span
                                    onClick={() => window.location.href = '/gestor'}
                                    className={classes.linkStyle}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Home
                                </span> {'>'} Auditoria
                            </h4>

                            <div style={{ marginBottom: 20 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            id="start-date"
                                            label="Data inicial"
                                            type="date"
                                            value={dataInicial}
                                            onChange={(e) => setDataInicial(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            id="end-date"
                                            label="Data final"
                                            type="date"
                                            value={dataFinal}
                                            onChange={(e) => setDataFinal(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            id="tecnicoID"
                                            options={tecnicos}
                                            getOptionLabel={(option) => option.login}
                                            value={tecnicos.find((option) => option.id === tecnicoID) || null}
                                            onChange={(event, newValue) => {
                                                setTecnicoID(newValue ? newValue.id : '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<span>Técnico</span>}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Selecione um técnico"
                                                    InputLabelProps={{
                                                        htmlFor: 'tecnicoID',
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        id: 'tecnicoID',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <BlueButton
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={handlePesquisar}
                                            fullWidth
                                            startIcon={carregandoBotaoPesquisar ? <CircularProgress color="primary" size={20} /> : <SearchIcon />}
                                            disabled={carregandoBotaoPesquisar}
                                            style={{ height: 55 }}
                                        >
                                            <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoPesquisar ? ' PESQUISANDO...' : ' PESQUISAR'}</span>
                                        </BlueButton>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <BlueButton
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={limparCampos}
                                            fullWidth
                                            startIcon={carregandoBotaoLimpar ? <CircularProgress color="primary" size={20} /> : <ClearIcon />}
                                            disabled={carregandoBotaoLimpar}
                                            style={{ height: 55 }}
                                        >
                                            <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoLimpar ? ' LIMPANDO...' : ' LIMPAR'}</span>
                                        </BlueButton>
                                    </Grid>
                                </Grid>
                            </div>

                            <Paper className={classes.paper} >
                                <Grid container spacing={1} direction="column">
                                    <Grid container justifyContent="flex-end" item xs={12} sm={12} style={{ marginBottom: 15 }}>
                                        <TextField
                                            label="Pesquisar"
                                            variant="outlined"
                                            value={pesquisa}
                                            onChange={(event) => valorPesquisa(event.target.value)}
                                            style={{ width: mobile ? '100%' : '40%' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {pesquisa ? (
                                                            <IconButton onClick={limparPesquisa}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        ) : (
                                                            <SearchIcon />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                {carregandoTable ? (
                                    <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                                        <CircularProgress />
                                    </Grid>
                                ) : auditoriaDados.length === 0 ? (
                                    <Grid container justifyContent="center" alignItems="center" >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Não há hístorico de auditoria.
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={1}>
                                        {auditoriaDados.map((rowData) => (
                                            <Grid item key={rowData.id} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                                                <Paper
                                                    style={{
                                                        padding: '16px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        height: '100%',
                                                        boxSizing: 'border-box',
                                                    }}
                                                >
                                                    <Box padding={1} style={{ flexGrow: 1, minHeight: '100px', maxHeight: '200px' }}>
                                                        <Typography component="div" variant="body2"><strong>Usuário:</strong> {rowData.login}</Typography>
                                                        <Typography component="div" variant="body2"><strong>Data:</strong> {moment(rowData.data).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                                                        <Typography component="div" variant="body2"><strong>Descrição:</strong> <br />{rowData.descricao}</Typography>
                                                    </Box>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography component="div" variant="body2"> <AcaoBadge acao={rowData.acao} /></Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item>
                                        <CustomTablePagination
                                            count={totalDados}
                                            page={page}
                                            rowsPerPage={pageSize}
                                            onPageChange={mudarPagina}
                                            quantidadeItens={totalDados}
                                            onRowsPerPageChange={mudarTamanhoPagina}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </>)
                    : (
                        <div style={{ margin: 30 }}>
                            <h3 className={classes.tituloStyle}>Auditoria</h3>
                            <h4 className={classes.subtituloStyle}>
                                <span
                                    onClick={() => window.location.href = '/gestor'}
                                    className={classes.linkStyle}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Home
                                </span> {'>'} Auditoria
                            </h4>


                            <Paper className={classes.paper}>
                                <Grid container justifyContent="center">
                                    <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                                </Grid>
                            </Paper>
                        </div>
                    )}
            </>
        </>
    );
}
