import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class BaseInstaladaService {

  async total(na, serie) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/baseinstalada/total/${na}/${serie}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });

      if (response.status === 200)
        return await response.json();

    } catch (error) {
      console.log(error);
    }
  }

  async atualiza(baseinstalada, numAgendamento, usuarioID) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/baseinstalada/atualiza/${numAgendamento}/${usuarioID}`, {
        method: 'PUT',
        headers: authService.headerAPITecnico(),
        body: baseinstalada
      });

      if (response.status === 200)
        return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

}
export default new BaseInstaladaService();
