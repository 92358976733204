import React, { useEffect, useState } from 'react';
import '../../agenda/components/react-big-calendar.css';
import 'moment/locale/pt-br';
import { InputAdornment, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, Typography, TextField, IconButton } from '@material-ui/core';
import authService from '../../Services/auth/auth-service';
import estoqueService from '../../Services/Estoque/estoque-service';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BlueButton from '../../components/button/BlueButton';
import CustomAlert from '../../components/alert/CustomAlert';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CustomTablePagination from '../../components/pagination/pagination-component';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    checkbox: {
        marginRight: '15px',
    },
}));

export default function NovaSolicitacaoReversa({ fecharTelaNovaReversa }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [solicitacaoReversaItens, setSolicitacaoReversa] = useState([]);
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [dadosSelect, setDadosSelect] = useState([]);
    const [abrirModal, setAbrirModal] = useState(false);
    const [idAreaAtendimento, setIdAreaAtendimento] = useState(0);
    const [abrirConfirmacaoModal, setAbrirModalConfirmacao] = useState(false);
    const [efeitoCarregando, setEfeitoCarregando] = useState(false);
    const [totalItensSelecionados, setTotalItensSelecionados] = useState(0);
    const [totalDados, setTotalDados] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(8);
    const [dadosInput, setDadosInput] = useState({
        peso: 0,
        volume: 0,
        anexos: [],
    });
    const [termoPesquisa, setTermoPesquisa] = useState('');
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');
    const classes = useStyles();
    const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);

    const InputAtribuirValor = (atr, value) => {
        setDadosInput(t => ({ ...t, [atr]: value }));
    };

    const buscarProduto = (term) => {
        if (term === '') {
            setItensFiltrados(solicitacaoReversaItens);
        } else {
            const filtrados = solicitacaoReversaItens.filter((item) =>
                item.produtoNome.toLowerCase().includes(term.toLowerCase())
            );
            setItensFiltrados(filtrados);
        }
    };

    const botaoContestar = () => {
        const totalSelecionados = dadosSelect.length;

        if (totalSelecionados < minimoItens) {
            setMensagemAlert({
                title: 'Atenção!',
                body: `Você selecionou apenas ${totalSelecionados} itens. É necessário selecionar no mínimo ${minimoItens} itens para continuar.`,
            });
            setTipoAlert('fail');
            setAbrirAlert(true);
        } else {
            setAbrirModal(true);
        }
    };

    const fecharModal = () => {
        setAbrirModal(false);
    };

    const botaoConfirmar = async () => {
        if (dadosInput.peso === 0 || dadosInput.volume === 0 || dadosInput.anexos.length === 0) {
            setMensagemAlert({ title: 'Falhou!', body: 'Por favor, preencha todos os campos obrigatórios.' });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
        }

        setAbrirModalConfirmacao(true);
    };

    const fecharModalConfirmacao = () => {
        setAbrirModalConfirmacao(false);
    };

    const mudarPagina = (event, novaPagina) => {
        setPage(novaPagina);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const modalEnvioConfirmacao = async () => {
        setEfeitoCarregando(true);
        try {
            await cadastrarSolicitacao();
        } finally {
            setEfeitoCarregando(false);
            setAbrirModalConfirmacao(false);
            setAbrirModal(false);
        }
    };

    const minimoItens = 6;
    const itensFaltantes = minimoItens - totalItensSelecionados;

    useEffect(() => {
        const filtrarItens = () => {
            if (termoPesquisa === '') {
                setItensFiltrados(solicitacaoReversaItens);
            } else {
                setItensFiltrados(
                    solicitacaoReversaItens.filter(item =>
                        item.produtoNome.toLowerCase().includes(termoPesquisa.toLowerCase())
                    )
                );
            }
            setPage(0);
        };

        setItensFiltrados(solicitacaoReversaItens);
        filtrarItens();
    }, [termoPesquisa, solicitacaoReversaItens]);

    const selecionarItem = (item) => {
        setDadosSelect((prevRows) => {
            const linhaSelecionada = new Set(prevRows.map((row) => row.estoqueId));

            if (linhaSelecionada.has(item.estoqueId)) {
                return prevRows.filter((row) => row.estoqueId !== item.estoqueId);
            } else {
                return [...prevRows, item];
            }
        });
    };

    const validarImagem = (tipo) => {
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/jpe', 'image/png', 'image/bmp'];
        return allowedTypes.includes(tipo.toLowerCase());
    };

    const handleInputFile = (files) => {
        if (files && files.length > 0) {
            let anexos = [];
            files.forEach(file => {
                if (validarImagem(file.type)) {
                    anexos.push(file);
                } else {
                    setMensagemAlert({ title: 'Erro!', body: 'Tipo de arquivo inválido.' });
                    setTipoAlert('fail');
                    setAbrirAlert(true);
                    return;
                }
            });
            if (anexos.length > 0) {
                setDadosInput(t => ({ ...t, anexos: anexos }));
            }
        }
    };

    const cadastrarSolicitacao = async () => {
        let formData = new FormData();

        formData.append('AreaAtendimentoId', idAreaAtendimento);
        formData.append('Volume', parseInt(dadosInput.volume));
        formData.append('Peso', parseInt(dadosInput.peso));
        formData.append('AreaAtendimentoGestor', authService.getUserInfo().usuario.local_gestor_responsavelid);

        dadosInput.anexos.forEach((anexo) => {
            formData.append('Anexo', anexo);
        });

        dadosSelect.forEach((item, index) => {
            formData.append(`Estoques[${index}][EstoqueId]`, item.estoqueId);
            formData.append(`Estoques[${index}][Motivo]`, item.motivo);
        });

        try {
            const response = await estoqueService.cadastrarSolicitacaoReversa(formData);

            if (response.error) {
                setMensagemAlert({ title: 'Erro!', body: response.message });
                setTipoAlert('error');
                setAbrirAlert(true);
            } else {
                setMensagemAlert({ title: 'Sucesso!', body: 'A solicitação foi enviada com sucesso.' });
                setTipoAlert('success');
                setAbrirAlert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const errorMessages = Object.keys(error.response.data.errors)
                    .map(key => error.response.data.errors[key].join(' '))
                    .join(' ');
                setMensagemAlert({ title: 'Erro!', body: errorMessages });
                setTipoAlert('error');
                setAbrirAlert(true);
            } else {
                setMensagemAlert({ title: 'Erro!', body: 'Ocorreu um erro ao enviar a solicitação.' });
                setTipoAlert('error');
                setAbrirAlert(true);
            }
        }
    };

    useEffect(() => {
        const getItens = async () => {
            setLoading(true);
            const areaAtendimento = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
            setIdAreaAtendimento(areaAtendimento);
            const responseDetalhe = await estoqueService.listarItensDevolucao(areaAtendimento);
            if (responseDetalhe) {
                setSolicitacaoReversa(responseDetalhe);
                setTotalDados(responseDetalhe.length);
            }
            setLoading(false);
        };
        getItens();
    }, []);

    const getItens = async () => {
        const areaAtendimento = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
        setIdAreaAtendimento(areaAtendimento);
        const responseDetalhe = await estoqueService.listarItensDevolucao(areaAtendimento);
        if (responseDetalhe) setSolicitacaoReversa(responseDetalhe);
    };

    const itensPaginados = itensFiltrados.slice(page * pageSize, page * pageSize + pageSize);

    return (
        <>
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            <h3 className={classes.tituloStyle}>Itens para Reversa</h3>
            <h4 className={classes.subtituloStyle}>
                <span
                    onClick={() => {
                        const userInfo = authService.getUserInfo();
                        if (userInfo.usuario.gestor) {
                            window.location.assign('/gestor');
                        } else {
                            window.location.assign('/agenda');
                        }
                    }}
                    className={classes.linkStyle}
                    style={{ cursor: 'pointer' }}
                >
                    Home
                </span> {'>'} Solicitação de Reversa {'>'} Itens para Reversa
            </h4>

            <Grid container spacing={2} alignItems="center" style={{ marginBottom: 20 }}>
                <Grid item xs={12} sm={9}>
                    <Button
                        onClick={fecharTelaNovaReversa}
                        style={{ color: '#209BDE' }}
                        startIcon={<ArrowBackIosIcon />}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <BlueButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={botaoContestar}
                        style={{ height: 55 }}
                        startIcon={<Inventory2Icon />}
                        fullWidth
                    >
                        Solicitar
                    </BlueButton>
                </Grid>
            </Grid>

            <Dialog
                aria-labelledby="customized-dialog-title"
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        fecharModal();
                    }
                }}
                open={abrirModal}
                style={{ marginTop: 35, marginBottom: 10 }}
            >
                <DialogTitle>
                    <Grid container alignItems="center">
                        <Grid item xs={10} sm={11}>
                            Nova Solicitação de Reversa
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <IconButton onClick={fecharModal}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ marginBottom: '16px' }}>
                        <TextField
                            fullWidth
                            type="number"
                            id="peso"
                            label={<span>Peso <span style={{ color: 'red' }}> *</span></span>}
                            value={dadosInput.peso}
                            onChange={(e) => InputAtribuirValor('peso', e.target.value)}
                            variant="outlined"
                        />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                        <TextField
                            fullWidth
                            type="number"
                            id="volume"
                            label={<span>Volume <span style={{ color: 'red' }}> *</span></span>}
                            value={dadosInput.volume}
                            onChange={(e) => InputAtribuirValor('volume', e.target.value)}
                            variant="outlined"
                        />
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                        <TextField
                            fullWidth
                            type="file"
                            id="anexos"
                            label={<span>Anexo <span style={{ color: 'red' }}> *</span></span>}
                            onChange={(event) => handleInputFile(event.target.files)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                multiple: false
                            }}
                            variant="outlined"
                        />
                    </div>
                    <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start' }}>
                        <strong>OBS:</strong> A solicitação uma vez enviada não poderá ser cancelada.
                    </Typography>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'space-around' }}>
                    <Button
                        variant="contained"
                        onClick={fecharModal}
                        style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                    >
                        Cancelar
                    </Button>
                    <BlueButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={botaoConfirmar}
                        style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                    >
                        Solicitar
                    </BlueButton>
                </DialogActions>
            </Dialog>

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={abrirConfirmacaoModal}
                style={{ marginTop: 35, marginBottom: 10 }}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        fecharModalConfirmacao();
                    }
                }}
            >
                <DialogTitle>
                    <Grid container alignItems="center">
                        <Grid item xs={10} sm={11}>
                            Confirmação
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <IconButton onClick={fecharModalConfirmacao}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
                        Você tem certeza que deseja solicitar a reversa?
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start' }}>
                        <strong>OBS:</strong> A solicitação uma vez enviada não poderá ser cancelada.
                    </Typography>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'space-around' }}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={efeitoCarregando}
                        onClick={fecharModalConfirmacao}
                        style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                    >
                        Cancelar
                    </Button>
                    <BlueButton
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={efeitoCarregando}
                        onClick={modalEnvioConfirmacao}
                        style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                    >
                        {efeitoCarregando ? 'Enviando...' : 'Solicitar'} {efeitoCarregando && <CircularProgress size={20} />}
                    </BlueButton>
                </DialogActions>
            </Dialog>

            <Paper className={classes.paper}>
                <Grid container spacing={1} direction="column">
                    <Grid container justifyContent="space-between" item xs={12} sm={12} style={{ marginBottom: 15 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => {
                                const term = e.target.value;
                                setSearchTerm(term);
                                buscarProduto(term);
                            }}
                            style={{ width: mobile ? '100%' : '40%' }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {searchTerm ? (
                                            <IconButton
                                                onClick={() => {
                                                    setSearchTerm('');
                                                    buscarProduto('');
                                                }}
                                                size="small"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        ) : (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )}
                                    </>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                {loading ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                        <CircularProgress />
                    </Grid>
                ) : itensFiltrados.length === 0 ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Não há equipamento disponíveis para reversa.
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid container spacing={1} alignItems="stretch">
                            {itensPaginados.map((item, index) => (
                                <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                                    <Paper onClick={() => selecionarItem(item)} style={{
                                        padding: '16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        height: '100%',
                                        boxSizing: 'border-box',
                                    }}>
                                        <Grid container alignItems="center" style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Checkbox
                                                    className={classes.checkbox}
                                                    checked={dadosSelect.some(row => row.estoqueId === item.estoqueId)}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography component="div" variant="body2">{item.produtoNome}</Typography>
                                                <Typography component="div" variant="body2"><strong>Motivo:</strong> {item.motivo === 'BaseDeTroca' ? 'Base de Troca' : item.motivo}</Typography>
                                                <Typography component="div" variant="body2"><strong>Série:</strong> {item.serie ? item.serie : '-'}</Typography>
                                                <Typography component="div" variant="body2"><strong>Número da NF:</strong> {item.notaFiscalNumero ? item.notaFiscalNumero : '-'}</Typography>
                                                <Typography component="div" variant="body2"><strong>Item número:</strong> {item.notaFiscalItemNumero ? item.notaFiscalItemNumero : '-'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                            <Grid item>
                                <CustomTablePagination
                                    count={totalDados}
                                    page={page}
                                    rowsPerPage={pageSize}
                                    quantidadeItens={itensFiltrados.length}
                                    onPageChange={mudarPagina}
                                    onRowsPerPageChange={mudarTamanhoPagina}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Paper>

        </>
    );
}
