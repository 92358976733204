import React from 'react';
import ParticleAnimation from 'react-particle-animation'
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import logoOmnilink from '../../src/assets/img/logoOmni.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  formControl: {
    minWidth: '100%',
  },
  root1: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '35ch',
    },
  },
}));

export default function RecusadaPage(props) {

  const classes = useStyles();
  return (
    <div style={{ overflow: 'hidden',backgroundColor: '#4e9cde', width: '100%', height: '100%' }}>
      <ParticleAnimation
        numParticles={110}
        interactive={true}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        particleRadius={2.5}
        particleSpeed={1}
        background={{ r: 32, g: 155, b: 222, a: 255 }}
        color={{ r: 255, g: 255, b: 255, a: 255 }}
      />

      <Container style={{ backgroundColor: '#ffffff', position: 'relative', borderRadius: 10 }} component="main" maxWidth="sm">


        <CssBaseline />
                
        <div className={classes.paper}>
          <br />
          <br />
          <img style={{ paddingTop: 5 }} src={logoOmnilink}></img>
          <br />
          <br />
          <br />

                Vistoria recusada!
          <br />
          <br />
          <br />
          <br />
        </div>

 
        <Box mt={20}>
        </Box>
      </Container>

    </div>
  );
}
