import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Select, InputLabel
} from '@material-ui/core';

export function AvlPreliminarVistoria({
  vistoria,
  handleInputChange,
  classes,
  errors,
  disabledEdit,
}) {
  const niveisCombustivel = [
    0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
    0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0,
  ];

  return (
    <>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: 10 }}>
        <Grid item xs={12} sm={6} container>
          <div className={classes.subtitle}>Avaliação preliminar <span style={{ color: 'red' }}>*</span></div>
        </Grid>
        <Grid xs={12} sm={6} container spacing={1}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="avaliacaoPreenchimento" style={{ whiteSpace: 'nowrap' }}>Preenchimento Rápido</InputLabel>
              <Select
                native
                labelId="marca-todos-itens-avaliacao"
                label="Preenchimento Rápido"
                id="drpMarcaTodosItensAvaliacao"
                disabled={disabledEdit}
                defaultValue=""
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  if (selectedValue === 'B' || selectedValue === 'M') {
                    handleInputChange('avp_bateria', selectedValue);
                    handleInputChange('avp_ignicao', selectedValue);
                    handleInputChange('avp_caixa_fusivel', selectedValue);
                    handleInputChange('avp_eletrica', selectedValue);
                    handleInputChange('avp_alternador', selectedValue);
                    handleInputChange('avp_portas_bau', selectedValue);
                    handleInputChange('avp_motor', selectedValue);
                  }
                }}
              >
                <optgroup>
                  <option value="" disabled hidden />
                  <option value={'B'}>Selecionar tudo como bom estado</option>
                  <option value={'M'}>Selecionar tudo como mau estado</option>
                </optgroup>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>

        <Grid item xs={12} sm={6}>
          <RadioGroup
            className={classes.radioP}
            id="rdpgBateria"
            defaultValue="top"
            value={vistoria?.avp_bateria || ''}
            onChange={(event) =>
              handleInputChange('avp_bateria', event.target.value)
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <strong className={classes.radioTitle}>Bateria:</strong>
              </Grid>
              <Grid item xs={12} md={8} container>
                <FormControlLabel
                  value="B"
                  control={<Radio color="primary" />}
                  label="Bom Estado"
                  disabled={disabledEdit}
                />
                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label="Mau Estado"
                  disabled={disabledEdit}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <RadioGroup
            className={classes.radioP}
            id="rdpgIgnicao"
            value={vistoria?.avp_ignicao || ''}
            onChange={(event) =>
              handleInputChange('avp_ignicao', event.target.value)
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <strong className={classes.radioTitle}>Ignição:</strong>
              </Grid>
              <Grid item xs={12} md={8} container>
                <FormControlLabel
                  value="B"
                  control={<Radio color="primary" />}
                  label="Bom Estado"
                  disabled={disabledEdit}
                />

                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label="Mau Estado"
                  disabled={disabledEdit}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <RadioGroup
            className={classes.radioP}
            id="rdpgCaixaFusivel"
            value={vistoria?.avp_caixa_fusivel || ''}
            onChange={(event) =>
              handleInputChange('avp_caixa_fusivel', event.target.value)
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <strong className={classes.radioTitle}>
                  Caixa de Fusível:
                </strong>
              </Grid>
              <Grid item xs={12} md={8} container>
                <FormControlLabel
                  value="B"
                  control={<Radio color="primary" />}
                  label="Bom Estado"
                  disabled={disabledEdit}
                />

                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label="Mau Estado"
                  disabled={disabledEdit}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <RadioGroup
            className={classes.radioP}
            id="rdpgEletricaGeral"
            value={vistoria?.avp_eletrica || ''}
            onChange={(event) =>
              handleInputChange('avp_eletrica', event.target.value)
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <strong className={classes.radioTitle}>Elétrica Geral:</strong>
              </Grid>
              <Grid item xs={12} md={8} container>
                <FormControlLabel
                  value="B"
                  control={<Radio color="primary" />}
                  label="Bom Estado"
                  disabled={disabledEdit}
                />

                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label="Mau Estado"
                  disabled={disabledEdit}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <RadioGroup
            className={classes.radioP}
            id="rdpgAlternador"
            value={vistoria?.avp_alternador || ''}
            onChange={(event) =>
              handleInputChange('avp_alternador', event.target.value)
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <strong className={classes.radioTitle}>Alternador:</strong>
              </Grid>
              <Grid item xs={12} md={8} container>
                <FormControlLabel
                  value="B"
                  control={<Radio color="primary" />}
                  label="Bom Estado"
                  disabled={disabledEdit}
                />

                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label="Mau Estado"
                  disabled={disabledEdit}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <RadioGroup
            className={classes.radioP}
            id="rdpgPortaBau"
            value={vistoria?.avp_portas_bau || ''}
            onChange={(event) =>
              handleInputChange('avp_portas_bau', event.target.value)
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <strong className={classes.radioTitle}>Portas Baú:</strong>
              </Grid>
              <Grid item xs={12} md={8} container>
                <FormControlLabel
                  value="B"
                  control={<Radio color="primary" />}
                  label="Bom Estado"
                  disabled={disabledEdit}
                />

                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label="Mau Estado"
                  disabled={disabledEdit}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <RadioGroup
            className={classes.radioP}
            id="rdpgMotor"
            value={vistoria?.avp_motor || ''}
            onChange={(event) =>
              handleInputChange('avp_motor', event.target.value)
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <strong className={classes.radioTitle}>Motor:</strong>
              </Grid>
              <Grid item xs={12} md={8} container>
                <FormControlLabel
                  value="B"
                  control={<Radio color="primary" />}
                  label="Bom Estado"
                  disabled={disabledEdit}
                />

                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label="Mau Estado"
                  disabled={disabledEdit}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: 25 }}>
        <Grid item xs={6} container alignItems="center">
          <span style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}>
            Nível de Combustível <span style={{ color: 'red' }}>*</span> :
          </span>
          <FormControl error={!!errors.nivel_combustivel}>
            <Select
              native
              disabled={disabledEdit}
              id="nivelCombustivelSelect"
              value={
                vistoria?.nivel_combustivel !== undefined &&
                  vistoria?.nivel_combustivel !== null
                  ? vistoria?.nivel_combustivel
                  : ''
              }
              onChange={(event) =>
                handleInputChange('nivel_combustivel', event.target.value)
              }
            >
              <optgroup>
                <option value="" disabled hidden>
                  Selecione o nível de combustível
                </option>
                {niveisCombustivel.map((nivel) => (
                  <option key={nivel} value={nivel}>
                    {Number(nivel * 100).toFixed(0)}%
                  </option>
                ))}
              </optgroup>
            </Select>
            <FormHelperText>{errors.nivel_combustivel?.message}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
