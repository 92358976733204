import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const theme = createTheme({
  overrides: {
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: '#209BDE',
      },
      colorPrimary: {
        backgroundColor: '#BBE5FB',
      },
    },
  },
});

function LinearProgressBlue({ ...rest }) {
  return (
    <ThemeProvider theme={theme}>
      <LinearProgress {...rest} />
    </ThemeProvider>
  );
}

export { LinearProgressBlue };
