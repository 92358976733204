import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: 'bold',
  },
  subTitle: {
    width: '100%',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 25,
    marginBottom: 20,
  },
}));

export function InformacoesVeiculo({ vistoria }) {
  const classes = useStyles();

  function parseBool(value) {
    return value === 'true' || value === true ? true : false;
  }

  return (
    <>
      <Grid style={{ marginTop: 10 }} container spacing={4}>
        <Grid item xs={6} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                id="chkMecanico"
                name="chkMecanico"
                checked={parseBool(vistoria.mecanico)}
                value={parseBool(vistoria.mecanico)}
                disableRipple
              />
            }
            label="Mecânico"
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                id="chk24V"
                name="chk24V"
                checked={parseBool(vistoria.vinte_quatro_v)}
                value={parseBool(vistoria.vinte_quatro_v)}
                disableRipple
              />
            }
            label="24V"
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                id="chkDoze"
                name="chkDoze"
                checked={parseBool(vistoria.doze_v)}
                value={parseBool(vistoria.doze_v)}
                disableRipple
              />
            }
            label="12V"
          />
        </Grid>
        <Grid item xs={6} sm={5}>
          <FormControlLabel
            control={
              <Checkbox
                id="chkInjecao"
                name="chkInjecao"
                checked={parseBool(vistoria.injecao_eletronica)}
                value={parseBool(vistoria.injecao_eletronica)}
                disableRipple
              />
            }
            label="Injeção Eletrônica"
          />
        </Grid>
      </Grid>

      <span className={classes.subTitle} style={{ marginTop: 20 }}>
        Dados Preliminares
      </span>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <FormControl fullWidth disabled>
            <InputLabel id="id-bateria">Bateria</InputLabel>
            <Select
              labelId="id-bateria"
              native
              value={vistoria.avp_bateria || ''}
            >
              <option value="" />
              <option value={'B'}>Bom Estado</option>
              <option value={'M'}>Mau Estado</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth disabled>
            <InputLabel id="id-caixa-fusivel">Caixa Fusivel</InputLabel>
            <Select
              labelId="id-caixa-fusivel"
              native
              value={vistoria.avp_caixa_fusivel || ''}
            >
              <option value="" />
              <option value={'B'}>Bom Estado</option>
              <option value={'M'}>Mau Estado</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth disabled>
            <InputLabel id="id-eletrica">Eletrica Geral</InputLabel>
            <Select
              labelId="id-eletrica"
              native
              value={vistoria.avp_eletrica || ''}
            >
              <option value="" />
              <option value={'B'}>Bom Estado</option>
              <option value={'M'}>Mau Estado</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth disabled>
            <InputLabel id="id-alternador">Altenador</InputLabel>
            <Select
              labelId="id-alternador"
              native
              value={vistoria.avp_alternador || ''}
            >
              <option value="" />
              <option value={'B'}>Bom Estado</option>
              <option value={'M'}>Mau Estado</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth disabled>
            <InputLabel id="id-portas-bau">Portas Báu</InputLabel>
            <Select
              labelId="id-portas-bau"
              native
              value={vistoria.avp_portas_bau || ''}
            >
              <option value="" />
              <option value={'B'}>Bom Estado</option>
              <option value={'M'}>Mau Estado</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth disabled>
            <InputLabel id="id-ignicao">Ignição Caminhão</InputLabel>
            <Select
              labelId="id-ignicao"
              native
              value={vistoria.avp_ignicao || ''}
            >
              <option value="" />
              <option value={'B'}>Bom Estado</option>
              <option value={'M'}>Mau Estado</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth disabled>
            <InputLabel id="id-motor">Motor Caminhão</InputLabel>
            <Select labelId="id-motor" native value={vistoria.avp_motor || ''}>
              <option value="" />
              <option value={'B'}>Bom Estado</option>
              <option value={'M'}>Mau Estado</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <strong>Nível de Combustível: </strong>
          {vistoria?.nivel_combustivel !== undefined &&
          vistoria?.nivel_combustivel !== null
            ? `${(vistoria.nivel_combustivel * 100).toFixed(0)}%`
            : '-'}
        </Grid>
      </Grid>

      {/* <span className={classes.subTitle} style={{ marginTop: 20 }}>
        Acessórios ( Além do Kit Básico):
      </span>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="painel"
                name="painel"
                checked={parseBool(vistoria.painel)}
                value={parseBool(vistoria.painel)}
                disableRipple
              />
            }
            label="Painel"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="panico"
                name="panico"
                checked={parseBool(vistoria.panico)}
                value={parseBool(vistoria.panico)}
                disableRipple
              />
            }
            label="Pânico"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="porta_bau"
                name="porta_bau"
                checked={parseBool(vistoria.porta_bau)}
                value={parseBool(vistoria.porta_bau)}
                disableRipple
              />
            }
            label="Porta Báu Tr"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="porta_bau_lateral"
                name="porta_bau_lateral"
                checked={parseBool(vistoria.porta_bau_lateral)}
                value={parseBool(vistoria.porta_bau_lateral)}
                disableRipple
              />
            }
            label="Porta Báu Lat"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="sensor_temperatura"
                name="sensor_temperatura"
                checked={parseBool(vistoria.sensor_temperatura)}
                value={parseBool(vistoria.sensor_temperatura)}
                disableRipple
              />
            }
            label="Temperatura"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="tansmissor_imagem"
                name="tansmissor_imagem"
                checked={parseBool(vistoria.tansmissor_imagem)}
                value={parseBool(vistoria.tansmissor_imagem)}
                disableRipple
              />
            }
            label="Módulo Transmissor Img"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="porta_cabine_mot"
                name="porta_cabine_mot"
                checked={parseBool(vistoria.porta_cabine_mot)}
                value={parseBool(vistoria.porta_cabine_mot)}
                disableRipple
              />
            }
            label="Porta Cabine Motorista"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="porta_cabine"
                name="porta_cabine"
                checked={parseBool(vistoria.porta_cabine)}
                value={parseBool(vistoria.porta_cabine)}
                disableRipple
              />
            }
            label="Porta Cabine Carona"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="engate"
                name="engate"
                checked={parseBool(vistoria.engate)}
                value={parseBool(vistoria.engate)}
                disableRipple
              />
            }
            label="Engate Cavalo"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="engate_carreta"
                name="engate_carreta"
                checked={parseBool(vistoria.engate_carreta)}
                value={parseBool(vistoria.engate_carreta)}
                disableRipple
              />
            }
            label="Engate Carreta"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="engate_eletronico"
                name="engate_eletronico"
                checked={parseBool(vistoria.engate_eletronico)}
                value={parseBool(vistoria.engate_eletronico)}
                disableRipple
              />
            }
            label="Engate Eletrônico"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="bateria_backupbit"
                name="bateria_backupbit"
                checked={parseBool(vistoria.bateria_backupbit)}
                value={parseBool(vistoria.bateria_backupbit)}
                disableRipple
              />
            }
            label="Bateria Backup"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="preparacao_sat"
                name="preparacao_sat"
                checked={parseBool(vistoria.preparacao_sat)}
                value={parseBool(vistoria.preparacao_sat)}
                disableRipple
              />
            }
            label="Preparação Sat"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="setas"
                name="setas"
                checked={parseBool(vistoria.setas)}
                value={parseBool(vistoria.setas)}
                disableRipple
              />
            }
            label="Setas"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="trava_bau"
                name="trava_bau"
                checked={parseBool(vistoria.trava_bau)}
                value={parseBool(vistoria.trava_bau)}
                disableRipple
              />
            }
            label="Trava Baú"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="trava_quinta_roda"
                name="trava_quinta_roda"
                checked={parseBool(vistoria.trava_quinta_roda)}
                value={parseBool(vistoria.trava_quinta_roda)}
                disableRipple
              />
            }
            label="Trava de 5ª Roda"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="sirene"
                name="sirene"
                checked={parseBool(vistoria.sirene)}
                value={parseBool(vistoria.sirene)}
                disableRipple
              />
            }
            label="Sirene"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="bloqueio_solenoide"
                name="bloqueio_solenoide"
                checked={parseBool(vistoria.bloqueio_solenoide)}
                value={parseBool(vistoria.bloqueio_solenoide)}
                disableRipple
              />
            }
            label="Bloqueio Solenoide"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="bloqueio_eletronico"
                name="bloqueio_eletronico"
                checked={parseBool(vistoria.bloqueio_eletronico)}
                value={parseBool(vistoria.bloqueio_eletronico)}
                disableRipple
              />
            }
            label="Bloqueio Eletrônico"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="teclado"
                name="teclado"
                checked={parseBool(vistoria.teclado)}
                value={parseBool(vistoria.teclado)}
                disableRipple
              />
            }
            label="Teclado"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="adesivos"
                name="adesivos"
                checked={parseBool(vistoria.adesivos)}
                value={parseBool(vistoria.adesivos)}
                disableRipple
              />
            }
            label="Adesivos"
          />
        </Grid>
        {vistoria.sensor_temperatura && vistoria.qtde_sensor_temperatura && (
          <Grid item xs={6}>
            <b>Quant. sensores de temp.:</b> {vistoria.qtde_sensor_temperatura}
          </Grid>
        )}
      </Grid> */}
    </>
  );
}
