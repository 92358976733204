import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import BlueButton from '../../components/button/BlueButton';
import ordemServicoServico from '../../Services/OrdemServico/ordem-servico-servico';
import { CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CustomAlert from '../../components/alert/CustomAlert';

const Anotacao = ({ ordemServico, StatusNA }) => {
  const [inputValue, setInputValue] = useState(ordemServico.anotacao.descricao || '');
  const [loadSave, setLoadSave] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('success');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleSave = async () => {
    setLoadSave(true);

    if (inputValue.trim() !== '') {

      const anotacaoBody = {
        AtividadeId: ordemServico.activityId,
        Descricao: inputValue.trim()
      };

      if (ordemServico.anotacao.id) {
        const responseAtualizarAnotacao = await ordemServicoServico.atualizarAnotacao(ordemServico.anotacao.id, JSON.stringify(anotacaoBody));
        if (!responseAtualizarAnotacao.error) {
          ordemServico.anotacao[0] = inputValue.trim();
          setAlertMessage({ title: 'Sucesso!', body: 'A observação foi atualizada com sucesso!' });
          setAlertType('success');
          setAlertOpen(true);
        } else {
          setAlertMessage({ title: 'Erro!', body: responseAtualizarAnotacao.message });
          setAlertType('error');
          setAlertOpen(true);
        }
      } else {
        const responseSalvarAnotacao = await ordemServicoServico.salvarAnotacao(JSON.stringify(anotacaoBody));
        if (!responseSalvarAnotacao.error) {
          ordemServico.anotacao[0] = inputValue.trim();
          setAlertMessage({ title: 'Sucesso!', body: 'A observação foi atualizada com sucesso!' });
          setAlertType('success');
          setAlertOpen(true);
        } else {
          setAlertMessage({ title: 'Erro!', body: responseSalvarAnotacao.message });
          setAlertType('error');
          setAlertOpen(true);
        }
      }

      setInputValue(inputValue.trim());

    }
    setLoadSave(false);
  };

  return (
    <div>
      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        type={alertType}
        onClose={() => setAlertOpen(false)}
      />

      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={10}>
          <TextField
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            label="Inserir Anotação"
            variant="outlined"
            disabled={StatusNA === 'Fechado'}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <BlueButton
            fullWidth
            style={{ height: 55 }}
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSave}
            disabled={StatusNA === 'Fechado' || loadSave}
            startIcon={loadSave ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            <span style={{ fontSize: '0.7rem' }}>{loadSave ? ' Salvando...' : ' Salvar anotação'}</span>
          </BlueButton>
        </Grid>
      </Grid>

    </div>
  );
};

export default Anotacao;
