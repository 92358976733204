import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class NotaFiscalService {

  async Notas(dados, microSigaID) {
    try {
      let tz_area_atendimento_microsigaid;
      if (microSigaID) {
        tz_area_atendimento_microsigaid = microSigaID;
      } else {
        tz_area_atendimento_microsigaid = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
      }
      let response = await fetch(`${_configAPITecnico.URI_API}/NotaFiscal/listar?AreaAtendimentoId=${tz_area_atendimento_microsigaid}&DataInicial=${dados.dataInicial}&DataFinal=${dados.dataFinal}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async detalheNF(areaAtendimentoID, nfID) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/notafiscal/item/${nfID}/${areaAtendimentoID}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });

      if (response.status === 200)
        return await response.json();

    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async contestarNF(request, areaAtendimentoID) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/notaFiscalConstestacao/${areaAtendimentoID}`, {
        method: 'POST',
        headers: authService.headerAPITecnico(),
        body: JSON.stringify(request)
      });

      if (response.status === 201) {
        return response.json();
      } else {
        const data = await response.json();
        let errorsMessage = null;
        if (data.errors) {
          errorsMessage = Object.values(data.errors).map((error) => error);
        }

        return {
          error: true,
          message: data.erro || errorsMessage,
        };
      }
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async dadosContestacaoNF(idNF) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/notaFiscalConstestacao?notaFiscalId=${idNF}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });

      if (response.status === 200)
        return await response.json();

    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }
}

export default new NotaFiscalService();
