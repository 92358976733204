import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class EstoqueService {

  async exibirEstoque(areaAtendimentoID) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoque/${areaAtendimentoID}`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async listarEstoque(areaAtendimentoID) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoque/${areaAtendimentoID}/disponivel`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async listarEstoqueConsolidadoEServicos(areaAtendimentoID) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoqueconsolidado/${areaAtendimentoID}/servicosEProdutosConsolidados`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async listarEstoqueConsolidado(dados) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoqueconsolidado/consolidadoListagem?AreaAtendimentoId=${dados.AreaAtendimentoId}&Pagina=${dados.Pagina}&Limite=${dados.Limite}&Pesquisa=${dados.Pesquisa}`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async listarSeriaisEquipamentoConsolidado(dados) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoqueconsolidado/consolidadoSerialListagem?AreaAtendimentoId=${dados.AreaAtendimentoId}&CodigoProduto=${dados.CodigoProduto}&Pagina=${dados.Pagina}&Limite=${dados.Limite}&Pesquisa=${dados.Pesquisa}`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async listarSolicitacoesReversa(dados, microSigaID) {
    try {
      let tz_area_atendimento_microsigaid;
      if (microSigaID) {
        tz_area_atendimento_microsigaid = microSigaID;
      } else {
        tz_area_atendimento_microsigaid = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
      }
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/solicitacaoReversa/listar?AreaAtendimentoId=${tz_area_atendimento_microsigaid}&DataInicial=${dados.dataInicial}&DataFinal=${dados.dataFinal}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}`, {
        method: 'GET',
        headers: _headerToken,
      },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async listarItensDevolucao(areaAtendimentoID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/estoque/${areaAtendimentoID}/devolucao`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async cadastrarSolicitacaoReversa(solicitacaoReversa) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/solicitacaoReversa`, {
        method: 'POST',
        headers: {
          ContentType: 'multipart/form-data',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
        },
        body: solicitacaoReversa
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 201) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };

    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async itensSeriesReversa(idNota, areaAtendimentoID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/notafiscal/item/${idNota}/${areaAtendimentoID}`, {
        method: 'GET',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }
}
export default new EstoqueService();
