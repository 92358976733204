/**
 * Função que retorna o status da vistoria
 * @param {Object?} vistoria vistoria
 * @param {string?} tipo o status que deve ser retornado
 */
export function statusVistoria(vistoria, tipo) {

  if (vistoria) {
    if (tipo === 'entrada') {
      if (vistoria.statusEntrada && vistoria.emailDeEntradaEnviado) {
        return vistoria.statusEntrada;
      } 
      else if (vistoria.emailDeEntradaEnviado && !vistoria.statusEntrada) {
        return 'Aguardando aprovação';
      }
      else if (!vistoria.emailDeEntradaEnviado && !vistoria.statusEntrada) {{
        return 'Não enviado';
      }
    }
    } else if (tipo === 'saida') {
      if (vistoria.statusSaida && vistoria.emailDeSaidaEnviado) {
        return vistoria.statusSaida;
      } else if (vistoria.emailDeSaidaEnviado && !vistoria.statusSaida){
        return 'Aguardando aprovação';
      }
      else if (!vistoria.emailDeSaidaEnviado && !vistoria.statusSaida) {
        return 'Não enviado';
      }
    }
  }else{
    return 'Atividade sem vistoria';
  }
}
