import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  searchMobile: {
    marginTop: 10,
    width: '100%',
    paddingLeft: 0
  },
  search: {
    marginTop: 10,
    width: 'auto',
    paddingLeft: 0
  },
  actions: {
    width: '100%',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '16px',
    [theme.breakpoints.down('sm')]: { 
      flexDirection: 'column',
      gap: '8px',
      width: '100%'
    }
  }
}));

export function MaterialTableCustom({ ...rest }) {
  const theme = useTheme(); 
  const classes = useStyles(theme);

  const mobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <MaterialTable
      components={{
        Toolbar: (props) => (
          <MTableToolbar
            classes={
              mobile
                ? {
                    root: classes.root,
                    searchField: classes.searchMobile,
                    actions: classes.actions
                  }
                : {
                    searchField: classes.search
                  }
            }
            {...props}
          />
        )
      }}
      {...rest}
    />
  );
}
