import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import {
  Grid,
  TextField,
  makeStyles,
  Paper,
  Button,
  Dialog,
  InputAdornment,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme, CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BlueButton from '../../../components/button/BlueButton';
import CustomAlert from '../../../components/alert/CustomAlert';
import usuarioService from '../../../Services/Usuario/usuario-service';
import authService from '../../../Services/auth/auth-service';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import FilesExportFilterProducts from '../../../servicos/components/filesExportFilterProducts';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  addButton: {
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  enviarButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      width: 'auto',
      maxWidth: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    display: 'flex',
    justifyContent: 'center',
  },
  itemContainer: {
    padding: 12,
    height: 240,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  truncatedText: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
  },

  dialogPaperMobile: {
    width: '90vw',
    maxWidth: '90vw',
  },
  centeredText: {
    textAlign: 'center',
  },
  textField: {
    paddingTop: theme.spacing(2),
  },
  exportButtonContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  exportButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchFieldContainer: {
    width: '100%',
    height: 'auto',
  },
  searchField: {
    '& .MuiOutlinedInput-root': {
      height: '43px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-input': {
      padding: '8px 0',
      textAlign: 'center',
    },
    '& .MuiInputLabel-root': {
      top: '-6px',
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function ProdutosTable({
  estoqueItens,
  itensOS,
  addProduto,
  alteraProduto,
  consultaDetalheOS,
  consultaProdutosEstoque,
  os,
}) {

  useEffect(() => {
    buscarUsuarioEstoqueAtivo();
  }, [os]);

  const classes = useStyles();
  const [mostrarCampoPesquisa, setMostrarCampoPesquisa] = useState(false);
  const [produtosTemporarios, setProdutosTemporarios] = useState([]);
  const [produtoEstoqueSelecionado, setProdutoEstoqueSelecionado] = useState(null);
  const [quantidadeProduto, setQuantidadeProduto] = useState(1);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [abrirDialog, setAbrirDialog] = useState(false);
  const [editandoIndex, setEditandoIndex] = useState(null);
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [mensagemAlert, setMensagemAlert] = useState({ title: '', body: '' });
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [tipoAlert, setTipoAlert] = useState('success');
  const [dataExportacao, setDataExportacao] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [carregandoEnvioProdutos, setCarregandoEnvioProdutos] = useState(false);
  const textRef = useRef();
  const [itemEditado, setItemEditado] = useState('');
  const temProdutoAprovadoNaoTarifa = itensOS?.concat(produtosTemporarios).some(
    (item) => item.descStatus === 'Aprovado' && item.produto !== 'TARIFA DE DESLOCAMENTO' && item.produto !== 'SERVICO DE PEDAGIO'
  );
  const itemsPerPage = isMobile ? 3 : 6;

  const buscarUsuarioEstoqueAtivo = async () => {
    try {
      const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
      if (!response.error) {
        setUsuarioEstoqueAtivo(response);
      }
    } catch (error) {
      setMensagemAlert("Erro ao verificar o estoque do usuário.");
      setTipoAlert("error");
      setAbrirAlert(true);
    }
  };

  const adicionarProdutoTemporario = () => {
    try {
      if (!produtoEstoqueSelecionado || quantidadeProduto < 1) {
        setMensagemAlert('Por favor, selecione um produto e defina uma quantidade válida.');
        setAbrirAlert(true);
        return;
      }

      const produtosExistentes = produtosTemporarios.filter(
        produto => produto.produtoId === produtoEstoqueSelecionado.produtoId
      );

      const produtosInseridos = itensOS.filter((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

      let quantidadeTotal = parseFloat(quantidadeProduto);

      if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === true) {
        if (produtosInseridos) {
          produtosInseridos.forEach(produto => {
            quantidadeTotal += parseFloat(produto.quantidade);
          });
        } else if (produtosExistentes.length > 0) {
          produtosExistentes.forEach(produto => {
            quantidadeTotal += parseFloat(produto.quantidade);
          });
        }

        if (quantidadeTotal > produtoEstoqueSelecionado.qtdDisponivel) {
          setMensagemAlert({
            title: 'Falha!',
            body: 'A soma total dos itens adicionados excede a quantidade do produto disponível em estoque.',
          });
          setTipoAlert("fail");
          setAbrirAlert(true);
          return;
        }
      }

      const produtoTemporario = {
        id: editandoIndex !== null ? produtosTemporarios[editandoIndex].id : new Date().getTime(),
        produtoId: produtoEstoqueSelecionado.produtoId,
        produto: produtoEstoqueSelecionado.nome,
        quantidade: quantidadeProduto,
        codigoProduto: produtoEstoqueSelecionado.produtoNumero,
        descStatus: 'Temporário',
        ehServico: produtoEstoqueSelecionado.ehServico
      };

      if (editandoIndex !== null) {
        const updatedProdutos = [...produtosTemporarios];
        updatedProdutos[editandoIndex] = produtoTemporario;
        setProdutosTemporarios(updatedProdutos);
        setEditandoIndex(null);
      } else {
        setProdutosTemporarios([...produtosTemporarios, produtoTemporario]);
      }

      setAbrirDialog(false);
      setProdutoEstoqueSelecionado(null);
      resetarCampos();
    } catch (error) {
      setMensagemAlert({
        title: 'Erro!',
        body: 'Erro ao adicionar o produto.',
      });
      setTipoAlert("error");
      setAbrirAlert(true);
    }
  };

  const preparaDadosParaExportacao = async () => {
    let data = [];

    if (itensOS.length > 0) {
      for (let itens of itensOS) {
        let itensObjeto = {
          'Número do Produto': itens.numeroProduto,
          'Produto': itens.produto,
          'Quantidade': itens.quantidade,
          'Status': itens.descStatus
        };
        data.push(itensObjeto);
      }
    }
    setDataExportacao(data);
  };

  const aplicarEdicao = async () => {
    try {
      if (!produtoEstoqueSelecionado || quantidadeProduto < 1) {
        setMensagemAlert({ title: 'Falha!', body: 'Por favor, selecione um produto e defina uma quantidade válida.' });
        setTipoAlert("fail");
        setAbrirAlert(true);
        return;
      }

      const index = produtosTemporarios.findIndex((item) => item.id === editandoIndex);
      const produtoExistente = itensOS.find((item) => item.produtoId === produtoEstoqueSelecionado.produtoId && item.id === produtoEstoqueSelecionado.id);
      const produtoServico = estoqueItens.find((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

      if (produtoExistente && itemEditado === 'OS') {
        let quantidadeTotalInserida = parseFloat(quantidadeProduto);

        const produtosInseridos = itensOS.filter((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

        if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === true) {
          if (produtosInseridos) {
            produtosInseridos.forEach(produto => {
              quantidadeTotalInserida += parseFloat(produto.quantidade);
            });
          }
          quantidadeTotalInserida = Math.abs(quantidadeTotalInserida - (produtoExistente?.quantidade || 0));

          if (quantidadeTotalInserida > produtoEstoqueSelecionado.qtdDisponivel) {
            setMensagemAlert({ title: 'Falha!', body: 'A soma total dos itens adicionados excede a quantidade do produto disponível em estoque.' });
            setTipoAlert("fail");
            setAbrirAlert(true);
            return;
          }
        }

        setCarregandoEnvioProdutos(true);

        let retorno = await alteraProduto(
          produtoEstoqueSelecionado.produtoId,
          produtoEstoqueSelecionado.produto,
          quantidadeProduto,
          produtoEstoqueSelecionado.produtoNumero,
          produtoEstoqueSelecionado.itemID,
          produtoServico.ehServico
        );

        if (retorno === true) {
          setAbrirDialog(false);
          setMensagemAlert({ title: 'Sucesso!', body: 'Produto alterado com sucesso.' });
          setTipoAlert("success");
          setAbrirAlert(true);
          resetarCampos();
          consultaDetalheOS();
          consultaProdutosEstoque();
          setCarregandoEnvioProdutos(false);
        }
        setCarregandoEnvioProdutos(false);
        return;
      }

      if (index !== -1) {
        let quantidadeTotal = parseFloat(quantidadeProduto);

        const produtosExistentes = produtosTemporarios.filter((produto, i) =>
          produto.produtoId === produtoEstoqueSelecionado.produtoId && produto.id !== produtoEstoqueSelecionado.id
        );

        const produtosInseridos = itensOS.filter((item) => item.produtoId === produtoEstoqueSelecionado.produtoId);

        if (produtoEstoqueSelecionado.ehServico === false && usuarioEstoqueAtivo === true) {
          if (produtosInseridos) {
            produtosInseridos.forEach(produto => {
              quantidadeTotal += parseFloat(produto.quantidade);
            });
          } else if (produtosExistentes.length > 0) {
            produtosExistentes.forEach(produto => {
              quantidadeTotal += parseFloat(produto.quantidade);
            });
          }

          if (quantidadeTotal > produtoEstoqueSelecionado.qtdDisponivel) {
            setMensagemAlert({ title: 'Falha!', body: 'A soma total dos itens adicionados excede a quantidade do produto disponível em estoque.' });
            setTipoAlert("fail");
            setAbrirAlert(true);
            return;
          }
        }

        const updatedProdutos = [...produtosTemporarios];
        updatedProdutos[index] = {
          ...updatedProdutos[index],
          id: produtoEstoqueSelecionado.id,
          produtoId: produtoEstoqueSelecionado.produtoId,
          produto: produtoEstoqueSelecionado.nome,
          produtoNumero: produtoEstoqueSelecionado.produtoNumero,
          quantidade: quantidadeProduto,
        };

        setProdutosTemporarios(updatedProdutos);
        setAbrirDialog(false);
        setMensagemAlert({ title: 'Sucesso!', body: 'Produto alterado com sucesso.' });
        setTipoAlert("success");
        setAbrirAlert(true);
        resetarCampos();
      } else {
        setMensagemAlert({ title: 'Erro!', body: 'Produto não encontrado.' });
        setTipoAlert("error");
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao aplicar as alterações no produto.' });
      setTipoAlert("error");
      setAbrirAlert(true);
    }
  };

  const removerProduto = (id) => {
    try {
      const produto = produtosTemporarios.find((item) => item.id === id);

      if (!produto) {
        setMensagemAlert({ title: 'Falha!', body: 'Produto não encontrado.' });
        setTipoAlert("fail");
        setAbrirAlert(true);
        return;
      }

      const updatedProdutos = produtosTemporarios.filter((item) => item.id !== id);
      setProdutosTemporarios(updatedProdutos);

      setProdutoEstoqueSelecionado(null);
      setQuantidadeProduto(1);
      setEditandoIndex(null);

      setMensagemAlert({ title: 'Sucesso!', body: `Produto ${produto.produto} removido com sucesso.` });
      setTipoAlert("success");
      setAbrirAlert(true);

    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao remover o produto.' });
      setTipoAlert("error");
      setAbrirAlert(true);
    }
  };

  const editarProduto = (id, produtoId) => {
    try {
      let produtoTemporario = produtosTemporarios.find((item) => item.id === id);

      setItemEditado('Temporario')

      if (!produtoTemporario) {
        produtoTemporario = estoqueItens.find((item) => item.produtoId === produtoId);

        if (!produtoTemporario) {
          setMensagemAlert({ title: 'Falha!', body: 'Produto não encontrado.' });
          setTipoAlert("fail");
          setAbrirAlert(true);
          return;
        }

        setItemEditado('OS')
      }

      const produtoQuantidadeDisponivel = estoqueItens.find((item) => item.produtoId === produtoId);

      if (!produtoQuantidadeDisponivel) {
        setMensagemAlert({ title: 'Falha!', body: 'O produto não está mais disponível. Informe ao seu supervisor.' });
        setTipoAlert("error");
        setAbrirAlert(true);
        return false;
      }

      const item = itensOS.find((item) => item.produtoId === produtoId && item.id === id);

      setProdutoEstoqueSelecionado({
        id: produtoTemporario?.id || item?.id,
        produtoId: produtoTemporario?.produtoId,
        nome: produtoTemporario?.produto || produtoTemporario?.nome,
        produtoNumero: produtoTemporario?.codigoProduto || produtoTemporario?.numeroProduto || produtoQuantidadeDisponivel?.produtoNumero,
        qtdDisponivel: produtoQuantidadeDisponivel?.qtdDisponivel || produtoQuantidadeDisponivel?.quantidade,
        ehServico: produtoQuantidadeDisponivel?.ehServico,
        itemID: item?.itemID
      });

      setQuantidadeProduto(produtoTemporario.quantidade || 1);
      setEditandoIndex(id);
      setAbrirDialog(true);

    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao editar o produto.' });
      setTipoAlert("error");
      setAbrirAlert(true);
    }
  };

  const resetarCampos = () => {
    setProdutoEstoqueSelecionado(null);
    setQuantidadeProduto(1);
    setEditandoIndex(null);
  };

  const enviarProdutos = async (event) => {
    setCarregandoEnvioProdutos(true);
    event.preventDefault();

    try {
      const promises = produtosTemporarios.map((produto) =>
        addProduto(
          produto.produtoId,
          produto.produto,
          produto.quantidade,
          produto.codigoProduto,
          produto.ehServico
        )
      );

      const results = await Promise.all(promises);
      const allSuccessful = results.every((result) => result);

      if (allSuccessful) {
        setProdutosTemporarios([]);
        consultaDetalheOS();
        consultaProdutosEstoque();
        setMensagemAlert({ title: 'Sucesso!', body: `Produtos enviados com sucesso!` });
        setTipoAlert('success');
        setCarregandoEnvioProdutos(false);
      } else {
        setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar alguns produtos.' });
        setTipoAlert('error');
        setCarregandoEnvioProdutos(false);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar os produtos.' });
      setTipoAlert('error');
      setCarregandoEnvioProdutos(false);
    }
  };

  useEffect(() => {
    preparaDadosParaExportacao();
  }, [os]);

  const filtrarProdutos = useMemo(() => {
    return itensOS.concat(produtosTemporarios).filter(
      (item) =>
        item &&
        item.produto &&
        item.produto.toLowerCase().includes(termoPesquisa.toLowerCase()) ||
        item.codigoProduto &&
        item.codigoProduto.toLowerCase().includes(termoPesquisa.toLowerCase())
    );
  }, [termoPesquisa, itensOS, produtosTemporarios]);

  const produtosFiltrados = filtrarProdutos;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = produtosFiltrados.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = useCallback(() => {
    if (currentPage < Math.ceil(produtosFiltrados.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, produtosFiltrados.length, itemsPerPage]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  const totalPages = Math.ceil(produtosFiltrados.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [produtosFiltrados]);

  const bloquearEnvio = itensOS.concat(produtosTemporarios).some(
    (item) => item.descStatus === 'Aprovado' || item.descStatus === 'Aguardando Aprovacao'
  ) && !itensOS.concat(produtosTemporarios).some(
    (item) => item.produto === 'TARIFA DE DESLOCAMENTO' || item.produto === 'SERVICO DE PEDAGIO'
  );

  const StatusDiv = ({ status }) => {
    let textoStatus = '';
    let statusCor = '';
    let IconeStatus = null;

    switch (status) {
      case 'Aprovado':
        textoStatus = 'Aprovado';
        statusCor = 'rgba(3, 201, 169, 1)';
        IconeStatus = CheckCircleIcon;
        break;
      case 'Aguardando Aprovacao':
        textoStatus = 'Aguardando Aprovação';
        statusCor = '#777';
        IconeStatus = HourglassEmptyIcon;
        break;
      case 'Recusado':
        textoStatus = 'Recusado';
        statusCor = 'rgba(255, 99, 132, 1)';
        IconeStatus = CancelIcon;
        break;
      case 'Temporário':
        textoStatus = 'Temporário';
        statusCor = '#87CEEB';
        IconeStatus = InfoIcon;
        break;
      default:
        textoStatus = 'Desconhecido';
        statusCor = '#000';
        IconeStatus = InfoIcon;
        break;
    }

    return (
      <div style={{
        fontSize: '0.8rem',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 5px',
        color: '#fff',
        borderRadius: '10px',
        textAlign: 'center',
        backgroundColor: statusCor,
        maxWidth: '200px',
        margin: '0 auto',
      }}>
        <IconeStatus style={{ marginRight: '8px', color: '#fff' }} />
        {textoStatus}
      </div>
    );
  };

  const itensAdicionados = currentItems.length > 0;

  return (
    <>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item xs={12} sm={3} md={3}>
            <FilesExportFilterProducts
              fileName={'Orçamento'}
              data={dataExportacao}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: 10 }}>
            <Grid container>
              <Grid item xs={12} sm={3} md={3}>
                <Tooltip title={temProdutoAprovadoNaoTarifa ? 'Não é possível adicionar itens após o orçamento ter sido aprovado.' : ''}>
                  <span>
                    <BlueButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<AddBoxIcon />}
                      onClick={() => setAbrirDialog(true)}
                      disabled={temProdutoAprovadoNaoTarifa}
                      style={{ marginBottom: 20 }}
                    >
                      Adicionar Produto
                    </BlueButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={5}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.searchFieldContainer}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Pesquisar Produto"
                    value={termoPesquisa}
                    onChange={(e) => setTermoPesquisa(e.target.value)}
                    className={classes.searchField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginRight: 10 }}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            {!itensAdicionados && (
              <Typography
                variant="body1"
                align="center"
                color="textSecondary"
                style={{ paddingTop: theme.spacing(8), paddingBottom: theme.spacing(8) }}
              >
                Nenhum produto ou serviço adicionado.
              </Typography>
            )}

            {itensAdicionados && (
              <Grid container spacing={1} style={{ marginTop: theme.spacing(3) }} ref={textRef}>
                {currentItems.map((item, index) => (
                  <Grid item xs={12} sm={isMobile ? 12 : 4} md={4} key={`${item.produtoId}-${index}`}>
                    <Paper className={classes.itemContainer}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Tooltip title={item.produto} placement="top" arrow>
                            <Typography component="div" variant="body2" className={classes.truncatedText}>
                              {item.produto}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} className={classes.centeredText}>
                          <Typography component="div" variant="body2">Código: {item.numeroProduto ? item.numeroProduto : item.codigoProduto} </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.centeredText}>
                          <Typography component="div" variant="body2">Quantidade: {item.quantidade}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.centeredText}>
                          <StatusDiv status={item.descStatus} />
                        </Grid>
                        <Grid item xs={12} className={classes.actionButtons}>
                          <BlueButton
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => editarProduto(item.id, item.produtoId)}
                            disabled={
                              item.descStatus === 'Aprovado'
                            }
                          >
                            Editar
                          </BlueButton>
                          <BlueButton
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={() => removerProduto(item.id)}
                            disabled={
                              item.descStatus === 'Aprovado' ||
                              item.descStatus === 'Aguardando Aprovacao' ||
                              item.descStatus === 'Recusado'
                            }
                          >
                            Remover
                          </BlueButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>

      {produtosTemporarios.length > 0 && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item xs={12} sm={3} md={3}>
            <BlueButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={enviarProdutos}
              disabled={bloquearEnvio || carregandoEnvioProdutos}
              style={{ marginTop: theme.spacing(2), height: 55 }}
              startIcon={carregandoEnvioProdutos ? <CircularProgress size={20} /> : <SendIcon />}
            >
              <span style={{ fontSize: '0.7rem' }}>{carregandoEnvioProdutos ? ' Enviando...' : ' Enviar Orçamento'}</span>
            </BlueButton>
          </Grid>
        </Grid>
      )}

      {totalPages > 1 && (
        <Grid container justify="center" style={{ marginTop: theme.spacing(3) }}>
          <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
            <ArrowBackIcon />
          </Button>
          <Typography variant="body2" style={{ padding: '10px 16px' }}>
            Página {currentPage} de {totalPages}
          </Typography>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <ArrowForwardIcon />
          </Button>
        </Grid>
      )}

      <Dialog
        open={abrirDialog}
        aria-labelledby="customized-dialog-title"
        maxWidth={isMobile ? false : 'sm'}
        classes={{ paper: isMobile ? classes.dialogPaperMobile : '' }}
        style={{ marginTop: 35, marginBottom: 10 }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setAbrirDialog(false);
            resetarCampos();
          }
        }}
      >
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              {editandoIndex !== null ? 'Editar Produto' : 'Adicionar Produto'}
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton
                onClick={() => {
                  resetarCampos();
                  setAbrirDialog(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <div style={{ marginBottom: '16px' }}>
            <Autocomplete
              id="produto-select"
              options={estoqueItens}
              getOptionLabel={(option) => `${option.produtoNumero} - ${option.nome}`}
              value={produtoEstoqueSelecionado}
              disabled={editandoIndex !== null && itensOS?.find((item) => item.produtoId === produtoEstoqueSelecionado?.produtoId)}
              onChange={(event, newValue) => {
                setProdutoEstoqueSelecionado(newValue);
                setQuantidadeProduto(1);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      Selecionar Produto/Serviço <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </div>
          <TextField
            variant="outlined"
            fullWidth
            label={
              <>
                Quantidade
                <span style={{ color: 'red' }}> *</span>
              </>
            }
            type="number"
            value={quantidadeProduto}
            onChange={(event) => setQuantidadeProduto(event.target.value)}
            InputProps={{
              inputProps: {
                min: 1,
                ...(produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true
                  ? { max: produtoEstoqueSelecionado?.qtdDisponivel }
                  : { max: 100000 }
                )
              },
              endAdornment: (
                <span style={{ marginLeft: 5 }}>
                  {produtoEstoqueSelecionado?.ehServico === false && usuarioEstoqueAtivo === true ? `/${produtoEstoqueSelecionado?.qtdDisponivel}` : ''}
                </span>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            onClick={() => {
              resetarCampos();
              setAbrirDialog(false);
            }}
            variant="contained"
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            <span style={{ fontSize: '0.7rem' }}>Voltar</span>
          </Button>
          <BlueButton
            onClick={editandoIndex !== null ? aplicarEdicao : adicionarProdutoTemporario}
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            disabled={!produtoEstoqueSelecionado || quantidadeProduto < 1 || carregandoEnvioProdutos}
            startIcon={
              carregandoEnvioProdutos ? <CircularProgress size={20} /> : ''
            }
          >
            <span style={{ fontSize: '0.7rem' }}>
              {carregandoEnvioProdutos
                ? ' Salvando...'
                : (editandoIndex !== null ? 'Salvar Alterações' : 'Adicionar')}
            </span>
          </BlueButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
