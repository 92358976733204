import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: 'bold',
  },
  subTitle: {
    width: '100%',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 25,
    marginBottom: 20,
  },
}));

export function InformacoesVistoria({ vistoria, os }) {
  const classes = useStyles();
  const tipoServicos = {
    IR: 'Instalação/Reinstalação',
    RM: 'Revisão/Manutenção',
    RT: 'Retirada',
  };
  const tiposVeiculos = {
    CM: 'Cavalo mec.',
    TR: 'Truck',
    TC: 'Toco',
    VN: 'Van',
    PS: 'Veículo de passeio',
    MT: 'Moto',
    CT: 'Caminhonete',
    ON: 'Ônibus',
    CR: 'Carreta',
  };
  const tiposCarretas = {
    CS: 'Carga seca',
    BA: 'Báu alumínio',
    BR: 'Báu refrig.',
    BG: 'Bug',
    TR: 'Treminhão',
    SD: 'Sider',
    PL: 'Plataforma',
  };

  return (
    <>
      <span className={classes.title}>
        {vistoria.checkListTipo === 'Entrada' ? 'Vistoria - Entrada' : 'Vistoria - Saída'}
      </span>
      <span>{vistoria.cod_check_list}</span>

      <span className={classes.subTitle}>Informações sobre a instalação</span>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <strong>Equipamento:</strong> {vistoria.equipamento}
        </Grid>
        <Grid item xs={12} sm={4}>
          <strong>Número Série:</strong> {vistoria.serie}
        </Grid>
        <Grid item xs={12} sm={4}>
          <strong>Número NA:</strong> {vistoria.na}
        </Grid>
        <Grid item xs={12}>
          <strong>Número OS:</strong> {os}
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <strong>Instalador:</strong> {vistoria.instalador}
        </Grid>
        <Grid item xs={12}>
          <strong>Local Serviço:</strong> {vistoria.local_servico}
        </Grid>
        <Grid item xs={12}>
          <strong>Tipo de Serviço:</strong>{' '}
          {tipoServicos[vistoria.tipo_servico]}
        </Grid>
      </Grid>

      <span className={classes.subTitle} style={{ marginTop: 30 }}>
        Dados do veículo
      </span>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <strong>Placa:</strong> {vistoria.placa}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Cor:</strong> {vistoria.cor}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Ano:</strong> {vistoria.ano}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Tipo veículo:</strong> {tiposVeiculos[vistoria.tipo_veiculo]}
        </Grid>
        {vistoria.tipo_veiculo === 'CR' && (
          <>
            <Grid item xs={12} sm={6}>
              <strong>Placa carreta:</strong> {vistoria.placa_carreta}
            </Grid>
            {vistoria.tipo_carreta && (
              <Grid item xs={12} sm={6}>
                <strong>Tipo carreta:</strong>{' '}
                {tiposCarretas[vistoria.tipo_carreta]}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
