import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import MaterialTable from 'material-table';
import OportunidadeService from '../Services/oportunidade/oportunidade-service';
import MaskUtil from '../util/MaskUtil';
import authService from '../Services/auth/auth-service';

export default function OportunidadePage() {

  const [_oportunidades, setOportunidade] = useState([]);

  useEffect(() => {

    console.log(authService.getUserInfo());
    let areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
    oportunidades(areaAtendimentoID);

  }, []);

  const oportunidades = async (areaAtendimentoID) => {

    try {

      let oportunidadeResult = await OportunidadeService.oportunidadesRevenda(areaAtendimentoID);

      console.log(oportunidadeResult);
      
      if (oportunidadeResult) {
        let oportunidades = [];

        oportunidadeResult.map((x) => {
          oportunidades.push({
            id: x.OportunidadeID || '',
            oportunidade: x.Oportunidade || '',
            datacriacao:x.DataCriacao || '',
            qtdveiculo: x.qtdVeiculo || 0,
            vl_licenca: MaskUtil.numberToReal(x.valorTotalLicenca) ||  MaskUtil.numberToReal(0), 
            vl_hardware:  MaskUtil.numberToReal(x.valorTotalHardware) ||  MaskUtil.numberToReal(0), 
            ticket_medio:  MaskUtil.numberToReal(x.TicketMedio) || MaskUtil.numberToReal(0),
            status:statusOportunidade(x.Status) || '',

          });
        });

        console.log(oportunidades);
        if(oportunidades.length > 0)
          oportunidades = oportunidades.sort((a, b) =>{ 
            if(b > a)
              return 1;
            else 
              return -1;

          });

        setOportunidade(oportunidades);
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  const statusOportunidade = (status) => {
    switch (status) {
    case 0:
      return 'Aberta';
    case 1:
      return 'Ganha';
    case 2: 
      return 'Perdida';
    default:
      return '';
    }
  }

  return (

    <div>

      <MenuComponent />
      <Container component='main'>
        <MaterialTable
          title="Oportunidades"
          columns={[
            { title: 'Oportunidade', field: 'oportunidade', width: 150 },
            { title: 'Data Criação', field: 'datacriacao', width: 30 },
            { title: 'Status', field: 'status', width: 30 },
            { title: 'Quantidade Veículo', field: 'qtdveiculo', width: 50 },
            { title: 'Ticket Médio', field: 'ticket_medio', width: 50 },
            { title: 'Valor Licença', field: 'vl_licenca', width: 50 },
            { title: 'Valor Hardware', field: 'vl_hardware', width: 50 },
           
          ]}
          data={_oportunidades}
          localization={{
            body: {
              emptyDataSourceMessage: 'Nenhum registro para exibir'
            },
            toolbar: {
              searchTooltip: 'Pesquisar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            }
          }}
        />


      </Container>
    </div>
  );


}

