/* eslint-disable no-undef */
class config {

    configAPIGateway(){
        return {
            API_USER: process.env.REACT_APP_API_GATEWAY_USER,
            API_PASS: process.env.REACT_APP_API_GATEWAY_PASS,
            URI_GATEWAY: process.env.REACT_APP_API_GATEWAY_URI_GATEWAY
        }
    }

    configAPITecnico(){
        return {
            URI_API: process.env.REACT_APP_API_TECNICO_HOST
        };
    }
}

export default new config;
