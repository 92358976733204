import config from '../../config';
import authService from '../auth/auth-service';

const _configAPITecnico = config.configAPITecnico();
class SenhaSupervisorService {

  async buscaSenhaSupervisor(idTerminal) {
    const _headerToken = authService.headerAPITecnico();
    const token = await this.loginWSTT(idTerminal);
    if (token.error == true) {
      return {
        errorLogin: true,
        message: 'Erro ao autenticar. Verifique as credenciais.',
      };
    }

    let usuarioID = authService.getUserInfo().usuario.id;

    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/SenhaSupervisor/listarPorIDTerminal?IdTerminal=${idTerminal}&usuarioID=${usuarioID}&token=${token.token}`, {
        method: 'Get',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return {
        error: false,
        dados: data
      };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error)
      return {
        error: true,
        message: 'Houve um problema ao buscar senha do supervisor.',
      };
    }
  }

  async alterarSenhaSupervisor(body, idTerminal) {
    const _headerToken = authService.headerAPITecnico();
    const token = await this.loginWSTT(idTerminal);
    if (token.error) {
      return {
        error: true,
        message: 'Erro ao autenticar. Verifique as credenciais.',
      };
    }

    let usuarioID = authService.getUserInfo().usuario.id;

    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/SenhaSupervisor/alterarSenhaSupervisor?usuarioID=${usuarioID}&token=${token.token}`, {
        method: 'PATCH',
        headers: _headerToken,
        body: JSON.stringify({
          idConfig: body.idConfig,
          idTerminal: body.idTerminal,
          idSequencia: body.idSequencia,
          senhaManobrista: body.senhaManobrista,
          senhaMotorista: body.senhaMotorista,
          senhaPanico: body.senhaPanico,
          senhaSuper: body.senhaSuper,
        })
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return {
        error: false,
        dados: data
      };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error);
      return {
        error: true,
        message: 'Houve um problema ao autenticar.',
      };
    }
  }

  async transmitirSenhaSupervisor(body, idTerminal) {
    const _headerToken = authService.headerAPITecnico();
    const token = await this.loginWSTT(idTerminal);
    if (token.error) {
      return {
        error: true,
        message: 'Erro ao autenticar. Verifique as credenciais.',
      };
    }

    let usuarioID = authService.getUserInfo().usuario.id;

    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/SenhaSupervisor/transmitirConfiguracoesSenha?usuarioID=${usuarioID}&token=${token.token}`, {
        method: 'POST',
        headers: _headerToken,
        body: JSON.stringify({
          desabilitar_GPS: body.desabilitar_GPS,
          idTerminal: body.idTerminal,
          senhaManobrista: body.senhaManobrista,
          senhaMotorista: body.senhaMotorista,
          senhaPanico: body.senhaPanico,
          senhaSuper: body.senhaSuper,
        })
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.error).map((error) => error);
      }

      if (response.status === 200) return {
        error: false,
        dados: data
      };

      return {
        error: true,
        message: data.error || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao autenticar.',
      };
    }
  }

  async loginWSTT(idTerminal) {
    const _headerToken = authService.headerAPITecnico();
    try {

      const response = await fetch(`${_configAPITecnico.URI_API}/SenhaSupervisor/autenticacao?NumeroSerie=${idTerminal}`, {
        method: 'Post',
        headers: _headerToken
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao autenticar.',
      };
    }
  }
}

export default new SenhaSupervisorService();
