import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logoOmnilink from '../../src/assets/img/logoOmni.png';
import BlueButton from '../components/button/BlueButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import vistoriaService from '../Services/Vistoria/vistoria-service';
import CustomAlert from '../components/alert/CustomAlert';
import { useHistory } from 'react-router-dom';
import { EmailVistoriaCliente } from './components/EmailVistoriaCliente';
import OrdemServicoService from '../Services/OrdemServico/ordem-servico-servico';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  formControl: {
    minWidth: '100%',
  },
  root1: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '35ch',
    },
  },
  subTitle: {
    width: '100%',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 25,
    marginBottom: 20,
  },
  attention: {
    marginTop: 30,
    width: '100%',
    padding: 10,
    border: '1px solid #d3d3d3',
    textAlign: 'center',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 30,
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    maxWidth: 350
  }
}));

export default function VistoriaPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [_dados, setdados] = useState({});
  const [vistoriaExists, setVistoriaExists] = useState(true);
  const [mostrarVistoria, setMostrarVistoria] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [_os, setOs] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);

  useEffect(() => {
    let [, , id, id2] = props.location.pathname.split('/');
    consultacadastrotecnico(id, id2);
  }, [props.isOpen]);

  const consultacadastrotecnico = async (id_agendamento, id_ordem_servico) => {
    const response = await vistoriaService.consultaVistoria(
      id_agendamento,
      id_ordem_servico
    );

    if (response && !response.error) {
      setVistoriaExists(true);
      setdados(response);
      setOs(response.numeroOS);
      if (response.entrada) {
        if (response.saida) {
          setMostrarVistoria(false);
          setMensagemErro('A vistoria entrada/saída já foi aprovada!');
        } else {
          // verifica se o vistoria de saida já foi preenchido
          if (
            Object.keys(response).find(
              (key) => key.includes('_saida') && response[key]
            )
          ) {
            setMostrarVistoria(true);
          } else {
            setMostrarVistoria(false);
            setMensagemErro('A Vistoria de saída ainda não foi preenchido/enviado!');
          }
        }
      } else {
        setMostrarVistoria(true);
      }
    } else {
      setVistoriaExists(false);
      if (response?.message) alert(response?.message);
    }
  };

  return (
    <div
      style={{
        overflow: 'hidden',
        backgroundColor: '#4e9cde',
        width: '100%',
        height:
          !mostrarVistoria || !vistoriaExists
            ? '100vh'
            : '100%',
        padding: 20,
      }}
    >
      {vistoriaExists ? (
        <>
          {mostrarVistoria ? (
            <Container
              style={{
                backgroundColor: '#ffffff',
                position: 'relative',
                borderRadius: 10,
              }}
              component="main"
              maxWidth="md"
            >
              <CssBaseline />

              {/* !! TELA DE EMAIL DO CLIENTE ABAIXO */}
              <EmailVistoriaCliente vistoria={_dados} os={_os} />
            </Container>
          ) : (
            <Container
              style={{
                backgroundColor: '#ffffff',
                position: 'relative',
                borderRadius: 10,
                padding: 30,
              }}
              component="main"
              maxWidth="md"
            >
              <div className={classes.paper}>
                <img src={logoOmnilink} />
                <span style={{ marginTop: 20 }}>{mensagemErro}</span>
              </div>
            </Container>
          )}
        </>
      ) : (
        <Container
          style={{
            backgroundColor: '#ffffff',
            position: 'relative',
            borderRadius: 10,
            padding: 30,
          }}
          component="main"
          maxWidth="md"
        >
          <div className={classes.paper}>
            <img src={logoOmnilink} />
            <span style={{ marginTop: 20 }}>
              Nenhuma Vistoria encontrada tente novamente ou solicite o
              reenvio!
            </span>
            <span style={{ marginTop: 30 }}>
              <BlueButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  window.location.reload();
                }}
                startIcon={<RefreshIcon />}
              >
                RECARREGAR
              </BlueButton>
            </span>
          </div>
        </Container>
      )}
    </div>
  );
}
