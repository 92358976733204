import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class PainelAtivacaoService {
  async painelAtivacao(na) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/PainelAtivacao/${na}`, {
        method: 'Get',
        headers: _headerToken,
      });

      const resultado = await response.json();

      if (response.status === 200) {
        return {
          error: false,
          result: resultado,
        }
      }
      return {
        error: true,
        message: resultado.erro,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Erro buscar dados do painel de ativação'
      };
    }
  }
}
export default new PainelAtivacaoService();
