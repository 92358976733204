/* eslint-disable no-async-promise-executor */
import React, { useEffect, useState } from 'react';
import { Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, Grid, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import MaterialTable from 'material-table';
import clienteService from '../Services/cliente/cliente-service';
import BlueButton from '../components/button/BlueButton';
import AddIcon from '@material-ui/icons/Add';
import authService from '../Services/auth/auth-service';
import produtoService from '../Services/Produto/produto-service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BlueTextField from '../components/textfield/BlueTextField';
import MaskUtil from '../util/MaskUtil';
import oportunidadeService from '../Services/oportunidade/oportunidade-service';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';

export default function VendaPage() {
  const [produtos, setProdutos] = React.useState([]);
  const [_clientes, setCliente] = useState([]);
  const [valor, setValor] = React.useState(0);
  const [valorTotal, setValorTotal] = React.useState(0);
  const [carrinho, setCarrinho] = React.useState([]);
  const [mensagem, setMensagem] = React.useState(null);
  const [clienteCompra, setClienteCompra] = React.useState(null);
  const [qtdeVeiculo, setQtdeVeiculo] = React.useState(0);
  const [load, setLoad] = React.useState(false);
  const [descricao, setDescricao] = React.useState('');
  const [parcela, setParcela] = React.useState(1);

  useEffect(() => {
    let usuario = authService.getUserInfo().usuario;

    console.log(usuario)
    clientes(usuario.tz_area_atendimento_microsigaid);
    consultaProdutos();

  }, []);

  const consultaProdutos = async () => {
    const produtosResult = await produtoService.produtos();
    console.log(produtosResult)
    if (produtosResult)
      setProdutos(produtosResult.produtos);


  }

  const clientes = async (areaAtendimentoID) => {

    try {
      let clientesResult = await clienteService.ClientesByRevenda(areaAtendimentoID);

      console.log(clientesResult)
      if (clientesResult) {
        let clientes = [];

        clientesResult.map((x) => {
          clientes.push({
            id: x.ClienteID || '',
            nome: x.Nome || '',
            cpfcnpj: x.CPFCNPJ || '',
            celular: x.Celular || '',
            telefone: x.Telefone || '',
          });
        });

        setCliente(clientes);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validaOportunidade = () => {
    let mensagem = '';
    if (qtdeVeiculo <= 0)
      mensagem = '[Quantidade de Veículos]';
    if (carrinho.length === 0)
      mensagem += ' [Nenhum Produto Adicionado]';
    if (!clienteCompra)
      mensagem += ' [Cliente Compra]';

    if (mensagem.length > 0) {
      setMensagem({
        titulo: 'Aviso',
        descricao: `Verifique se os campos estão preenchidos corretamente - ${mensagem}`
      });
      return false;
    }
    return true;
  }


  const validaDados = (data) => {
    let mensagem = '';
    if (!data.produto)
      mensagem = 'Produto'
    if (!data.quantidade)
      mensagem += ' Quantidade'

    if (mensagem.length > 0) {
      setMensagem({
        titulo: 'Aviso',
        descricao: `Informe os Campos ${mensagem}`
      });
      return false;
    }
    return true;
  }


  const handleComprar = async () => {
    setLoad(true);
    let usuario = authService.getUserInfo().usuario;
    if (validaOportunidade()) {
      let produtos = [];
      console.log(carrinho);
      let total = 0;

      carrinho.map((c) => {
        console.log(c)
        total += parseFloat(c.valor) * parseFloat(c.quantidade);

        produtos.push({
          ProdutoID: c.id,
          Nome: c.produto,
          Quantidade: parseInt(c.quantidade),
          Valor: c.valor,
          descricao: c.descricao
        })
      });

      let oportunidade = {
        Produtos: produtos,
        ClienteID: clienteCompra.id,
        Resumo: `Oportunidade - ${clienteCompra.nome}`,
        ClienteNome: clienteCompra.nome,
        EntidadeCliente: clienteCompra.cpfcnpj.length <= 14 ? 'contact' : 'account',
        TicketMedio: total,
        QtdVeiculos: qtdeVeiculo,
        parcelas: parcela,
        areaAtendimentoID: usuario.tz_area_atendimento_microsigaid,
        Origem: 100000003
      };

      console.log(oportunidade)
      let resultOportunidade = await oportunidadeService.addOportunidade(oportunidade);
      if (resultOportunidade)
        setMensagem({
          titulo: 'Sucesso',
          descricao: 'Oportunidade Gerada com Sucesso.'
        });
      else
        setMensagem({
          titulo: 'Aviso',
          descricao: 'Não foi possível gerar a oportunidade'
        });
    }

    setLoad(false);
  }

  const parcelas = () => {
    let parcelaComponent = [];
    for (let i = 1; i <= 12; i++)
      parcelaComponent.push(<MenuItem value={i}>{`${i}x`}</MenuItem>);

    return parcelaComponent;

  }

  const handleParcela = (e) => {
    setParcela(e.target.value);
  }
  return (

    <div>

      <MenuComponent />
      <Container component='main'>

        <Paper>

          <Grid container style={{ flexGrow: 1, paddingBottom: 5, paddingLeft: 10 }} spacing={3}>
            <Grid xs={12} md={4} lg={5} item>
              <Autocomplete
                id=""
                value={clienteCompra}
                onChange={async (e, newValue) => {
                  console.log(newValue)
                  if (newValue)
                    setClienteCompra(newValue);

                }}
                options={_clientes}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => <BlueTextField {...params} label="Cliente" />}
              />
            </Grid>
            <Grid xs={8} md={3} lg={2} item>
              <BlueTextField
                id="txtQtdVeiculo"
                value={null}
                onChange={async (e, newValue) => {
                  if (e.target.value)
                    setQtdeVeiculo(parseInt(e.target.value))

                }}
                type="number"
                label="Quantidade de Veículos"
              />

            </Grid>
            <Grid xs={12} md={5} lg={4} item>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Parcelas</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={parcela}
                  onChange={handleParcela}
                >
                  {parcelas()}

                </Select>
              </FormControl>

            </Grid>
          </Grid>


          <MaterialTable
            title="Adicionar Produtos"
            columns={[
              {
                title: 'Produto',
                field: 'produto',
                editable: 'onAdd',
                editComponent: (props) => {
                  /*this.state.assinadaPor !== this.state.usuario  || this.state.status !== '2'*/
                  return (
                    <Autocomplete
                      id=""
                      value={props.value}
                      onChange={async (e, newValue) => {
                        console.log(props)
                        console.log(newValue);
                        if (newValue) {
                          props.onChange(newValue)
                          setValor(newValue.valor);
                          setDescricao(newValue.descricao);
                          if (props.rowData)
                            setValorTotal(parseFloat(newValue.valor) * parseFloat(props.rowData.quantidade || 0));
                        }
                        else {
                          setValor(0);
                          setValorTotal(0)
                        }
                      }}
                      options={produtos}
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => <BlueTextField {...params} label="Produto" />}
                    />
                  )

                },
                width: 300
              },
              { title: 'Descrição', field: 'descricao', width: 250, editable: 'never', render: (rowData) => rowData ? rowData.descricao : descricao },
              { title: 'Valor', field: 'valor', type: 'numeric', width: 80, editable: 'never', render: (rowData) => rowData ? MaskUtil.numberToReal(rowData.valor) : MaskUtil.numberToReal(valor) },
              {
                title: 'Quantidade', field: 'quantidade', type: 'numeric', width: 80,
                editComponent: (props) => {
                  /*this.state.assinadaPor !== this.state.usuario  || this.state.status !== '2'*/
                  return (
                    <BlueTextField
                      id="Valor"
                      value={props.value}
                      onChange={async (e, newValue) => {
                        if (e.target.value)
                          props.onChange(e.target.value)

                      }}
                      onBlur={
                        async (e) => {
                          if (e.target.value)
                            setValorTotal(parseFloat(e.target.value) * parseFloat(valor));
                        }}
                      options={produtos}
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => <BlueTextField {...params} label="Produto" />}
                    />
                  )

                },

              },
              { title: 'Valor Total', field: 'valorTotal', editable: 'never', type: 'numeric', width: 80, render: (rowData) => rowData ? MaskUtil.numberToReal(rowData.valorTotal) : MaskUtil.numberToReal(valorTotal) },
            ]}
            data={carrinho}
            localization={{
              body: {
                emptyDataSourceMessage: 'Nenhum registro para exibir'
              },
              toolbar: {
                searchTooltip: 'Pesquisar'
              },
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{from}-{to} de {count}',
                firstTooltip: 'Primeira página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página'
              }
            }}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {

                  console.log(newData);
                  if (validaDados(newData)) {
                    const produto = {
                      id: newData.produto.id,
                      produto: newData.produto.nome,
                      quantidade: newData.quantidade,
                      valor: newData.produto.valor,
                      descricao: newData.produto.descricao,
                      valorTotal: (newData.quantidade * newData.produto.valor)
                    };

                    setCarrinho([...carrinho, produto]);
                    setValorTotal(0);
                    setValor(0);
                    resolve();
                  }
                  else
                    reject();

                })
            }}
            options={{
              actionsColumnIndex: -1
            }}
          />
        </Paper>
        <div style={{ paddingTop: 10 }}>

          <div>
            {
              !load ?
                <BlueButton disabled={carrinho.length === 0} variant="outlined" onClick={handleComprar}>
                  <AddIcon />
                  Comprar
                </BlueButton> : <FacebookCircularProgress></FacebookCircularProgress>
            }

          </div>
        </div>

      </Container>

      {mensagem && (<div>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{mensagem.titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {mensagem.descricao}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <BlueButton onClick={() => setMensagem(null)} variant="contained" color="primary">
              Ok
            </BlueButton>
          </DialogActions>
        </Dialog>
      </div>)}
    </div>
  );


}

