class DataUtils {

    //Formata hora para o estilo HH:MM e diminui -3H
    formatarHora(dataHoraString){
        const dataHora = new Date(dataHoraString);
        
        dataHora.setHours(dataHora.getHours() - 3);
      
        const horas = String(dataHora.getHours()).padStart(2, '0');
        const minutos = String(dataHora.getMinutes()).padStart(2, '0');
      
        return `${horas}:${minutos}`;
      }

}

export default new DataUtils();