import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const BlueTextField = withStyles(theme => ({
  root: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#209BDE'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#209BDE'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#209BDE'
    }
  }
}))(props => <TextField {...props} />);

export default BlueTextField;
