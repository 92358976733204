import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',  
    margin: 10
  },

  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'block',
    position: 'absolute'
    
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'block',

  },
  circle: {
    strokeLinecap: 'round',
  },
}));

export default function FacebookCircularProgress(props) {
  
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <div>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={4}
          {...props}
          value={100}
       
        />
      </div>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}