import authService from '../auth/auth-service';
import config from '../../config';

const configAPIGateway = config.configAPIGateway();
const _configAPITecnico = config.configAPITecnico();

class ConsultaperfilService {

    async consultaperfiltecnico(idtecnico)
    {
        try{
            let token = await authService.tokenGateway();
            let response = await fetch(`${configAPIGateway.URI_GATEWAY}/prestador/dados?areaAtendimentoID=${idtecnico}`,{
                method: 'GET',
                headers : {
                    'Content-Type' : 'application/json',
                    'Accept' : 'application/json',
                    'Authorization': `Bearer ${token.access_token}`
                  },
            });
            if(response.status === 200)
                return await response.json();
        }catch (error) {
            console.log(error);
        }
    }


      async updatetelefone(id, telefone, endereco, cep, cidade, estado, email)
          {
             try{
                let token = await authService.tokenGateway();
                let response = await fetch(`${configAPIGateway.URI_GATEWAY}/prestador/dados`,{
                method: 'Put',
                headers : {
                    'Content-Type' : 'application/json',
                    'Accept' : 'application/json',
                    'Authorization': `Bearer ${token.access_token}`
                  },
                body: id, telefone, endereco, cep, cidade, estado, email
              });
              console.log('response', response);
            if(response.status === 200)
                return await response.json();
        }catch (error) {
            console.log(error);
        }
    }
    async RecuperarSenha(login)
    {
        try{
            let response = await fetch(`${_configAPITecnico.URI_API}/usuario/senha/recuperar`,{
                method: 'PUT',
                body: login,
                headers:{     
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                }
            
            });
            if(response.status === 200)
                return (true);
        }catch (error) {
            console.log(error);
        }
    }

  /*   async novasnoticias(noticiasModel)
    {
        try{
              let response = await fetch(http:localhost:5001/api/noticiasnovas,{
                method: 'POST',
                headers:_headerToken,
                body:noticiasModel
            });
            if(response.status === 200)
                return await response.json();

        }catch (error) {
            console.log(error);
        }
    }*/
}

export default new ConsultaperfilService();