import React, { useState, useEffect } from 'react';
import {
  makeStyles, Paper, TextField, Typography, 
  Box, CssBaseline, Container, useMediaQuery, 
  CircularProgress, Grid, InputAdornment
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import BlueButton from '../components/button/BlueButton';
import perfilService from '../Services/Perfil/perfil-service';
import { useLocation } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import ParticleAnimation from 'react-particle-animation';
import logoOmnilink from '../../src/assets/img/logoOmni.png';
import CustomAlert from '../components/alert/CustomAlert';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderRadius: 10,
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  passwordCriteria: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));

const PasswordCriteria = ({ label, valid }) => (
  <Typography variant="body2" className={useStyles().passwordCriteria}>
    {valid ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
    {label}
  </Typography>
);

export default function RedefinirSenhaPage(props) {
  const classes = useStyles();
  const location = useLocation();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));
  const [tableKey, setTableKey] = useState(0);
  const [novaSenha, setNovaSenha] = useState('');
  const [repetirSenha, setRepetirSenha] = useState('');
  const [token, setToken] = useState('');
  const [carregandoRedefinicao, setCarregandoRedefinicao] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('success');
  const [senhaValida, setSenhaValida] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [mostrarConfirmacaoSenha, setMostrarConfirmacaoSenha] = useState(false);

  const senhaTecnicoVisivel = () => {
    setMostrarSenha(!mostrarSenha);
  };

  const senhaConfirmacaoTecnicoVisivel = () => {
    setMostrarConfirmacaoSenha(!mostrarConfirmacaoSenha);
  };


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (mobile) {
      setTableKey(tableKey + 1);
    } else {
      setTableKey(tableKey + 1);
    }
  }, [mobile]);

  useEffect(() => {
    const length = novaSenha.length >= 8;
    const uppercase = /[A-Z]/.test(novaSenha);
    const lowercase = /[a-z]/.test(novaSenha);
    const number = /[0-9]/.test(novaSenha);
    const special = /[^A-Za-z0-9]/.test(novaSenha);

    setSenhaValida({ length, uppercase, lowercase, number, special });
  }, [novaSenha]);

  const criarNovaSenha = async (event) => {
    event.preventDefault();
    if (Object.values(senhaValida).every(Boolean) && novaSenha === repetirSenha) {
      setCarregandoRedefinicao(true);

      const dados = {
        confirmacaoNovaSenha: repetirSenha,
        novaSenha: novaSenha,
        token: token
      }
      let response = await perfilService.redefinirSenha(dados);

      if (!response.error) {
        setAlertMessage({ title: 'Sucesso!', body: 'Sua senha foi alterada com sucesso!' });
        setAlertType('success');
        setAlertOpen(true);
        setCarregandoRedefinicao(false);
        setTimeout(() => {
          window.location.href = '/';
        }, 5000);
      } else {
        const mensagem = response.message.split(':');
        const mensagemFormatada = mensagem.length > 1 ? mensagem[1].trim() : response.message;
        setAlertMessage({ title: 'Erro!', body: mensagemFormatada });
        setAlertType('error');
        setAlertOpen(true);
        setCarregandoRedefinicao(false);
      }
    } else {
      setAlertMessage({ title: 'Falha!', body: 'Por favor, preencha os campos corretamente.' });
      setAlertType('fail');
      setAlertOpen(true);
    }
  };

  if (!token) {
    return (
      <div style={{ overflow: 'hidden', position: 'absolute', width: '100%', height: '100%' }}>
        <ParticleAnimation
          numParticles={110}
          interactive={true}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          particleRadius={2.5}
          particleSpeed={1}
          background={{ r: 32, g: 155, b: 222, a: 255 }}
          color={{ r: 255, g: 255, b: 255, a: 255 }}
        />
        <Container component="main">
          <Paper style={{
            backgroundColor: '#ffffff',
            position: 'relative',
            borderRadius: 10,
            marginTop: '100px',
            padding: '20px'
          }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} style={{ textAlign: 'center', wordWrap: 'break-word' }}>
                <h2>O token para redefinição de senha é inválido, tente novamente!</h2>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    );
  }

  return (
    <div style={{ overflow: 'hidden', position: 'absolute', width: '100%', height: '100%' }}>
      <ParticleAnimation
        numParticles={110}
        interactive={true}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        particleRadius={2.5}
        particleSpeed={1}
        background={{ r: 32, g: 155, b: 222, a: 255 }}
        color={{ r: 255, g: 255, b: 255, a: 255 }}
      />

      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        type={alertType}
        onClose={() => setAlertOpen(false)}
      />

      <Container style={{
        backgroundColor: '#ffffff',
        position: 'relative',
        borderRadius: 10,
        marginTop: '70px'
      }}
        component="main"
        maxWidth="xs">

        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <img src={logoOmnilink} alt="Omnilink Logo" />
          </div>
          <form onSubmit={criarNovaSenha}>
            <TextField
              label={<span>Nova Senha <span style={{ color: 'red' }}> *</span></span>}
              type={mostrarSenha ? 'text' : 'password'}
              fullWidth
              variant="outlined"
              className={classes.formField}
              value={novaSenha}
              onChange={(e) => setNovaSenha(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={senhaTecnicoVisivel}>
                      {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={<span>Repetir Nova Senha <span style={{ color: 'red' }}> *</span></span>}
              type={mostrarConfirmacaoSenha ? 'text' : 'password'}
              fullWidth
              variant="outlined"
              className={classes.formField}
              value={repetirSenha}
              onChange={(e) => setRepetirSenha(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={senhaConfirmacaoTecnicoVisivel}>
                      {mostrarConfirmacaoSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={2} mb={2}>
              <PasswordCriteria label="Mínimo de 8 caracteres" valid={senhaValida.length} />
              <PasswordCriteria label="Letra maiúscula" valid={senhaValida.uppercase} />
              <PasswordCriteria label="Letra minúscula" valid={senhaValida.lowercase} />
              <PasswordCriteria label="Número" valid={senhaValida.number} />
              <PasswordCriteria label="Caractere especial" valid={senhaValida.special} />
            </Box>

            <Box display="flex" justifyContent="center" mt={3} style={{ marginTop: 25 }}>
              <BlueButton
                type="submit"
                variant="contained"
                color="primary"
                startIcon={carregandoRedefinicao ? <CircularProgress size={20} /> : <LockResetIcon />}
                disabled={carregandoRedefinicao}
                style={{ height: 55 }}
              >
                {carregandoRedefinicao ? '  Redefinindo... ' : ' Redefinir Senha'}
              </BlueButton>
            </Box>
          </form>
        </div>
      </Container>

    </div>
  );
}
