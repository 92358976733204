import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class VeiculoService {

  async marcas(){
    try{
      let response = await fetch(`${_configAPITecnico.URI_API}/veiculo/marcas`,{
        method: 'GET',    
        headers: authService.headerAPITecnico
      });

      if(response.status === 200)
        return await response.json();

    }catch (error) {
      console.log(error);
    } 
  }

  async modelos(marcaID){
    try{

      let response = await fetch(`${_configAPITecnico.URI_API}/veiculo/modelos/${marcaID}`,{
        method: 'GET',    
        headers: authService.headerAPITecnico
      });

      if(response.status === 200)
        return await response.json();

    }catch (error) {
      console.log(error);
    } 
  }

}
export default new VeiculoService();
