import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();
class GestorService {
	async listaAtividadesTecnicos(dados) {
		let dataInicio = dados.dataInicial ? new Date(dados.dataInicial) : new Date();
		let dataFim = dados.dataFinal ? new Date(dados.dataFinal) : new Date();

		dataInicio.setUTCHours(11, 0, 0, 0);
		dataFim.setUTCHours(21, 0, 0, 0);

		const dataFormatadaInicio = dataInicio.toISOString().slice(0, 19);
		const dataFormatadaFim = dataFim.toISOString().slice(0, 19);

		try {
			const _headerToken = authService.headerAPITecnico();
			const localId = authService.getUserInfo().usuario.localid;
			let response = await fetch(
				`${_configAPITecnico.URI_API}/agendaGestor?LocalId=${localId}&DataInicial=${dataFormatadaInicio}&DataFinal=${dataFormatadaFim}&Pagina=${dados.pagina}&Limite=${dados.limite}&TecnicoNome=${dados.tecnicoNome}`,
				{
					method: 'GET',
					headers: _headerToken,
				},
			);
			const data = await response.json();
			let errorsMessage = null;
			if (data.errors) {
				errorsMessage = Object.values(data.errors).map((error) => error);
			}

			if (response.status === 200) return data;

			return {
				error: true,
				message: data.erro || errorsMessage,
			};
		} catch (error) {
			console.log(error);
			return {
				error: true,
				message: 'Houve um erro ao buscar os dados',
			};
		}
	}
}

export default new GestorService();