import authService from '../auth/auth-service';
import config from '../../config';

const configAPIGateway = config.configAPIGateway();

class ClienteService {

    async add(cliente) {
       
       try {

        let token = await authService.tokenGateway();

        let response = await fetch(`${configAPIGateway.URI_GATEWAY}/create`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token.access_token}`
            },
            body: cliente
        
        });

        console.log(response);

        return response;
           
       } catch (error) {
        console.log(error);
       } 
    
    }

    async ClientesByVendedor(vendedorID)
    {
        try{

            let token = await authService.tokenGateway();

            let response = await fetch(`${configAPIGateway.URI_GATEWAY}/getclientevendedor/${vendedorID}`,{
            method: 'GET',    
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token.access_token}`
                },
            });

            if(response.status === 200)
                return await response.json();

        }catch (error) {
            console.log(error);
        } 
    }

    async ClientesByRevenda(areaAtendimentoID)
    {
        try{

            let token = await authService.tokenGateway();

            let response = await fetch(`${configAPIGateway.URI_GATEWAY}/clientes/revenda/${areaAtendimentoID}`,{
            method: 'GET',    
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token.access_token}`
                },
            });

            if(response.status === 200)
                return await response.json();

        }catch (error) {
            console.log(error);
        } 
    }
}
export default new ClienteService();
