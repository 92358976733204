class MaskUtil {

  cpfcnpjmask(num) {
    if (num) {
      num = num.toString();
      num = num.replace(/\D/g, '');

      switch (num.length) {
        case 4:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case 5:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case 6:
          num = num.replace(/(\d+)(\d{3})/, '$1.$2');
          break;
        case 7:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case 8:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case 9:
          num = num.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
          break;
        case 10:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
          break;
        case 11:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          break;
        case 12:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4');
          break;
        case 13:
          num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
          break;
        case 14:
          num = num.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
          break;
      }
    }
    return num;
  }

  handleCnpjChange(cpfCnpj) {
    let data = cpfCnpj.replace(/\D/g, '');

    if (data.length > 11) { // CNPJ
      let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
        5,
        3
      )}/`;
      if (data.length > 12) {
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      } else {
        cnpj += data.substr(8);
      }
      data = cnpj;
    }

    return data;
  }

  handleCpfChange(cpfCnpj) {
    let data = cpfCnpj.replace(/\D/g, '');

    // CPF
    let cpf = '';
    let parts = Math.ceil(data.length / 3);
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${data.substr(i * 3)}`;
        break;
      }
      cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`;
    }
    data = cpf;

    return data;
  }

  numberToReal(numero) {
    if (numero) {
      numero = numero.toFixed(2).split('.');
      numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.');
      return numero.join(',');
    }
    return 0;
  }

  maskRG(rg) {
    if (rg) {
      rg = rg.replace(/\D/g, '');
      rg = rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
    }
    return rg;
  }

}
export default new MaskUtil;