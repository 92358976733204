import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { CircularProgress, Typography, TextField, InputAdornment, IconButton, debounce } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import authService from '../Services/auth/auth-service';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';
import inventarioService from '../Services/inventario/inventario-service';
import CustomTablePagination from '../components/pagination/pagination-component';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        marginBottom: '5px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        marginTop: '0px',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 20,
    },
    searchInput: {
        width: '500px',
    },
    paperProduto: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        boxSizing: 'border-box',
    },
}));

export default function InventarioPage() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(8);
    const [totalDados, setTotalDados] = useState(0);
    const [dadosInventario, setDadosInventario] = useState([]);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [carregandoTela, setCarregandoTela] = useState(true);
    const [permissaoGestor, setPermissaoGestor] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');
    const [originalData, setOriginalData] = useState([]);

    const buscarInventarios = async (paginaAtual, limite, pesquisa) => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: paginaAtual ? paginaAtual : 0,
                limite: limite ? limite : 10,
                nome: pesquisa ? pesquisa : '',
            };

            const response = await inventarioService.listarInventario(authService.getUserInfo().usuario.id, dados);

            if (response.dados) {
                setOriginalData(response.dados);
                setDadosInventario(response.dados);
                setTotalDados(response.total);
            } else {
                setOriginalData([]);
                setDadosInventario([]);
                setTotalDados(0);
            }
        } catch (error) {
            setMensagemAlert({ title: 'Erro!', body: 'Erro ao buscar dados do inventário.' });
            setTipoAlert('error');
            setAbrirAlert(true);
        }
        setCarregandoTable(false);
    };

    const delayPesquisa = useCallback(
        debounce((nome) => {
            buscarInventarios(0, pageSize, nome);
        }, 700), []
    );

    const valorPesquisa = (nome) => {
        if (nome !== pesquisa) {
            setPesquisa(nome);
            delayPesquisa(nome);
        }
    };

    const limparPesquisa = () => {
        setPesquisa('');
        buscarInventarios(0, pageSize, '');
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarInventarios(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
        buscarInventarios(0, parseInt(event.target.value, 10), pesquisa);
    };

    useEffect(() => {
        setCarregandoTela(true);
        buscarInventarios(page, pageSize, pesquisa);
        setPermissaoGestor(authService.getUserInfo().usuario.gestor);
        setCarregandoTela(false);
    }, []);

    if (carregandoTela) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            {permissaoGestor === true ? (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Inventário</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => {
                                const userInfo = authService.getUserInfo();
                                if (userInfo.usuario.gestor) {
                                    window.location.assign('/gestor');
                                } else {
                                    window.location.assign('/agenda');
                                }
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Inventário
                    </h4>

                    <div className={classes.searchContainer}>
                        <TextField
                            id="campo-pesquisa"
                            className={classes.searchInput}
                            variant="outlined"
                            label="Pesquisar"
                            value={pesquisa}
                            onChange={(event) => valorPesquisa(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={pesquisa ? limparPesquisa : null}>
                                            {pesquisa ? <ClearIcon /> : <SearchIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <Paper className={classes.paper}>
                        {carregandoTable ? (
                            <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                                <CircularProgress />
                            </Grid>
                        ) : dadosInventario.length === 0 ? (
                            <Grid container justifyContent="center" alignItems="center">
                                <Typography
                                    variant="body1"
                                    align="center"
                                    style={{ padding: '16px', color: '#555' }}
                                >
                                    Não há dados.
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid container spacing={1}>
                                    {dadosInventario.map((item) => (
                                        <Grid item key={item.id} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                                            <Paper className={classes.paperProduto}>
                                                <Typography component="div" variant="body2"><strong>Destinatário:</strong> {item.areaAtendimentoNome}</Typography>
                                                <Typography component="div" variant="body2"><strong>Nome do Produto:</strong> {item.produtoNome}</Typography>
                                                <Typography component="div" variant="body2"><strong>Número do Produto:</strong> {item.produtoCodigo}</Typography>
                                                <Typography component="div" variant="body2"><strong>Serial:</strong> {item.serie || "N/A"}</Typography>
                                                <Typography component="div" variant="body2"><strong>Data Cadastro:</strong> {moment(item.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                                                <Typography component="div" variant="body2"><strong>Data Atualização:</strong> {moment(item.dataAlteracao).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item>
                                        <CustomTablePagination
                                            onPageChange={mudarPagina}
                                            page={page}
                                            count={totalDados}
                                            rowsPerPage={pageSize}
                                            quantidadeItens={totalDados}
                                            onRowsPerPageChange={mudarTamanhoPagina}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Paper>
                </div>
            ) : (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Inventário</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => {
                                const userInfo = authService.getUserInfo();
                                if (userInfo.usuario.gestor) {
                                    window.location.assign('/gestor');
                                } else {
                                    window.location.assign('/agenda');
                                }
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Inventário
                    </h4>
                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center">
                            <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                        </Grid>
                    </Paper>
                </div>
            )}
        </>
    );
}
