import authService from '../auth/auth-service';
import config from '../../config';
import apiTecnico from '../axios/apiTecnico';

const _configAPITecnico = config.configAPITecnico();

class VistoriaService {

  async aceitarEntrada(agendamentoID, ordemservicoID) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/checklist/entrada/aceitar/${agendamentoID}/${ordemservicoID}`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico
        }
      );

      return response.json();
      
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao aceitar vistoria de entrada',
      };
    }
  }

  async aceitarSaida(agendamentoID, ordemservicoID) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/checklist/saida/aceitar/${agendamentoID}/${ordemservicoID}`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico
        }
      );
      if (response.status === 200) return response.json();
      return {
        error: true,
        message: 'Houve um problema ao aceitar vistoria de saida',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao aceitar vistoria de saida',
      };
    }
  }

  async recusarEntrada(agendamentoID, ordemservicoID) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/checklist/entrada/recusar/${agendamentoID}/${ordemservicoID}`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico
        }
      );
      if (response.status === 200) return response.json();
      return {
        error: true,
        message: 'Houve um problema ao recusar vistoria de entrada',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao recusar vistoria de entrada',
      };
    }
  }

  async recusarSaida(agendamentoID, ordemservicoID) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/checklist/saida/recusar/${agendamentoID}/${ordemservicoID}`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico()
        }
      );
      if (response.status === 200) return response.json();
      return {
        error: true,
        message: 'Houve um problema ao recusar vistoria de saida',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao recusar vistoria de saida',
      };
    }
  }

  async consultaVistoria(agendamentoID, ordemservicoID) {
    try {
      const response = await fetch(
        `${_configAPITecnico.URI_API}/checklist/${agendamentoID}/${ordemservicoID}`,
        {
          method: 'Get',
          headers: authService.headerAPITecnico()
        }
      );

      if (response.status === 200) return response.json();
      if (response.status === 204) return null;

      return {
        error: true,
        message: 'Houve um problema ao consultar vistoria',
      };
    } catch (error) {
      console.log(error);
      return {
        error: true,
        message: 'Houve um problema ao consultar vistoria',
      };
    }
  }

  async updateVistoria(agendamentoID, ordemservicoID, vistoria) {
    try {
      const response = await apiTecnico.put(
        `${_configAPITecnico.URI_API}/checklist/atualizar/${agendamentoID}/${ordemservicoID}`,
        vistoria,
        { headers: authService.headerAPITecnico() }
      );

      if (response.status === 200) return response;
      return {
        error: true,
        message:
          response.data?.erro || 'Houve um problema ao atualizar o vistoria',
      };
    } catch (error) {
      return {
        error: true,
        message:
          error?.response?.data?.erro ||
          'Houve um problema ao atualizar o vistoria',
      };
    }
  }

  async createVistoria(vistoria) {
    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/checklist`, {
        method: 'Post',
        headers: authService.headerAPITecnico(),
        body: vistoria,
      });

      if (response.status === 201) return response;

      return {
        error: true,
        message: 'Houve um problema ao cadastrar o vistoria',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao cadastrar o vistoria',
      };
    }
  }

  async enviaEmailChecklist(vistoria, emailPrincipal){
    try {
      const emailTratado = encodeURIComponent(emailPrincipal);
      const response = await fetch(`${_configAPITecnico.URI_API}/checklist/email?destinatario=${emailTratado}`, {
        method: 'POST',
        headers: authService.headerAPITecnico(),
        body: JSON.stringify(vistoria),
      });
  
      if (response.status === 200) return response;
      return {
        error: true,
        message: 'Houve um problema ao enviar o e-mail',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao enviar o e-mail',
      };
    }
  }

  async enviaPDFVistoria(dados){
    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/checklist/pdfVistoria`, {
        method: 'PUT',
        headers: authService.headerAPITecnico(),
        body: dados,
      });
  
      if (response.status === 200) return response;
      return {
        error: true,
        message: 'Houve um problema ao enviar PDF ao CRM.',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao enviar PDF ao CRM.',
      };
    }
  }
}
export default new VistoriaService();
