import React, { useState, useEffect, useCallback } from 'react';
import {
  Paper, Table, TableBody, Grid, TextField, TableCell,
  TableContainer, TableHead, TableRow, Typography, Box,
  useMediaQuery, CircularProgress, IconButton, InputAdornment,
  Dialog, DialogContent, DialogActions, DialogTitle, Button
} from '@material-ui/core';
import MenuComponent from '../../components/menu/menu-component';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomTablePagination from '../../components/pagination/pagination-component';
import CloseIcon from '@material-ui/icons/Close';
import authService from '../../Services/auth/auth-service';
import { Alert } from '@material-ui/lab';
import CustomAlert from '../../components/alert/CustomAlert';
import NotificacaoService from '../../Services/notificacoes/notificacoes-tecnico-service';
import debounce from 'lodash/debounce';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import BlueButton from '../../components/button/BlueButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '18px',
    textAlign: 'start',
    padding: '0 0px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
}));

const NotificacoesPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [notificacoes, setNotificacoes] = useState([]);
  const [totalNotificacoes, setTotalNotificacoes] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [pesquisa, setPesquisa] = useState('');
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState({ title: '', body: '' });
  const [tipoAlert, setTipoAlert] = useState('');
  const [carregandoTable, setCarregandoTable] = useState(true);
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));
  const [descricaoNotificacao, setDescricaoNotificacao] = useState('');
  const [abrirModalVisualizarDescricao, setAbrirModalVisualizarDescricao] = useState(false);
  const [permissao, setPermissao] = useState(0);
  const [loading, setLoading] = useState(true);

  const abrirModalDescricao = (idNotificacao, descricaoNotificacao, statusVisualizacao) => {
    setDescricaoNotificacao(descricaoNotificacao)
    setAbrirModalVisualizarDescricao(true);
    if (statusVisualizacao === false && idNotificacao !== '') {
      notificacaoLida(idNotificacao);
    }
  }

  const fecharModalVisualizarDescricao = () => {
    setAbrirModalVisualizarDescricao(false);
  }

  const buscarNotificacoes = async (page, pageSize, pesquisa) => {
    setCarregandoTable(true);
    const dados = {
      pagina: page ? page : 0,
      limite: pageSize ? pageSize : 8,
      nome: pesquisa ? pesquisa : '',
    }

    const data = await NotificacaoService.listarNotificacoesVisaoTecnico(authService.getUserInfo().usuario.id, dados);

    if (!data.error) {
      setNotificacoes(data.dados);
      setTotalNotificacoes(data.total);
    } else {
      setNotificacoes([]);
      setTotalNotificacoes(0);
    }

    setCarregandoTable(false);
  };

  const notificacaoLida = async (idNotificacao) => {
    let idsNotificacoes = [];

    idsNotificacoes.push(idNotificacao);

    const response = await NotificacaoService.visualizarNotificacao(idsNotificacoes);
    if (response.error) {
      setMensagemAlert({ title: 'Erro!', body: response.message });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    } else {
      await buscarNotificacoes(page, pageSize, pesquisa);
    }
  }

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    buscarNotificacoes(newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    buscarNotificacoes(0, parseInt(event.target.value, 10), pesquisa);
  };

  const delayPesquisa = useCallback(
    debounce((nome) => {
      setPage(0);
      buscarNotificacoes(0, pageSize, nome);
    }, 700), []
  );

  const pesquisarDados = (event) => {
    const nome = event.target.value;

    if (nome !== pesquisa) {
      setPesquisa(nome);
      delayPesquisa(nome);
    }
  };

  const limparPesquisa = () => {
    setPesquisa('');
    setPage(0)
    buscarNotificacoes(0, pageSize, '');
  };

  const renderStatusVisualizacao = (notificacao) => {
    const isVisualizada = notificacao.noticacaoLida;
    const bgColor = isVisualizada ? 'rgba(3, 201, 169, 1)' : 'rgba(255, 99, 132, 1)';

    return (
      <Alert
        severity={isVisualizada ? "success" : "error"}
        iconMapping={{
          success: <CheckIcon style={{ marginRight: '8px', color: 'white' }} />,
          error: <CloseIcon style={{ marginRight: '8px', color: 'white' }} />,
        }}
        style={{
          fontSize: '0.875rem',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          color: 'white',
          height: 55,
          marginTop: '10px',
          backgroundColor: bgColor,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isVisualizada ? "Visualizada" : "Não visualizada"}
        </div>
      </Alert>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setPermissao(authService.getUserInfo().usuario.gestor);
      await buscarNotificacoes(page, pageSize, pesquisa);
      setLoading(false);
    };

    fetchData();
  }, [mobile]);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {
        permissao === false || permissao === null ? (
          <>
            <MenuComponent />

            <CustomAlert
              open={abrirAlert}
              message={mensagemAlert}
              type={tipoAlert}
              onClose={() => setAbrirAlert(false)}
            />

            <div style={{ margin: 30 }}>
              <h3 className={classes.tituloStyle}>Notificações</h3>
              <h4 className={classes.subtituloStyle}>
                <span
                  onClick={() => {
                    const userInfo = authService.getUserInfo();
                    if (userInfo.usuario.gestor) {
                      window.location.assign('/gestor');
                    } else {
                      window.location.assign('/agenda');
                    }
                  }}
                  className={classes.linkStyle}
                  style={{ cursor: 'pointer' }}
                >
                  Home
                </span>{' '}
                {'>'} Notificações
              </h4>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <TextField
                  label="Pesquisar"
                  variant="outlined"
                  value={pesquisa}
                  onChange={pesquisarDados}
                  style={{ width: mobile ? '100%' : '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {pesquisa ? (
                          <IconButton onClick={limparPesquisa}>
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <Dialog
                aria-labelledby="customized-dialog-title"
                open={abrirModalVisualizarDescricao}
                className="custom-dialog"
                style={{ marginTop: 35, marginBottom: 10 }}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    fecharModalVisualizarDescricao();
                  }
                }}
              >
                <DialogTitle>
                  <Grid container alignItems="center">
                    <Grid item xs={10} sm={11}>
                      Descrição da Notificação
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton onClick={fecharModalVisualizarDescricao}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{
                        textAlign: 'justify',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {descricaoNotificacao}
                    </Typography>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={fecharModalVisualizarDescricao}
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>

              <Paper className={classes.paper}>
                {carregandoTable ? (
                  <div className={classes.loadingContainer}>
                    <CircularProgress />
                  </div>
                ) :
                  notificacoes.length === 0 ? (
                    <Grid container justifyContent="center" alignItems="center" >
                      <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: 15 }}>
                        Não há notificações.
                      </Typography>
                    </Grid>
                  ) : isMobile ? (
                    <Grid container spacing={1}>
                      {notificacoes.map((notificacao) => (
                        <Grid item xs={12} sm={6} md={3} key={notificacao.id}>
                          <Paper
                            style={{
                              padding: '16px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              minHeight: '250px',
                              height: '100%',
                              boxSizing: 'border-box',
                              overflow: 'hidden',
                            }}
                          >
                            <Box padding={1} style={{ flexGrow: 1 }}>
                              <Typography
                                component="div"
                                variant="body2"
                                style={{ wordBreak: 'break-word' }}
                              >
                                {notificacao.tituloNotificacao}
                              </Typography>
                              <Typography
                                component="div"
                                variant="body2"
                                style={{ color: 'gray', wordBreak: 'break-word', whiteSpace: 'normal' }}
                              >
                                {notificacao.textoNotificacao.length > 40
                                  ? `${notificacao.textoNotificacao.substring(0, 40)}...`
                                  : notificacao.textoNotificacao}
                              </Typography>
                              <Typography component="div" variant="body2">
                                <strong>Data de Notificação:</strong>{' '}
                                {notificacao.dataCadastro
                                  ? new Date(notificacao.dataCadastro).toLocaleString()
                                  : ''}
                              </Typography>
                            </Box>

                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12}>
                                <Typography component="div" variant="body2">{renderStatusVisualizacao(notificacao)}</Typography>
                              </Grid>
                              <Grid item xs={12} style={{ marginTop: 5 }}>
                                <BlueButton
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  style={{ height: 55 }}
                                  startIcon={<VisibilityIcon />}
                                  onClick={() => abrirModalDescricao(notificacao.id, notificacao.textoNotificacao, notificacao.noticacaoLida)}
                                >
                                  <span style={{ fontSize: '0.7rem' }}>Visualizar Descrição</span>
                                </BlueButton>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <TableContainer component={Paper} style={{ maxWidth: '100%', overflowX: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '20%' }}>Título</TableCell>
                            <TableCell style={{ width: '40%', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                              Descrição
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>Data de Notificação</TableCell>
                            <TableCell style={{ width: '20%' }}>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notificacoes.map((notificacao) => (
                            <TableRow key={notificacao.id}>
                              <TableCell style={{ width: '20%' }}>{notificacao.tituloNotificacao}</TableCell>
                              <TableCell style={{ width: '40%', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                {notificacao.textoNotificacao}
                              </TableCell>
                              <TableCell style={{ width: '20%' }}>
                                {notificacao.dataCadastro ? new Date(notificacao.dataCadastro).toLocaleString() : ''}
                              </TableCell>
                              <TableCell style={{ width: '20%' }}>{renderStatusVisualizacao(notificacao)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                  <Grid item>
                    {notificacoes.length > 0 && (
                      <CustomTablePagination
                        count={totalNotificacoes}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={mudarPagina}
                        quantidadeItens={totalNotificacoes}
                        onRowsPerPageChange={mudarTamanhoPagina}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div >
          </>
        ) : (
          <>
            <MenuComponent />
            <div style={{ margin: 30 }}>
              <h3 className={classes.tituloStyle}>Notificações</h3>
              <h4 className={classes.subtituloStyle}>
                <span
                  onClick={() => (window.location.href = '/agenda')}
                  className={classes.linkStyle}
                  style={{ cursor: 'pointer' }}
                >
                  Home
                </span>{' '}
                {'>'} Notificações
              </h4>

              <Paper className={classes.paper}>
                <Grid container justifyContent="center">
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                    Você não tem permissão para acessar esta página!
                  </h2>
                </Grid>
              </Paper>
            </div>
          </>
        )
      }
    </>
  )
};

export default NotificacoesPage;
