import React, { useEffect, useState } from 'react';
import '../agenda/components/react-big-calendar.css';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTableCustom } from '../components/table/material-table-custom';
import BlueButton from '../components/button/BlueButton';
import CancelIcon from '@material-ui/icons/Cancel';
import agendaService from '../Services/Agenda/agenda-service';
import authService from '../Services/auth/auth-service';
import CustomAlert from '../components/alert/CustomAlert';

const styles = (theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


export default function DetalheOrdemServicoPage({ nf, handleClose }) {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [_servicos, setServicos] = useState([]);
  const [load, setLoad] = useState(false);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');

  const servicos = async (areaAtendimentoID) => {
    setLoad(true);
    try {
      let servicosResult = await agendaService.nas(areaAtendimentoID);

      if (servicosResult) setServicos(servicosResult);
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao consultar serviços.' });
      setTipoAlert('error');
      setAbrirAlert(true);
      setLoad(false);
    }
    setLoad(false);
  };

  // Função para filtrar os dados com base nas datas selecionadas
  const filterDataByDate = (data) => {
    if (!startDate || !endDate) return data;
    return data.filter((item) => {
      const itemDate = moment(item.inicio);
      return itemDate.isBetween(startDate, endDate, 'day', '[]');
    });
  };
  const styleStatus = (status) => {
    switch (status) {
      case 1:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 10,
              color: 'white',
              background: '#73ae2e',
              width: '100px',
            }}
          >
            Concluído
          </div>
        );
      case 2:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 6,
              color: 'white',
              background: 'red',
              width: '100px',
            }}
          >
            Cancelado
          </div>
        );
      case 3:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 6,
              color: 'black',
              background: '#98FB98',
              width: '100px',
            }}
          >
            Agendado
          </div>
        );
      default:
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'block',
              borderRadius: 6,
              color: 'white',
              background: '#f2a821',
              width: '100px',
            }}
          >
            Agendado
          </div>
        );
    }
  };

  useEffect(() => {
    let usuario = authService.getUserInfo().usuario;

    servicos(usuario.tz_area_atendimento_microsigaid);
  }, []);
  useEffect(() => {
    // Buscar serviços com o filtro por data quando startDate e endDate forem definidos
    if (startDate && endDate) {
      servicos(authService.getUserInfo().usuario.tz_area_atendimento_microsigaid);
    }
  }, [startDate, endDate]);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      fullScreen
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" onClose={handleClose} >
        Ordens de Serviço Técnico
      </DialogTitle>
      <DialogContent dividers>
        <CustomAlert
          open={abrirAlert}
          message={mensagemAlert}
          type={tipoAlert}
          onClose={() => setAbrirAlert(false)}
        />

        <MaterialTableCustom
          title="Serviços"
          columns={[
            {
              title: 'Agendamento',
              field: 'na',
              width: '50%',
            },
            {
              title: 'Status',
              field: 'descStatus',
              render: (rowData) => styleStatus(rowData.status),
              width: '100%',
            },

          ]}

          data={filterDataByDate(_servicos)}
          localization={{
            body: {
              emptyDataSourceMessage: 'Nenhum registro para exibir',
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
              exportCSVName: 'Exportar em CSV',
              exportPDFName: 'Exportar em PDF',
              exportTitle: 'Exportar',
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página',
            },
          }}
          options={{
            actionsColumnIndex: -1,
            search: true,
            toolbarButtonAlignment: 'right',
            pageSize: 10,
          }}
          detailPanel={[rowData => ({
            render: rowData => {
              return (<table>

                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <strong>NA:</strong> {rowData.na}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Status:</strong> {rowData.descStatus}
                    </Grid>
                    <Grid item xs={12}>
                      <strong>Cliente:</strong> {rowData.nomeCliente}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>Serviço:</strong> {rowData.servico}
                    </Grid>

                    <Grid item xs={12}>
                      <strong>Assunto:</strong> {rowData.subject}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Início:</strong>{' '}
                      {rowData.inicio
                        ? moment(rowData.inicio).format('DD/MM/YYYY HH:MM:SS')
                        : ''}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Término:</strong>{' '}
                      {rowData.termino
                        ? moment(rowData.termino).format('DD/MM/YYYY HH:MM:SS')
                        : ''}
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                  <h3>Informações de endereço</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <strong>Logradouro:</strong> {rowData.tz_Endereco_Rua}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>Número:</strong> {rowData.tz_Endereco_Numero}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>CEP:</strong> {rowData.tz_endereco_cepIdName}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>Bairro:</strong> {rowData.tz_endereco_bairro}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>Cidade:</strong>{' '}
                      {rowData.tz_endereco_cidadeIdName}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>Estado:</strong>{' '}
                      {rowData.tz_endereco_EstadoIdName}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>Complemento:</strong>{' '}
                      {rowData.tz_endereco_complemento}
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                  <h3>Informações do veículo</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <strong>Placa:</strong> {rowData.tz_VeiculoIdName}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <strong>Placa Carreta:</strong> {rowData.tz_placa_carreta}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <strong>Série:</strong>{' '}
                      {rowData.tz_Num_Serie_Rastreador_Instalado}
                    </Grid>
                  </Grid>
                </DialogContent>
                <tbody>
                  <td>
                  </td>
                </tbody>
              </table>
              );

            }
          })
          ]}
        />
      </DialogContent>
      <DialogActions>
        <BlueButton onClick={handleClose} variant="contained" color="primary">
          <CancelIcon />
          Fechar
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
}
