import React, { useEffect, useState } from 'react';
import '../../agenda/components/react-big-calendar.css';
import 'moment/locale/pt-br';
import {
  Box, Grid, Dialog,
  DialogActions, Paper,
  DialogContent, InputLabel, InputAdornment,
  DialogTitle, CircularProgress, Typography, TextField
} from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import notaFiscalService from '../../Services/NotaFiscal/nota-fiscal-service';
import authService from '../../Services/auth/auth-service';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@material-ui/core/Button';
import BlueButton from '../../components/button/BlueButton'
import usuarioService from '../../Services/Usuario/usuario-service';
import { useMediaQuery, useTheme } from '@mui/material';
import CustomAlert from '../../components/alert/CustomAlert';
import CloseIcon from '@material-ui/icons/Close';
import BlueTextField from '../../components/textfield/BlueTextField';
import CustomTablePagination from '../../components/pagination/pagination-component';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloPaperStyle: {
    fontFamily: 'Mont, sans-serif',
    fontSize: '19px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  actions: {
    marginBottom: 6,
  },
}));

export default function DetalheNotaFiscal({ nf, handleClose, microSigaID }) {
  const [detalhes, setDetalhe] = useState([]);
  const [dadosNF, setDadosNF] = useState([]);
  const [selectItensSeries, setSelectItensSeries] = useState([]);
  const [itensQuantidadeSelect, setItensQuantidadeSelect] = useState([]);
  const [abrirModal, setAbrirModal] = useState(false);
  const [observacao, setObservacao] = useState('');
  const [motivo, setMotivo] = useState('');
  const [confirmeModalBotao, setConfirmeModalBotao] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const classes = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [efeitoCarregando, setEfeitoCarregando] = useState(false);
  const [tipoAlert, setTipoAlert] = useState('error');
  const tecnicoPrestador = authService?.getUserInfo()?.usuario?.tecnico;
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [totalDados, setTotalDados] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [dadosBusca, setDadosBusca] = useState('');
  const [dadosFiltrados, setDadosFiltrados] = useState([]);
  const [expandirPainel, setExpandirPainel] = useState(null);
  let selectTimeout;

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const expandirSeletor = (panel) => (event, isExpanded) => {
    setExpandirPainel(isExpanded ? panel : null);
  };

  const procurarItem = (term) => {
    if (term) {
      const filtro = detalhes?.filter((item) =>
        item.descProduto?.toLowerCase().includes(term.toLowerCase()) ||
        item.codProduto?.toLowerCase().includes(term.toLowerCase())
      );
      setDadosFiltrados(filtro);
    }
  };

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService?.getUserInfo()?.usuario?.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  const botaoConfirmar = async () => {
    if (observacao.trim() === '' || motivo === '') {
      setMensagemAlert({ title: 'Falha!', body: 'Por favor, preencha todos os campos obrigatórios.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
    setConfirmeModalBotao(true);
  };

  const handleConfirmModalOpen = async () => {
    enviarContestacao();

    setDadosNF([]);
    setSelectItensSeries([]);
    setItensQuantidadeSelect({});
    setObservacao('');
    setMotivo('');
    fecharModal();
  };

  const cancelarModalConfirmacao = async () => {
    setConfirmeModalBotao(false);
  };

  const idItemNotaFiscal = (itemId) => {
    const IDItem = dadosNF.find((item) => {
      return item.detalhes.find((x) => x.id === itemId);
    });

    if (IDItem) {
      return IDItem.id;
    }
  }

  const selecionarItem = (rowData) => {
    setDadosNF((prevRows) => {
      const idSelecionado = new Set(prevRows.map((row) => row.id));

      if (idSelecionado.has(rowData.id)) {
        idSelecionado.delete(rowData.id);
      } else {
        idSelecionado.add(rowData.id);
      }

      const itemSelecionado = [...idSelecionado].map((id) => detalhes.find((row) => row.id === id));

      return itemSelecionado;
    });
  };

  const alterarValorItensQuantidadeSemSerie = (ItemId, quantidadeSelecionada) => {
    setItensQuantidadeSelect((prevItensQuantidadeSelect) => {
      const novoItensQuantidadeSelect = { ...prevItensQuantidadeSelect };

      if (novoItensQuantidadeSelect[ItemId] !== undefined) {
        novoItensQuantidadeSelect[ItemId] = quantidadeSelecionada;
      } else {
        novoItensQuantidadeSelect[ItemId] = quantidadeSelecionada;
      }
      return novoItensQuantidadeSelect;
    });
  };

  const alterarValorItemSerie = (rowData) => {
    setSelectItensSeries((prevRows) => {
      const idSelecionado = new Set(prevRows);

      if (idSelecionado.has(rowData.id)) {
        idSelecionado.delete(rowData.id);
      } else {
        idSelecionado.add(rowData.id);
      }
      return Array.from(idSelecionado);
    });
  };

  const botaoContestar = () => {
    setAbrirModal(true);
  };

  const fecharModal = () => {
    setAbrirModal(false);
  };

  const enviarContestacao = async () => {
    setEfeitoCarregando(true);
    const notaFiscalItemsWithData = [];

    if (selectItensSeries.length > 0) {
      notaFiscalItemsWithData.push({
        NotaFiscalItemId: idItemNotaFiscal(selectItensSeries[0]),
        Quantidade: selectItensSeries.length,
        NotaFiscalItemSeriesIds: selectItensSeries,
      });
    }

    const selectedItemsWithoutSeriesKeys = Object.keys(itensQuantidadeSelect);
    selectedItemsWithoutSeriesKeys.forEach((index) => {
      const quantidade = itensQuantidadeSelect[index];
      notaFiscalItemsWithData.push({
        NotaFiscalItemId: index,
        Quantidade: quantidade,
        NotaFiscalItemSeriesIds: [],
      });
    });

    const requestBody = {
      NotaFiscalId: nf.id,
      Motivo: motivo,
      Observacoes: observacao,
      NotaFiscalItems: notaFiscalItemsWithData,
    };

    let areaAtendimento = microSigaID || authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;

    const response = await notaFiscalService.contestarNF(requestBody, areaAtendimento);

    if (response.error) {
      setMensagemAlert({ title: 'Erro!', body: response.message });
      setTipoAlert('error');
      setAbrirAlert(true);
      setTableKey((prevKey) => prevKey + 1);
      setEfeitoCarregando(false);

    } else {
      setMensagemAlert({ title: 'Sucesso!', body: 'Contestação enviada com sucesso!' });
      setTipoAlert('success');
      setAbrirAlert(true);
      setTableKey((prevKey) => prevKey + 1);
      setEfeitoCarregando(false);
    }

    setDadosNF([]);
    setSelectItensSeries([]);
    setItensQuantidadeSelect({});
    setObservacao('');
    setMotivo('');
    setAbrirModal(false);
    setConfirmeModalBotao(false);
    setEfeitoCarregando(false);
    fecharModal();
  };

  const mudarPagina = (event, novaPagina) => {
    setPage(novaPagina);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getDetalheNF(nf.id);
    buscarUsuarioEstoqueAtivo();
  }, [nf.id, mobile]);
  
  
   const openSelectWithTimeout = () => {
    setIsSelectOpen(true);
    selectTimeout = setTimeout(() => {
      setIsSelectOpen(false); 
    }, 60000);
  };

  const handleSelectChange = (e) => {
    clearTimeout(selectTimeout); 
    setMotivo(e.target.value);
    setIsSelectOpen(false);
  };

  useEffect(() => {
    return () => clearTimeout(selectTimeout);
  }, []);

  useEffect(() => {
    const filtrarItens = () => {
      if (dadosBusca === '') {
        setDadosFiltrados(detalhes);
      } else {
        setDadosFiltrados(
          detalhes?.filter(item =>
            item.descProduto?.toLowerCase().includes(dadosBusca.toLowerCase()) ||
            item.codProduto?.toLowerCase().includes(dadosBusca.toLowerCase())
          )
        );
      }
      setPage(0);
    };

    setDadosFiltrados(detalhes);
    filtrarItens();
  }, [dadosBusca, detalhes]);

  const getDetalheNF = async (idNF) => {
    setCarregandoTable(true);
    let areaAtendimento = microSigaID || authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;
    var responseDetalhe = await notaFiscalService.detalheNF(areaAtendimento, idNF);
    if (responseDetalhe) {
      setDetalhe(responseDetalhe);
      setTotalDados(responseDetalhe.length);
    }
    setCarregandoTable(false);
  };

  const itensPaginados = dadosFiltrados.slice(page * pageSize, page * pageSize + pageSize);

  const aplicarEstilos =
    nf.Descricao === 'Recebido' &&
    usuarioEstoqueAtivo &&
    (tecnicoPrestador === false || tecnicoPrestador === null)

  return (
    <>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      <div style={{ margin: 30 }}>
        <Dialog
          aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              fecharModal();
            }
          }}
          open={abrirModal}
          style={{ marginTop: 35, marginBottom: 10 }}
          maxWidth="sm"
          fullWidth
          disableEnforceFocus
          disableAutoFocus
        >
          <DialogTitle>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11}>
                Contestar Nota Fiscal - {nf.numero}
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={fecharModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ marginBottom: '16px' }}>
              <InputLabel id="observacao_label" style={{ marginBottom: '8px' }}>
                Observação: <span style={{ color: 'red' }}> *</span>
              </InputLabel>
              <BlueTextField
                name="txtobs"
                fullWidth
                placeholder="Digite sua observação"
                onChange={(e) => setObservacao(e.target.value)}
                value={observacao || ''}
                multiline
                inputProps={{
                  maxLength: 500,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </div>

            <div style={{ marginBottom: '16px' }}>
            <InputLabel id="motivo_label">
            Motivo: <span style={{ color: 'red' }}> *</span>
          </InputLabel>
            <Select
              id="motivo"
              value={motivo}
              onChange={handleSelectChange}
              onOpen={openSelectWithTimeout} // Abre com timeout
              open={isSelectOpen}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Selecione o Motivo
              </MenuItem>
              <MenuItem value="ItensNaoChegaram">Itens não chegaram</MenuItem>
              <MenuItem value="ItensChegaramComDefeito">Itens chegaram com defeito</MenuItem>
            </Select>
            </div>
          </DialogContent>

          <DialogActions style={{ justifyContent: 'space-around' }}>
            <Button
              variant="contained"
              fullWidth
              onClick={fecharModal}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Cancelar
            </Button>
            <BlueButton
              variant="contained"
              color="primary"
              size="large"
              onClick={botaoConfirmar}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Enviar
            </BlueButton>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              cancelarModalConfirmacao();
            }
          }}
          open={confirmeModalBotao}
          style={{ marginTop: 35, marginBottom: 10 }}
          disableEnforceFocus
          disableAutoFocus
        >
          <DialogTitle>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11}>
                Confirmação
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={cancelarModalConfirmacao}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
              Tem certeza de que deseja enviar a contestação?
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start' }}>
              <strong>OBS:</strong>  A contestação uma vez feita não poderá ser desfeita.
            </Typography>
          </DialogContent>

          <DialogActions style={{ justifyContent: 'space-around' }}>
            <Button
              variant="contained"
              fullWidth
              disabled={efeitoCarregando}
              onClick={cancelarModalConfirmacao}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Cancelar
            </Button>
            <BlueButton
              variant="contained"
              color="primary"
              size="large"
              disabled={efeitoCarregando}
              onClick={handleConfirmModalOpen}
              startIcon={efeitoCarregando ? <CircularProgress size={20} /> : ''}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              {efeitoCarregando ? 'Enviando...' : 'Confirmar'}
            </BlueButton>
          </DialogActions>
        </Dialog>

        <Grid className={classes.actions} container justifyContent="space-between" alignItems="center">
          <Grid item xs={3} sm={1} md={1}>
            <Button
              onClick={handleClose}
              style={{ color: '#209BDE' }}
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </Button>
          </Grid>
          {usuarioEstoqueAtivo && nf.Descricao === 'Recebido' && (
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 15 }}>
                <BlueButton
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ height: 55 }}
                  onClick={botaoContestar}
                  disabled={dadosNF.length === 0 || (selectItensSeries.length === 0 && Object.keys(itensQuantidadeSelect).length === 0) || nf.Descricao !== 'Recebido'}
                >
                  Contestar
                </BlueButton>
              </Box>
            </Grid>
          )}
        </Grid>

        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.tituloPaperStyle}>
              <strong>Detalhe da Nota Fiscal - {nf.numero}</strong>
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Número NF:</strong> {nf.numero}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Série:</strong> {nf.serie}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Status:</strong> {nf.Descricao}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Data Emissão:</strong> {nf.dataEmissao}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Previsão de entrega:</strong> {nf.dataPrevisaoEntrega?.toLocaleString('pt-BR') ? nf.dataPrevisaoEntrega?.toLocaleString('pt-BR') : 'Sem previsão de entrega'}
            </Grid>
            {nf.dataEntrega !== null ? (
              <Grid item xs={12} className={classes.subtituloStyle}>
                <strong>Data entrega:</strong> {nf.dataEntrega?.toLocaleString('pt-BR')}
              </Grid>
            ) : (null)}
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container spacing={1} style={{ justifyContent: 'space-between' }}>
            <Grid item>
              <strong className={classes.tituloPaperStyle}>
                Itens da nota
              </strong>
            </Grid>

            <Grid item xs={12} sm={3} style={{ margin: 5, marginBottom: mobile ? 20 : 0 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Pesquisar"
                value={dadosBusca}
                onChange={(e) => {
                  const term = e.target.value;
                  setDadosBusca(term);
                  procurarItem(term);
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {dadosBusca ? (
                        <IconButton
                          onClick={() => {
                            setDadosBusca('');
                            procurarItem('');
                          }}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {carregandoTable ? (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
              <CircularProgress />
            </Grid>
          ) : itensPaginados.length === 0 ? (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
              <Typography variant="subtitle1" color="textSecondary">
                Não há itens na nota.
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid container spacing={1} alignItems="stretch" style={{ marginTop: 15 }}>
                {itensPaginados.map((item, index) => (
                  <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                    <Paper
                      style={{
                        padding: '16px',
                        flex: '1 0 auto',
                        boxSizing: 'border-box',
                        ...(aplicarEstilos ? {
                          display: 'flex',
                          flexDirection: 'column',
                          minHeight: '200px',
                          flexWrap: 'wrap',
                        } : {
                          minHeight: '140px'
                        })
                      }}
                    >
                      <Grid container alignItems="center" spacing={2} style={{ display: 'flex', alignItems: 'center', marginBottom: usuarioEstoqueAtivo ? 10 : 0 }}>
                        {nf.Descricao === 'Recebido' && usuarioEstoqueAtivo && (tecnicoPrestador === false || tecnicoPrestador === null) && (
                          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Checkbox
                              onClick={() => selecionarItem(item)}
                              className="checkbox"
                              checked={dadosNF.some(row => row.id === item.id)}
                              color="primary"
                            />
                          </Grid>
                        )}
                        <Grid item xs={nf.Descricao === 'Recebido' && usuarioEstoqueAtivo && (tecnicoPrestador === false || tecnicoPrestador === null) ? 9 : 12}>
                          <Typography component="div" variant="body2">{item.descProduto}</Typography>
                          <Typography component="div" variant="body2">{item.codProduto}</Typography>
                          <Typography component="div" variant="body2"><strong>Quantidade:</strong> {item.quantidade}</Typography>
                        </Grid>
                      </Grid>

                      {nf.Descricao === 'Recebido' && usuarioEstoqueAtivo && (tecnicoPrestador === false || tecnicoPrestador === null) && item.detalhes.length > 0 && (
                        <Accordion key={item.id} expanded={expandirPainel === item.id} onChange={expandirSeletor(item.id)}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Selecione as séries para contestar.</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={1}>
                              {item.detalhes.map((x) => (
                                <Grid item key={x.id} xs={12}>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Checkbox
                                      onChange={() => alterarValorItemSerie(x)}
                                      checked={selectItensSeries.includes(x.id)}
                                    />
                                    <span>{x.serie}</span>
                                  </div>
                                </Grid>
                              ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )}

                      {nf.Descricao === 'Recebido' && usuarioEstoqueAtivo && (tecnicoPrestador === false || tecnicoPrestador === null) && item.detalhes.length === 0 && (
                        <Accordion key={item.id} expanded={expandirPainel === item.id} onChange={expandirSeletor(item.id)}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Selecione a quantidade para contestar.</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container justifyContent="center">
                              <Select
                                fullWidth
                                value={itensQuantidadeSelect[item.id] || ''}
                                onChange={(e) => alterarValorItensQuantidadeSemSerie(item.id, e.target.value)}
                                displayEmpty
                              >
                                <MenuItem value="" disabled>Selecione</MenuItem>
                                {[...Array(item.quantidade).keys()].map((num) => (
                                  <MenuItem key={num + 1} value={num + 1}>{num + 1}</MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Paper>
                  </Grid>
                ))}
              </Grid>

              <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 15 }}>
                <Grid item>
                  <CustomTablePagination
                    count={totalDados}
                    page={page}
                    rowsPerPage={pageSize}
                    quantidadeItens={dadosFiltrados.length}
                    onPageChange={mudarPagina}
                    onRowsPerPageChange={mudarTamanhoPagina}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </div>
    </>
  );
}
