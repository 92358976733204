import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class NotificacaoService {

  async listarNotificacoesVisaoGestor(remetenteNotificacaoId, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/listarVisaoGestor?RemetenteNotificacaoId=${remetenteNotificacaoId}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.pesquisa || ''}`, {
        method: 'GET',
        headers: _headerToken,
      });
      
      const data = await response.json();
      let errorsMessage = null;
      
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar as notificações.',
      };
    }
  }

  async listarNotificacoesVisaoGestorPaginado(gestorId, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/listarPorGestorData?GestorId=${gestorId}&Pagina=${dados.pagina}&Limite=${dados.limite}&DataInicial=${dados.dataInicial}&DataFinal=${dados.dataFinal}`, {
        method: 'GET',
        headers: _headerToken,
      });
      
      const data = await response.json();
      let errorsMessage = null;
      
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar as notificações.',
      };
    }
  }

  async enviarNotificacao(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao`, {
        method: 'POST',
        headers: _headerToken,
        body: JSON.stringify(dados),
      });
      
      const data = await response.json();
      let errorsMessage = null;
      
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200)
        return {
          error: false,
          result: data,
        };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao enviar a notificação.',
      };
    }
  }
  async deletarNotificacao(id) {
    const _headerToken = authService.headerAPITecnico();
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/deletarPorLote/${id}`, {
        method: 'PATCH',
        headers: _headerToken,
      });
  
      const data = await response.json();
      if (response.status === 200) {
        return { error: false, result: data };
      } else {
        return { error: true, message: data.erro || 'Erro ao deletar notificação.' };
      }
    } catch (error) {
      return { error: true, message: 'Erro ao deletar notificação.' };
    }
  }
  
  
  async listarTecnicosLote(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/listarPorLote?Lote=${dados.lote}&Pagina=${dados.pagina}&Limite=${dados.limite}`, {
        method: 'GET',
        headers: _headerToken,
      });
      
      const data = await response.json();
      let errorsMessage = null;
      
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200)
        return {
          error: false,
          result: data,
        };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados.',
      };
    }
  }
  async atualizarNotificacao(id, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/${id}`, {
        method: 'PUT',
        headers: {
          ..._headerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dados),
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
        return { error: false, result: data };
      } else {
        return { error: true, message: data.erro || 'Erro ao atualizar a notificação.' };
      }
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao atualizar a notificação.',
      };
    }
  }
  
  
  async buscarNotificacaoDetalhada(loteId) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/${loteId}`, {
        method: 'GET',
        headers: _headerToken,
      });
      
      const data = await response.json();
      let errorsMessage = null;
      
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return { error: false, result: data };
      }

      return { error: true, message: data.erro || errorsMessage };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os detalhes da notificação.',
      };
    }
  }

}

export default new NotificacaoService();
