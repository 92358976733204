import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function HomePage()
{
  useEffect(()=>{
  },[]);

  return(
    <div>
      <MenuComponent/>
      <Container component='main'>

      </Container>
    </div>
  );


}