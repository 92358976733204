import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class DocumentosService {
  async listadocumentos() {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/Documento/1/1/tec`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });
      if (response.status === 200)
        return await response.json();

    } catch (error) {
      console.log(error);
    }
  }

  async arquivodocumentos(id) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/Documento/${id}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });
      if (response.status === 200) {
        return response.blob();
      } else {
        return {
          error: true,
        };
      }
    } catch (error) {
      console.error('Erro ao buscar o documento:', error);
      return {
        error: true,
      };
    }
  }
}

export default new DocumentosService();
