import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const BlueButton = withStyles(theme => ({
  root: {
    backgroundColor: '#209BDE',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#209BDE',
      color: '#FFF'
    }
  }
}))(props => <Button color="default" {...props} />);

export default BlueButton;
