import authService from '../auth/auth-service';
import config from '../../config';

const configAPIGateway = config.configAPIGateway();

class OportunidadeService {

    async oportunidadesVendedor(vendedorId)
    {
        try{

            let token = await authService.tokenGateway();

            let response = await fetch(`${configAPIGateway.URI_GATEWAY}/oportunidades/${vendedorId}`,{
                method: 'GET',    
                 headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token.access_token}`
                },
            });

            if(response.status === 200)
                return await response.json();

        }catch (error) {
            console.log(error);
        } 
    }

    async oportunidadesRevenda(areaAtendimentoID)
    {
        try{

            let token = await authService.tokenGateway();

            let response = await fetch(`${configAPIGateway.URI_GATEWAY}/oportunidade/revenda/${areaAtendimentoID}`,{
                method: 'GET',    
                 headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token.access_token}`
                },
            });

            if(response.status === 200)
                return await response.json();

        }catch (error) {
            console.log(error);
        } 
    }

    async addOportunidade(oportunidade)
    {
        try{

            let token = await authService.tokenGateway();

            let response = await fetch(`${configAPIGateway.URI_GATEWAY}/addoportunidade`,{
                method: 'POST',    
                 headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token.access_token}`
                },
                body: JSON.stringify(oportunidade)
            });

            if(response.status === 200)
                return await response.json();

        }catch (error) {
            console.log(error);
        } 
    }
}
export default new OportunidadeService();
