import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class SensorService {

  async configuravel() {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/sensor/configuravel`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`
        },
      });

      if (response.status === 200)
        return await response.json();

    } catch (error) {
      console.log(error);
    }
  }

}
export default new SensorService();