class ReceitaService {

  async pesquisaCNPJ() {
       
    try {

      let url = 'https://viacep.com.br/ws/01001000/json/';
      console.log(url);


      let response = await fetch(url,{
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        },
        
      });

      console.log(response);

      return response;
           
    } catch (error) {
      console.log(error);
    } 
    
  }
}
export default new ReceitaService();
