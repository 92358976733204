import axios from 'axios';

import config from '../../config';

const api = axios.create({
  baseURL: config.configAPITecnico().URI_API || 'http://localhost:5001',
  timeout: 30000,
  timeoutErrorMessage: 'Problema de conexão',
});

export default api;
