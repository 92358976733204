import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class ContraSenhaService {
  async contraSenha(opcao, ocorrencia, na){
    try{
     
      let response = await fetch(`${_configAPITecnico.URI_API}/contrasenha/gerar/${opcao}/${ocorrencia}/${na}`,{
        method: 'GET',    
        headers: authService.headerAPITecnico()
      });

      if(response.status === 200)
        return await response.json();

    }catch (error) {
      console.log(error);
    } 
  }

}
export default new ContraSenhaService();
