import config from '../../config';

const _configAPITecnico = config.configAPITecnico();
class VistoriaService {

  async cadastrarTecnico(tecnico) {
    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/tecnico`, {
        method: 'Post',
        body: tecnico,
      });

      const data = await response.json();
      let errorsMessage = null;

      if (data.errors) {
        const errorsArray = Object.values(data.errors).flat();
        errorsMessage = errorsArray.join(', ');
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao cadastrar o técnico.',
      };
    }
  }

  async cadastrarVeiculoTecnico(veiculo) {
    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/veiculo`, {
        method: 'Post',
        body: veiculo,
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return response;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao cadastrar o veículo.',
      };
    }
  }

  async consultaCNPJ(cnpj) {
    try {
      const cnpjLimpo = cnpj.replace(/[^\d]+/g, '');
      const response = await fetch(`${_configAPITecnico.URI_API}/endereco/${cnpjLimpo}`);
      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  }

  async getMarcasVeiculos() {
    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/veiculo/marcas`);
      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  }

  async getModelosVeiculos(marca) {
    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/veiculo/modelos/${marca}`);
      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  }

}

export default new VistoriaService();
