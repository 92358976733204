import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class AtivacaoService {

  async status(serie) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ativacaointegrada/status/${serie}`, {
        method: 'GET',
        headers: _headerToken
      });

      if (response.status === 200)
        return await response.json();

    } catch (error) {
      console.log(error);
    }
  }

  async ultimoInventario(serie) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ativacaointegrada/ultimoinventario/${serie}`, {
        method: 'GET',
        headers: _headerToken
      });

      if (response.status === 200)
        return await response.json();

    } catch (error) {
      console.log(error);
    }
  }

  async addFormAtivacao(formAtivacao) {
    try {

      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/ativacaointegrada/formativacao`, {
        method: 'POST',
        headers: _headerToken,
        body: formAtivacao
      });

      if (response.status === 200)
        return await response.json();

    } catch (error) {
      console.log(error);
    }
  }
}
export default new AtivacaoService();
