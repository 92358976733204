import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { CircularProgress, Typography, TextField, InputAdornment, IconButton, debounce } from '@material-ui/core';
import MenuComponent from '../../components/menu/menu-component';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../../components/alert/CustomAlert';
import remanufaturaService from '../../Services/remanufatura/remanufatura-service';
import CustomTablePagination from '../../components/pagination/pagination-component';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() => ({
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        marginBottom: '5px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        marginTop: '0px',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 20,
    },
    searchInput: {
        width: '500px',
    },

    paperProduto: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        boxSizing: 'border-box',
    },
}));

export default function EquipamentosRemanufaturaPage() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(8);
    const [totalDados, setTotalDados] = useState(0);
    const [dadosRemanufatura, setDadosRemanufatura] = useState([]);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [pesquisa, setPesquisa] = useState('');
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');

    const buscarEquipamentos = async (paginaAtual, limite, pesquisa) => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: paginaAtual || 0,
                limite: limite || 10,
                pesquisa: pesquisa || '',
            };
            const response = await remanufaturaService.listarProdutosRemanufatura(dados);

            if (response.dados) {
                setDadosRemanufatura(response.dados);
                setTotalDados(response.total);
            } else {
                setDadosRemanufatura([]);
                setTotalDados(0);
            }
        } catch (error) {
            setMensagemAlert({ title: 'Erro!', body: 'Erro ao buscar dados dos Itens de remanufatura.' });
            setTipoAlert('error');
            setAbrirAlert(true);
        }
        setCarregandoTable(false);
    };

    const delayPesquisa = useCallback(
        debounce((nome) => {
            buscarEquipamentos(0, pageSize, nome);
        }, 700), []
    );

    const valorPesquisa = (nome) => {
        setPesquisa(nome);
        delayPesquisa(nome);
    };

    const limparPesquisa = () => {
        setPesquisa('');
        buscarEquipamentos(0, pageSize, '');
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarEquipamentos(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
        buscarEquipamentos(0, parseInt(event.target.value, 10), pesquisa);
    };
    const capitalizarPalavras = (str) => {
        return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
    
    useEffect(() => {
        buscarEquipamentos(page, pageSize, pesquisa);
    }, []);

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            <div style={{ margin: 30 }}>
                <h3 className={classes.tituloStyle}>Itens de Remanufatura</h3>
                <h4 className={classes.subtituloStyle}>
                    <span onClick={() => window.location.assign('/home')} style={{ cursor: 'pointer' }}>
                        Home
                    </span> {'>'} Itens de Remanufatura
                </h4>

                <div className={classes.searchContainer}>
                    <TextField
                        id="campo-pesquisa"
                        className={classes.searchInput}
                        variant="outlined"
                        label="Pesquisar"
                        value={pesquisa}
                        onChange={(event) => valorPesquisa(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={pesquisa ? limparPesquisa : null}>
                                        {pesquisa ? <ClearIcon /> : <SearchIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <Paper className={classes.paper}>
                    {carregandoTable ? (
                        <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                            <CircularProgress />
                        </Grid>
                    ) : dadosRemanufatura.length === 0 ? (
                        <Grid container justifyContent="center" alignItems="center">
                            <Typography variant="body1" align="center" style={{ padding: '16px', color: '#555' }}>
                                Não há dados.
                            </Typography>
                        </Grid>
                    ) : (
                        <>
                            <Grid container spacing={1}>
                                {dadosRemanufatura.map((item) => (
                                    <Grid item key={item.id} xs={12} md={3}>
                                        <Paper className={classes.paperProduto}>
                                            <Typography variant="body2"><strong>Nome do Produto:</strong> {capitalizarPalavras(item.produtoNome.substring(0, 30))}{item.produtoNome.length > 50 ? '...' : ''}</Typography>
                                            <Typography variant="body2"><strong>Código do Produto:</strong> {item.produtoCodigo}</Typography>
                                            <Typography variant="body2"><strong>Data de Cadastro:</strong> {moment(item.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                      <Grid item>
                                <CustomTablePagination
                                    onPageChange={mudarPagina}
                                    page={page}
                                    count={totalDados}
                                    rowsPerPage={pageSize}
                                    quantidadeItens={totalDados}
                                    onRowsPerPageChange={mudarTamanhoPagina}
                                />
                            </Grid>
                            </Grid>
                        </>
                    )}
                </Paper>
            </div>
        </>
    );
}
