class EnderecoService {

  async pesquisaCEP(cep) {
       
    try {

      let response = await fetch(`https://viacep.com.br/ws/${cep}/json/`,{
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        },
        
      });

      if(response.status === 200)
        return await response.json();

      return null;
           
    } catch (error) {
      console.log(error);
    } 
    
  }
}
export default new EnderecoService();
