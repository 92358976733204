import React from 'react';
import { Grid, FormGroup, FormControl, Select } from '@material-ui/core';

export function AvlLatariaVistoria({ vistoria, handleInputChange, classes, disabledEdit }) {
  return vistoria?.tipo_veiculo === 'PS' ||
    vistoria?.tipo_veiculo === 'VN' ||
    vistoria?.tipo_veiculo === 'CR' ? (
      <>
        <span className={classes.subtitle}>Avaliação lataria e partes ext.</span>
        <span style={{ marginLeft: 10 }}>
          <strong>A:</strong> Amassado, <strong>R:</strong> Riscado,{' '}
          <strong>Q:</strong> Quebrado, <strong>F:</strong> Faltante
        </span>

        {vistoria?.tipo_veiculo === 'PS' && (
          <Grid container spacing={3} style={{ marginTop: '30px' }}>
            <Grid item xs={12} sm={6}>
              <img
                style={{ marginTop: '10px' }}
                src="../Carro/Prancheta 7@5x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '30px', marginTop: '-50px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_roda_traseira_direita"
                    value={vistoria?.avl_roda_traseira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '15px', marginTop: '-15px' }}>
                  <Select
                    native
                    id="avl_porta_dianteira_direita"
                    value={vistoria?.avl_porta_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_porta_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '15px' }}>
                  <Select
                    native
                    id="avl_roda_dianteira_direita"
                    value={vistoria?.avl_roda_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                style={{ marginTop: '20px' }}
                src="../Carro/Prancheta 6@5x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '35px', marginTop: '-55px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_roda_dianteira_esquerda"
                    value={vistoria?.avl_roda_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '10px', marginTop: '-15px' }}>
                  <Select
                    native
                    id="avl_porta_dianteira_esquerda"
                    value={vistoria?.avl_porta_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_porta_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: 20 }}>
                  <Select
                    native
                    id="avl_roda_traseira_esquerda"
                    value={vistoria?.avl_roda_traseira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>

            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6} md={4}>
              <img
                style={{ marginLeft: '95px', marginTop: '-18px' }}
                src="../Carro/Prancheta 3@5x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '60px', marginTop: '-84px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_dianteira_direita"
                    value={vistoria?.avl_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>

                <FormControl disabled={disabledEdit} style={{ marginLeft: '60px' }}>
                  <Select
                    native
                    id="avl_dianteira_esquerda"
                    value={vistoria?.avl_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6} md={4}>
              <img
                style={{ marginLeft: '70px', marginTop: '-18px' }}
                src="../Carro/Prancheta 4@5x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '60px', marginTop: '-84px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_traseira_esquerda"
                    value={vistoria?.avl_traseira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_traseira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>

                <FormControl disabled={disabledEdit} style={{ marginLeft: '50px' }}>
                  <Select
                    native
                    id="avl_traseira_direita"
                    value={vistoria?.avl_traseira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_traseira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6} md={4}>
              <img
                style={{ marginLeft: '100px', marginTop: '-18px' }}
                src="../Carro/Prancheta 5@5x.png"
                height={120}
              />

              <FormControl disabled={disabledEdit} style={{ marginLeft: '-60px', marginTop: '40px' }}>
                <Select
                  native
                  id="avl_teto"
                  value={vistoria?.avl_teto || ''}
                  onChange={(event) =>
                    handleInputChange('avl_teto', event.target.value)
                  }
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    padding: 2,
                  }}
                >
                  <optgroup>
                    <option value="" />
                    <option value={'A'}>A</option>
                    <option value={'R'}>R</option>
                    <option value={'Q'}>Q</option>
                    <option value={'F'}>F</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {vistoria?.tipo_veiculo === 'VN' && (
          <Grid container spacing={3} style={{ marginTop: '30px' }}>
            <Grid item xs={12} sm={6}>
              <img
                style={{ marginTop: '10px' }}
                src="../Van/Prancheta 7@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '30px', marginTop: '-50px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_roda_traseira_direita"
                    value={vistoria?.avl_roda_traseira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '15px', marginTop: '-15px' }}>
                  <Select
                    native
                    id="avl_porta_dianteira_direita"
                    value={vistoria?.avl_porta_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_porta_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '15px' }}>
                  <Select
                    native
                    id="avl_roda_dianteira_direita"
                    value={vistoria?.avl_roda_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                style={{ marginTop: '20px' }}
                src="../Van/Prancheta 5@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '25px', marginTop: '-45px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_roda_dianteira_esquerda"
                    value={vistoria?.avl_roda_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '10px', marginTop: '-15px' }}>
                  <Select
                    native
                    id="avl_porta_dianteira_esquerda"
                    value={vistoria?.avl_porta_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_porta_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: 20 }}>
                  <Select
                    native
                    id="avl_roda_traseira_esquerda"
                    value={vistoria?.avl_roda_traseira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>

            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6} md={4}>
              <img
                style={{ marginLeft: '95px', marginTop: '-18px' }}
                src="../Van/Prancheta 4@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '70px', marginTop: '-75px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_dianteira_direita"
                    value={vistoria?.avl_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>

                <FormControl disabled={disabledEdit} style={{ marginLeft: '60px' }}>
                  <Select
                    native
                    id="avl_dianteira_esquerda"
                    value={vistoria?.avl_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6} md={4}>
              <img
                style={{ marginLeft: '70px', marginTop: '-18px' }}
                src="../Van/Prancheta 6@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '55px', marginTop: '-80px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_traseira_esquerda"
                    value={vistoria?.avl_traseira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_traseira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>

                <FormControl disabled={disabledEdit} style={{ marginLeft: '50px' }}>
                  <Select
                    native
                    id="avl_traseira_direita"
                    value={vistoria?.avl_traseira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_traseira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6} md={4}>
              <img
                style={{ marginLeft: '100px', marginTop: '-18px' }}
                src="../Van/Prancheta 3@4x.png"
                height={120}
              />

              <FormControl disabled={disabledEdit} style={{ marginLeft: '-60px', marginTop: '40px' }}>
                <Select
                  native
                  id="avl_teto"
                  value={vistoria?.avl_teto || ''}
                  onChange={(event) =>
                    handleInputChange('avl_teto', event.target.value)
                  }
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    padding: 2,
                  }}
                >
                  <optgroup>
                    <option value="" />
                    <option value={'A'}>A</option>
                    <option value={'R'}>R</option>
                    <option value={'Q'}>Q</option>
                    <option value={'F'}>F</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {vistoria?.tipo_veiculo === 'CR' && (
          <Grid
            container
            spacing={3}
            style={{ marginTop: '30px', marginBottom: '30px' }}
          >
            <Grid item xs={12} sm={6}>
              <img
                style={{ marginTop: '10px' }}
                src="../Caminhao/Prancheta 5@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '10px', marginTop: '-50px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_roda_traseira_bau_direita"
                    value={vistoria?.avl_roda_traseira_bau_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_bau_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '4px' }}>
                  <Select
                    native
                    id="avl_roda_traseira_direita"
                    value={vistoria?.avl_roda_traseira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '16px', marginTop: '-20px' }}>
                  <Select
                    native
                    id="avl_porta_dianteira_direita"
                    value={vistoria?.avl_porta_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_porta_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '2px' }}>
                  <Select
                    native
                    id="avl_roda_dianteira_direita"
                    value={vistoria?.avl_roda_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                style={{ marginTop: '20px' }}
                src="../Caminhao/Prancheta 4@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '20px', marginTop: '-45px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_roda_dianteira_esquerda"
                    value={vistoria?.avl_roda_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '2px', marginTop: '-20px' }}>
                  <Select
                    native
                    id="avl_porta_dianteira_esquerda"
                    value={vistoria?.avl_porta_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_porta_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '8px' }}>
                  <Select
                    native
                    id="avl_roda_traseira_esquerda"
                    value={vistoria?.avl_roda_traseira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '2px' }}>
                  <Select
                    native
                    id="avl_roda_traseira_bau_esquerda"
                    value={vistoria?.avl_roda_traseira_bau_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_roda_traseira_bau_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>

            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6}>
              <img
                style={{ marginLeft: '95px', marginTop: '-18px' }}
                src="../Caminhao/Prancheta 6@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '70px', marginTop: '-60px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_dianteira_direita"
                    value={vistoria?.avl_dianteira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_dianteira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>

                <FormControl disabled={disabledEdit} style={{ marginLeft: '20px' }}>
                  <Select
                    native
                    id="avl_dianteira_esquerda"
                    value={vistoria?.avl_dianteira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_dianteira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6}>
              <img
                style={{ marginLeft: '70px', marginTop: '-18px' }}
                src="../Caminhao/Prancheta 7@4x.png"
                height={120}
              />

              <FormGroup style={{ marginLeft: '55px', marginTop: '-75px' }} row>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_traseira_esquerda"
                    value={vistoria?.avl_traseira_esquerda || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_traseira_esquerda',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>

                <FormControl disabled={disabledEdit} style={{ marginLeft: '12px' }}>
                  <Select
                    native
                    id="avl_traseira_direita"
                    value={vistoria?.avl_traseira_direita || ''}
                    onChange={(event) =>
                      handleInputChange(
                        'avl_traseira_direita',
                        event.target.value
                      )
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid style={{ marginTop: '45px' }} item xs={12} sm={6}>
              <img
                style={{ marginTop: '-18px' }}
                src="../Caminhao/Prancheta 3@4x.png"
                height={120}
              />

              <FormGroup style={{ marginTop: '-85px' }} row>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '60px' }}>
                  <Select
                    native
                    id="avl_bau_teto"
                    value={vistoria?.avl_bau_teto || ''}
                    onChange={(event) =>
                      handleInputChange('avl_bau_teto', event.target.value)
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit} style={{ marginLeft: '90px', marginRight: '2px' }}>
                  <Select
                    native
                    id="avl_teto"
                    value={vistoria?.avl_teto || ''}
                    onChange={(event) =>
                      handleInputChange('avl_teto', event.target.value)
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl disabled={disabledEdit}>
                  <Select
                    native
                    id="avl_capo"
                    value={vistoria?.avl_capo || ''}
                    onChange={(event) =>
                      handleInputChange('avl_capo', event.target.value)
                    }
                    style={{
                      backgroundColor: '#fafafa',
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <optgroup>
                      <option value="" />
                      <option value={'A'}>A</option>
                      <option value={'R'}>R</option>
                      <option value={'Q'}>Q</option>
                      <option value={'F'}>F</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </>
    ) : (
      <></>
    );
}
