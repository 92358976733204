import React, { useEffect, useState } from 'react';
import TabelaTecnicos from './tabela-tecnicos';
import { Paper, ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core';
import 'moment/locale/pt-br';
import '../agenda/components/react-big-calendar.css';
import MenuComponent from '../components/menu/menu-component';
import { makeStyles } from '@material-ui/core/styles';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InventoryIcon from '@mui/icons-material/Inventory';
import FileCopy from '@material-ui/icons/FileCopy';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AirplayIcon from '@mui/icons-material/Airplay';
import TodayIcon from '@mui/icons-material/Today';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShopIcon from '@mui/icons-material/Shop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LineWeight from '@mui/icons-material/LineWeight';
import ContactsIcon from '@mui/icons-material/Contacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import usuarioService from '../Services/Usuario/usuario-service';
import authService from '../Services/auth/auth-service';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import HandymanIcon from '@mui/icons-material/Handyman';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  acessoRapidoStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#1C69AD',
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'start',
    padding: '10px 5px',
    marginTop: '0px',
    marginBottom: '8px',
  },
  cardAcesso: {
    flexGrow: 0,
    flexBasis: 'calc(20% - 10px)', 
    margin: '5px',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#D4EDFE',
    textAlign: 'start',
    alignItems: 'center',
    justifyContent: 'start',
    display: 'flex',
    minHeight: '60px',
    borderRadius: '10px',
    padding: '5px',
    color: '#1C69AD',
    fontWeight: 'bold',
  },
  TextoCardAcesso: {
    margin: '0',
    padding: '5px',
    color: '#1C69AD',
    fontWeight: 'bold',
    whiteSpace: 'normal'
  },
  IconeCardAcesso: {
    marginLeft: '10px',
    color: '#1C69AD',
    fontSize: '37px'
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  [theme.breakpoints.down('md')]: {
    cardAcesso: {
      flexBasis: 'calc(50% - 10px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    cardAcesso: {
      flexBasis: 'calc(100% - 10px)',
    },
  },
}));

var menuItems = [
  {
    text: 'Clientes',
    icon: <ContactsIcon />,
    url: '/clientes',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Financeiro',
    icon: <AttachMoneyIcon />,
    url: '/financeiro',
    allowedRoles: ['tecnico'],
  },
  {
    text: 'Oportunidade',
    icon: <ShopIcon />,
    url: '/oportunidades',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Venda',
    icon: <ShoppingCartIcon />,
    url: '/venda',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Gestor',
    icon: <TodayIcon />,
    url: '/gestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Auditoria',
    icon: <ReceiptLongIcon />,
    url: '/auditoria',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Inventário',
    icon: <InventoryIcon />,
    url: '/inventario',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Ordens de Serviço',
    icon: <ListAltIcon />,
    url: '/servicos',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Comunicação',
    icon: <AirplayIcon />,
    url: '/comunicacao',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Notas Fiscais',
    icon: <DescriptionIcon />,
    url: '/notasfiscais',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Meu Estoque',
    icon: <InventoryIcon />,
    url: '/estoque',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Solicitação de Reversa',
    icon: <SettingsBackupRestoreIcon />,
    url: '/solicitacaoreversa',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Usuários',
    icon: <GroupIcon />,
    url: '/gestorTecnico',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Técnicos',
    icon: <EngineeringIcon />,
    url: '/perfiltecnico',
    allowedRoles: ['tecnico', 'revenda'],
  },
  {
    text: 'Notícias',
    icon: <LineWeight />,
    url: '/noticia',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Manuais',
    icon: <FileCopy />,
    url: '/manuais',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Minha Conta',
    icon: <AccountBoxIcon />,
    url: '/perfil',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Liberação do Estoque',
    icon: <LockOpenIcon />,
    url: '/liberacaoEstoqueGestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Central de Notificações',
    icon: <MarkChatUnreadIcon />,
    url: '/notificacoesgestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Itens de Remanufatura',
    icon: <HandymanIcon />,
    url: '/remanufaturagestor',
    allowedRoles: ['gestor'],
  },
];

menuItems.sort((a, b) => a.text.localeCompare(b.text));

export default function GestorPage(props) {
  const classes = useStyles();
  const [usuarioInfo, setUsuarioInfo] = useState(null);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);

  const filteredMenuItems = menuItems
    .filter((item) => {
      const isGestor = usuarioInfo && usuarioInfo.gestor === true;
      const isRevenda = usuarioInfo && usuarioInfo.revenda === true;
      const isTecnico = !isGestor && !isRevenda;

      if (item.allowedRoles.includes('gestor') && isGestor) {
        return true;
      } else if (item.allowedRoles.includes('revenda') && isRevenda) {
        return true;
      } else if (item.allowedRoles.includes('tecnico') && isTecnico) {
        return true;
      }

      return false;
    })
    .filter(item => !(!usuarioEstoqueAtivo && item.text === 'Estoque'))
    .sort((a, b) => a.text.length - b.text.length);

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  useEffect(() => {
    buscarUsuarioEstoqueAtivo();
    setUsuarioInfo(JSON.parse(localStorage.getItem('userInfo')).usuario);
  }, []);

  return (
    <>
      <MenuComponent />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Bem-vindo!</h3>
        <h4 className={classes.subtituloStyle}>Tela inicial do Portal do Técnico</h4>
        <Paper className={classes.paper}>
          <h4 className={classes.acessoRapidoStyle}>Acesso Rápido</h4>
          <Box className={classes.flexContainer}>
            {filteredMenuItems.map((item, index) => (
              <React.Fragment key={item.url}>
                <ListItem
                  button
                  onClick={() => {
                    window.location.href = item.url;
                  }}
                  className={classes.cardAcesso}
                  style={{ marginBottom: '5px' }}
                >
                  <ListItemIcon className={classes.IconeCardAcesso}>{item.icon}</ListItemIcon>
                  <ListItemText className={classes.TextoCardAcesso} primary={item.text} />
                </ListItem>
              </React.Fragment>
            ))}
          </Box>
        </Paper>
        <Paper className={classes.paper}>
          <h4 className={classes.acessoRapidoStyle}>Gestor</h4>
          <TabelaTecnicos />
        </Paper>
        {/* <Paper className={classes.paper}>
          <h4 className={classes.acessoRapidoStyle}>Agenda</h4>
          <EventCalendar />
        </Paper> */}
      </div>
    </>
  );
}
