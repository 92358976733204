import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class LiberacaoEstoqueGestorService {

  async tecnicosListagemEstoqueLiberado(localidGestor, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/usuarioestoqueliberado/tecnicosListagemPorGestor?LocalId=${localidGestor}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}`,
        {
          method: 'GET',
          headers: _headerToken,
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async criarRegistroEstoqueLiberado(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/usuarioestoqueliberado/criarTecnicosPorGestor`,
        {
          method: 'POST',
          headers: _headerToken,
          body: dados
        },
      );
      let data;
      try {
        data = await response.json();
      } catch (jsonError) {
        if (response.status === 200) {
          return {
            error: false,
            result: null,
          };
        } else {
          return {
            error: true,
            message: 'JSON inválido.',
          };
        }
      }

      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          result: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao alterar o status.',
      };
    }
  }

  async editarStatusTecnicoLiberacaoEstoque(tecnicoID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/usuarioestoqueliberado/liberarEstoque/${tecnicoID}`,
        {
          method: 'PATCH',
          headers: _headerToken
        },
      );
      let data;
      try {
        data = await response.json();
      } catch (jsonError) {
        if (response.status === 200) {
          return {
            error: false,
            result: null,
          };
        } else {
          return {
            error: true,
            message: 'JSON inválido.',
          };
        }
      }

      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          result: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao alterar o status.',
      };
    }
  }
}

export default new LiberacaoEstoqueGestorService();
