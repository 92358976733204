import authService from '../auth/auth-service';
import config from '../../config';

const configAPIGateway = config.configAPIGateway();

class ProdutoService {
	async produtos() {
		try {
			let token = await authService.tokenGateway();

			let response = await fetch(`${configAPIGateway.URI_GATEWAY}/produtoplataformavendaget?plataforma=r`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.access_token}`,
				},
			});

			if (response.status === 200) return await response.json();
		} catch (error) {
			console.log(error);
		}
	}
}
export default new ProdutoService();
