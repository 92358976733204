import React, { useEffect, useState } from 'react';
import '../agenda/components/react-big-calendar.css';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BlueButton from '../components/button/BlueButton';
import CancelIcon from '@material-ui/icons/Cancel';
import estoqueService from '../Services/Estoque/estoque-service';
import authService from '../Services/auth/auth-service';

import { MaterialTableCustom } from '../components/table/material-table-custom';

const styles = (theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function convertDate(date) {
  try {
    const dateF = `${date?.split('/')[2]}-${date?.split('/')[1]}-${date?.split('/')[0]}`;
    return new Date(dateF);
  } catch (error) {
    return date;
  }
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


export default function DetalheEstoquePage({ nf, handleClose }) {

  const [detalhes, setDetalhe] = useState([]);
  const [estoqueItens, setEstoque] = useState([]);
  useEffect(() => {
    estoque();
  }, []);

  //CONSULTA ESTOQUE
  const estoque = async () => {
    let area =
      authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    let estoqueLista = await estoqueService.exibirEstoque(area);

    if (estoqueLista) {
      let _estoqueItens = [];

      estoqueLista.map((m) => {
        _estoqueItens.push({
          id: m.id,
          area: m.area || '',
          produto: m.produto || '',
          codProduto: m.codProduto || '',
          qtdeReal: m.qtdeReal,
          quantidade: m.quantidade || 0,
          qtdeReservado: m.qtdeReservado || 0,
          qtdeDevolver: m.qtdeDevolver || 0,
          data: m.data ? moment(m.data).format('DD/MM/YYYY') : '',
          login: m.login || '',
          minimo: m.minimo,
          maximo: m.maximo,
        });
      });
      setEstoque(_estoqueItens);
    }
    console.log(estoqueLista)
  };
  return (
    <Dialog
      onClose={handleClose}
      open={true}
      fullScreen
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" onClose={handleClose} >
        Detalhe Estoque Técnico
      </DialogTitle>
      <DialogContent dividers>
        <MaterialTableCustom
          title="Poder terceiro"
          columns={[
            {
              title: 'Produto',
              field: 'produto',
              width: 350,
              render: (data) => (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{data.produto}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Typography><span style={{ fontWeight: 'bold' }}>Quantidade:</span> {data.quantidade}</Typography>
                      <Typography><span style={{ fontWeight: 'bold' }}>Quantidade Reversa:</span> {data.qtdeDevolver}</Typography>
                      <Typography><span style={{ fontWeight: 'bold' }}>Data Entrada Estoque:</span> {data.data}</Typography>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ),
            },
          ]}
          data={estoqueItens}
          localization={{
            body: {
              emptyDataSourceMessage: 'Nenhum registro para exibir',
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página',
            },

          }}
          options={{
            pageSize: 10,
          }}
        />
      </DialogContent>
      <DialogActions>
        <BlueButton onClick={handleClose} variant="contained" color="primary">
          <CancelIcon />
          Fechar
        </BlueButton>
      </DialogActions>
    </Dialog>
  );

}
