import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Modal, Box, CircularProgress } from '@material-ui/core';
import BlueButton from '../../components/button/BlueButton';
import BlueTextField from '../../components/textfield/BlueTextField';
import authService from '../../Services/auth/auth-service';
import CryptoJS from 'crypto-js';
import FacebookCircularProgress from '../../components/progress/CircularProgressBlue';
import logoOmnilink from '../../../src/assets/img/logoOmni.png';
import CustomAlert from '../../components/alert/CustomAlert';
import ReactDOM from 'react-dom';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3000, 
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    maxWidth: '400px',
    width: '100%',
  },
  logo: {
    display: 'block',
    margin: '0 auto 20px',
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  alertWrapper: {
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  alert: {
    marginBottom: theme.spacing(2),
  }
}));

export default function LoginModal({ open, onLoginSuccess, firstLoginUser }) {
  const classes = useStyles();
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [userMismatch, setUserMismatch] = useState(false); 
  const [alertOpen, setAlertOpen] = useState(false);  
  const [alertType, setAlertType] = useState('info');
  const [alertMessage, setAlertMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginForm({ ...loginForm, [name]: value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (firstLoginUser && loginForm.email !== firstLoginUser) {
      setAlertType('error');
      setAlertMessage(`O login atual não corresponde ao usuário logado anteriormente  ${firstLoginUser}`);
      setAlertOpen(true);
      setUserMismatch(true); 
      setLoading(false);
      return;
    }

    const response = await authService.login(JSON.stringify({
      usuario: loginForm.email,
      senha: CryptoJS.MD5(loginForm.password).toString(),
    }));

    if (response) {
      localStorage.setItem('userInfo', JSON.stringify(response));
      localStorage.setItem('loginTime', Date.now());
      setLoading(false);
      onLoginSuccess(response.usuario);
    } else {
      setAlertType('error');
      setAlertMessage('Usuário ou senha incorretos. Tente novamente.');
      setAlertOpen(true);
      setLoading(false);
    }
  };
  
  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('loginTime');
    window.location.href = '/';
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div className={classes.alertWrapper}>
          <CustomAlert
            open={alertOpen}
            message={{ title: alertType === 'error' ? 'Erro' : 'Informação', body: alertMessage }}
            type={alertType}
            onClose={() => setAlertOpen(false)}
          />
        </div>,
        document.body 
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        open={open}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
        className={classes.modal}
        disableEnforceFocus
        disableAutoFocus
      >
        <Box className={classes.paper}>
          <img src={logoOmnilink} alt="Logo Omnilink" className={classes.logo} />

          <Alert severity="error" className={classes.alert}>
              Sessão expirada, será necessário logar novamente.
          </Alert>
          <form onSubmit={handleLoginSubmit}>
            <BlueTextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="Usuário"
              name="email"
              required
              autoComplete="email"
              value={loginForm.email}
              onChange={handleInputChange}
              className={classes.formControl}
            />
            <BlueTextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              label="Senha"
              name="password"
              type="password"
              autoComplete="current-password"
              value={loginForm.password}
              onChange={handleInputChange}
              className={classes.formControl}
            />

            {loading ? (
              <div style={{ margin: 'auto', width: '50%' }}>
                <FacebookCircularProgress />
              </div>
            ) : (
              <BlueButton
                type="submit"
                fullWidth
                variant="contained"
                className={classes.button}
              >
                ENTRAR
              </BlueButton>
            )}

            {userMismatch && (
              <BlueButton
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleLogout}
                className={classes.button}
              >
                Sair
              </BlueButton>
            )}
          </form>
        </Box>
      </Modal>
    </>
  );
}
