/**
 * Função para validação do formulário de vistoria
 * @param {Object} vistoria
 */
export function validateVistoria(vistoria) {
  const newErrors = {};

  // valida os campos
  newErrors.equipamento = !vistoria?.equipamento
    ? { message: 'Informe o equipamento' }
    : undefined;
  newErrors.serie = !vistoria?.serie
    ? { message: 'Informe o número de série / id' }
    : undefined;
  newErrors.instalador = !vistoria?.instalador
    ? { message: 'Informe o nome do instalador' }
    : undefined;
  newErrors.tipo_servico = !vistoria?.tipo_servico
    ? { message: 'Selecione o tipo do serviço' }
    : undefined;
  newErrors.local_servico = !vistoria?.local_servico
    ? { message: 'Informe o local do serviço' }
    : undefined;
  newErrors.placa = !vistoria?.placa
    ? { message: 'Informe a placa do veículo' }
    : undefined;
  newErrors.ano = !vistoria?.ano
    ? { message: 'Informe o ano do veículo' }
    : undefined;
  newErrors.cor = !vistoria?.cor
    ? { message: 'Informe a cor do veículo' }
    : undefined;
    // newErrors.id_fabricante = !vistoria?.id_fabricante
    // ? { message: 'Informe a marca do veículo' }
    // : undefined;
    // newErrors.id_modelo = !vistoria?.id_modelo
    // ? { message: 'Informe o modelo do veículo' }
    // : undefined;
  newErrors.tipo_veiculo = !vistoria?.tipo_veiculo
    ? { message: 'Informe o tipo do veículo' }
    : undefined;

  newErrors.lanternas_traseiras = !vistoria?.lanternas_traseiras
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.lanternas_dianterias = !vistoria?.lanternas_dianterias
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.luz_freio = !vistoria?.luz_freio
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.luz_re = !vistoria?.luz_re
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.pisca_alerta = !vistoria?.pisca_alerta
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.farol_baixo = !vistoria?.farol_baixo
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.vidros_porta_traseira = !vistoria?.vidros_porta_traseira
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.vidros_portas_dianteiras = !vistoria?.vidros_portas_dianteiras
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.vidro_traseiro = !vistoria?.vidro_traseiro
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.retrovisor = !vistoria?.retrovisor
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.bancos = !vistoria?.bancos
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.buzina = !vistoria?.buzina
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.cond_painel = !vistoria?.cond_painel
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.outros_equipamentos = !vistoria?.outros_equipamentos
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.velocimetro = !vistoria?.velocimetro
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.computador_bordo = !vistoria?.computador_bordo
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.limpador_dianteiro = !vistoria?.limpador_dianteiro
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.acendedor_cigarro = !vistoria?.acendedor_cigarro
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.sistema_som = !vistoria?.sistema_som
    ? { message: 'Campo obrigatório' }
    : undefined;
  newErrors.nivel_combustivel =
    vistoria?.nivel_combustivel === null ||
    vistoria?.nivel_combustivel === undefined
      ? { message: 'Informe o nível do combustível' }
      : undefined;
  newErrors.clienteNome = !vistoria?.clienteNome
    ? { message: 'Informe o nome do conferente' }
    : undefined;
  newErrors.clienteCpfCnpj = !vistoria?.clienteCpfCnpj
    ? { message: 'Informe o CPF do conferente' }
    : undefined;
  // remove campos vazios
  Object.keys(newErrors).map((key) => {
    if (!newErrors[key]) delete newErrors[key];
  });

  return newErrors;
}
