import React, { useState, useEffect } from 'react';
import { Container, TextField, Grid, CircularProgress } from '@material-ui/core';
import BlueButton from '../../components/button/BlueButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import senhaSupervisorService from '../../Services/SenhaSupervisor/senha-supervisor-service';
import CustomAlert from '../../components/alert/CustomAlert';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
    },
}));

export default function SenhaSupervisorComponent({ numeroEquipamento, usuario, dadosSenhaSupervisor, mensagem }) {
    const classes = useStyles();
    const [efeitoCarregandoAlterarSenha, setEfeitoCarregandoAlterarSenha] = useState(false);
    const [efeitoCarregandoBuscarSenha, setEfeitoCarregandoBuscarSenha] = useState(false);
    const [novaSenha, setNovaSenha] = useState('');
    const [senhaSupervisor, setSenhaSupervisor] = useState('');
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');
    const [mensagemRetorno, setMensagemRetorno] = useState(false);

    useEffect(() => {
        if (mensagem != '') {
            setMensagemRetorno(true);
        }
        setSenhaSupervisor(dadosSenhaSupervisor.senhaSuper || '');
    }, [dadosSenhaSupervisor, mensagem]);

    const buscaSenhaSupervisor = async () => {
        setEfeitoCarregandoBuscarSenha(true);
        const response = await senhaSupervisorService.buscaSenhaSupervisor(numeroEquipamento, usuario);

        if (!response.errorLogin) {
            if (response.error == false) {
                setEfeitoCarregandoBuscarSenha(false);
                setSenhaSupervisor(response.dados.senhaSuper);
            } else {
                setEfeitoCarregandoBuscarSenha(false);
                setMensagemAlert({ title: 'Erro!', body: 'Senha não encontrada, tente novamente!' });
                setTipoAlert('error');
                setAbrirAlert(true);
            }
        } else {
            setMensagemRetorno(true);
        }
        setEfeitoCarregandoBuscarSenha(false);
    };

    const transmitirSenha = async () => {
        setEfeitoCarregandoAlterarSenha(true);
        try {
            const body = {
                desabilitar_GPS: '0',
                idTerminal: numeroEquipamento,
                senhaManobrista: '1234',
                senhaMotorista: '4321',
                senhaPanico: '9999',
                senhaSuper: novaSenha,
            };

            const response = await senhaSupervisorService.transmitirSenhaSupervisor(body, numeroEquipamento);
            setAbrirAlert(false);

            if (!response.errorLogin) {
                if (response.error) {
                    setMensagemAlert({ title: 'Erro!', body: response.message });
                    setTipoAlert('error');
                    setAbrirAlert(true);
                } else {
                    setNovaSenha('');
                    setMensagemAlert({ title: 'Sucesso!', body: 'Senha transmitida com sucesso!' });
                    setTipoAlert('success');
                    setAbrirAlert(true);
                    setMensagemRetorno(false);
                    buscaSenhaSupervisor();
                }
            }
            else {
                setMensagemAlert({ title: 'Erro!', body: response.message });
                setTipoAlert('error');
                setMensagemRetorno(true);
            }

            setEfeitoCarregandoAlterarSenha(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container style={{ marginBottom: 25 }}>
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            <Grid container spacing={3} style={{ marginBottom: 10 }} alignItems="center">
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={senhaSupervisor ? senhaSupervisor : '****'}
                        disabled
                        label="Senha Atual"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <BlueButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={efeitoCarregandoBuscarSenha || efeitoCarregandoAlterarSenha}
                        onClick={buscaSenhaSupervisor}
                        startIcon={efeitoCarregandoBuscarSenha ? <CircularProgress size={20} /> : <RefreshIcon />}
                        style={{ height: 55 }}
                    >
                        {efeitoCarregandoBuscarSenha ? ' Buscando senha...' : ' Buscar Senha'}
                    </BlueButton>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Nova Senha"
                        type="password"
                        value={novaSenha}
                        inputProps={{ maxLength: 4 }}
                        onChange={(event) => setNovaSenha(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <BlueButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={efeitoCarregandoAlterarSenha || efeitoCarregandoBuscarSenha || novaSenha.length !== 4}
                        onClick={transmitirSenha}
                        startIcon={efeitoCarregandoAlterarSenha ? <CircularProgress size={20} /> : <LockOpenIcon />}
                        style={{ height: 55 }}
                    >
                        {efeitoCarregandoAlterarSenha ? ' Enviando senha...' : ' Enviar Senha'}
                    </BlueButton>
                </Grid>
            </Grid>
            {mensagem && mensagemRetorno && <Alert severity='warning'>
                {mensagem}
            </Alert>}
        </Container>
    );
}
