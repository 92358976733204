import { Container, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react';
import authService from '../Services/auth/auth-service';
import clienteService from '../Services/cliente/cliente-service';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import CadastratoClientePage from './cadastro-cliente-page';

import FacebookCircularProgress from '../components/progress/CircularProgressBlue';

const useStyles = makeStyles({
  root: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  searchMobile: {
    marginTop: 10,
    width: '100%',
    paddingLeft: 0,
  },
  search: {
    marginTop: 10,
    width: 'auto',
    paddingLeft: 0,
  }
});

export default function ClientePage() {
  const classes = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));

  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);

    let usuario = authService.getUserInfo().usuario;

    console.log(usuario);
    // clientes(usuario.iD_VENDEDOR_CRM);
  };

  const [_clientes, setCliente] = useState([]);
  useEffect(() => {
    let usuario = authService.getUserInfo().usuario;

    console.log(usuario);
    clientes(usuario.tz_area_atendimento_microsigaid);
  }, []);

  const clientes = async (areaAtendimentoID) => {
    setLoad(true);
    try {
      let clientesResult = await clienteService.ClientesByRevenda(areaAtendimentoID);

      console.log(clientesResult);
      if (clientesResult) {
        let clientes = [];

        clientesResult.map((x) => {
          clientes.push({
            id: x.ClienteID || '',
            nome: x.Nome || '',
            cpfcnpj: x.CPFCNPJ || '',
            celular: x.Celular || '',
            telefone: x.Telefone || '',
          });
        });

        setCliente(clientes);
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
    setLoad(false);
  };

  return (
    <div>
      <MenuComponent />
      <Container component="main">
        <h1>Clientes</h1>

        <div style={{ paddingBottom: 10 }}>
          <div>
            <BlueButton variant="outlined" color="primary" onClick={handleClick}>
              <AddIcon />
              Cadastrar Cliente
            </BlueButton>
            <CadastratoClientePage isOpen={open} handleClose={handleClose} />
          </div>
        </div>
        {!load ? (
          <MaterialTable
            title="Clientes"
            columns={[
              { title: 'Nome', field: 'nome', width: 150 },
              { title: 'CPF/CNPJ', field: 'cpfcnpj', width: 150 },
              { title: 'Telefone', field: 'telefone', width: 150 },
              { title: 'Celular', field: 'celular', width: 150 },
            ]}
            data={_clientes}
            localization={{
              body: {
                emptyDataSourceMessage: 'Nenhum registro para exibir',
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
              },
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{from}-{to} de {count}',
                firstTooltip: 'Primeira página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página',
              },
            }}
            components={{
              Toolbar: (props) => (
                <MTableToolbar
                  classes={
                    mobile
                      ? {
                        root: classes.root,
                        searchField: classes.searchMobile,
                      }
                      : {
                        searchField: classes.search,
                      }
                  }
                  {...props}
                />
              ),
            }}
          />
        ) : (
          <div style={{ marginLeft: 500, marginTop: 180 }}>
            {' '}
            <FacebookCircularProgress></FacebookCircularProgress>
          </div>
        )}
      </Container>
    </div>
  );
}
