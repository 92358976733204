import React from 'react';
import { FormControl, Grid, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  subTitle: {
    width: '100%',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 40,
    marginBottom: 20,
    textAlign: 'center',
  },
}));

export function VistoriaSaida({ vistoria }) {
  const classes = useStyles();

  return (
    <>
      <span className={classes.subTitle}>Vistoria de Saída - Itens</span>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="lanternas-dianteiras">
              Lanternas Dianteiras / Setas
            </InputLabel>
            <Select
              labelId="lanternas-dianteiras"
              native
              value={vistoria.lanternas_dianterias_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="lanternas-traseiras">
              Lanternas Traseiras / Setas
            </InputLabel>
            <Select
              labelId="lanternas-traseiras"
              native
              value={vistoria.lanternas_traseiras_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="luz-freio">Luz de Freio / Brake Light</InputLabel>
            <Select
              labelId="luz-freio"
              native
              value={vistoria.luz_freio_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="luz-re">Luz de Ré</InputLabel>
            <Select
              labelId="luz-re"
              native
              value={vistoria.luz_re_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="pisca-alerta">Pisca Alerta</InputLabel>
            <Select
              labelId="pisca-alerta"
              native
              value={vistoria.pisca_alerta_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="farois">Farois Alto e Baixo (e Lampelo)</InputLabel>
            <Select
              native
              labelId="farois"
              value={vistoria.farol_baixo_saida || ''}
            >
              <optgroup>
                <option value={''} />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="vidros-port-tras">Vidros Portas Tras.</InputLabel>
            <Select
              native
              labelId="vidros-port-tras"
              value={vistoria.vidros_porta_traseira_saida || ''}
            >
              <optgroup>
                <option value={''} />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="vidros-port-diant">Vidros Portas Diant.</InputLabel>
            <Select
              native
              labelId="vidros-port-diant"
              value={vistoria.vidros_portas_dianteiras_saidas || ''}
            >
              <optgroup>
                <option value={''} />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="vidro-traseiro">Vidro Traseiro</InputLabel>
            <Select
              native
              labelId="vidro-traseiro"
              value={vistoria.vidro_traseiro_saida || ''}
            >
              <optgroup>
                <option value={''} />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="retrovisores">
              Retrovisor Motorista e Carona
            </InputLabel>
            <Select
              native
              labelId="retrovisores"
              value={vistoria.retrovisor_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="bancos-tapetes">
              Bancos, Estofados e Tapetes
            </InputLabel>
            <Select
              native
              labelId="bancos-tapetes"
              value={vistoria.bancos_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="buzina">Buzina</InputLabel>
            <Select
              native
              labelId="buzina"
              value={vistoria.buzina_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="cond-painel">Condições do Painel</InputLabel>
            <Select
              native
              labelId="cond-painel"
              value={vistoria.cond_painel_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="outros-equipamentos">
              Outros Esquipamentos
            </InputLabel>
            <Select
              native
              label="outros equips"
              value={vistoria.outros_equipamentos_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="velocimetro">Velocimetro</InputLabel>
            <Select
              native
              labelId="velocimetro"
              value={vistoria.velocimetro_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="comp-bordo">Computador de Bordo</InputLabel>
            <Select
              native
              labelId="comp-bordo"
              value={vistoria.computador_bordo_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="limpadores">Limp. de Vidro (Dia/Tra)</InputLabel>
            <Select
              native
              labelId="limpadores"
              value={vistoria.limpador_dianteiro_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="acendedor-cigarro">Acendedor de Cigarro</InputLabel>
            <Select
              native
              labelId="acendedor-cigarro"
              value={vistoria.acendedor_cigarro_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="sistema-som">Sistema de Som</InputLabel>
            <Select
              native
              labelId="sistema-som"
              value={vistoria.sistema_som_saida || ''}
            >
              <optgroup>
                <option value="" />
                <option value={'CF'}>Conforme</option>
                <option value={'NC'}>Não conforme</option>
                <option value={'NP'}>Não possui</option>
              </optgroup>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
