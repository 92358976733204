import React, { useState } from 'react';
import { Container, TextField, Grid, CircularProgress, Tooltip,IconButton, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import BlueButton from '../../components/button/BlueButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import CustomAlert from '../../components/alert/CustomAlert';
import ativacaoService from '../../Services/Ativacao/ativacao-service';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export default function FormularioAtivacaoComponent() {
  const [opcaoForm, setOpcaoForm] = useState(null);
  const [numSerie, setNumSerie] = useState(null);
  const [formAtivacao, setFormAtivacao] = useState(false);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [efeitoCarregandoInicio, setEfeitoCarregandoInicio] = useState(false);
  const [efeitoCarregandoPesquisar, setEfeitoCarregandoPesquisar] = useState(false);
  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setNumSerie(text);
      setMensagemAlert({ title: 'Sucesso!', body: 'Número de série colado com sucesso!' });
      setTipoAlert('success');
      setAbrirAlert(true);
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Falha ao colar o número de série' });
      setTipoAlert('error');
      setAbrirAlert(true);
    }
  };
  const handleFormAtivacao = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const numSerie = data.get('txtSerie');

    if(numSerie === ''){
      setMensagemAlert({ title: 'Falha!', body: 'É obrigatório o preenchimento do número da série.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar o formulário. Tente novamente.' });
    setTipoAlert('error');
    setAbrirAlert(true);

    if (opcaoForm === 1) {
      setEfeitoCarregandoInicio(true);
      try {
        const inventarioResult = await ativacaoService.ultimoInventario(numSerie);
        if (inventarioResult?.status === '-1') {
          let errorMessage = inventarioResult.erro;
          if (errorMessage.startsWith("Nao existe inventario valido")) {
            errorMessage = "Não existe inventário válido para o formulário de ativação enviado.";
          }
          setMensagemAlert({ title: 'Erro!', body: errorMessage });
          setTipoAlert('error');
          setAbrirAlert(true);
        } else {
          setFormAtivacao(true);
          setNumSerie(numSerie);
        }
      } catch (error) {
        setMensagemAlert({ title: 'Erro!', body: 'Erro ao iniciar a ativação. Tente novamente.' });
        setTipoAlert('error');
        setAbrirAlert(true);
      } finally {
        setEfeitoCarregandoInicio(false);
      }
    } else if (opcaoForm === 2) {
      setEfeitoCarregandoPesquisar(true);
      try {
        const statusResult = await ativacaoService.status(numSerie);
        if (statusResult?.status === 0) {
          let successMessage = statusResult.historico;
          if (successMessage.startsWith("STATUS DA ATIVAÇÃO: CONCLUIDO")) {
            successMessage = "Status da ativação: Concluído com sucesso.";
          }
          setMensagemAlert({ title: 'Sucesso!', body: successMessage });
          setTipoAlert('success');
        } else {
          setMensagemAlert({ title: 'Erro!', body: statusResult.historico });
          setTipoAlert('error');
        }
        setAbrirAlert(true);
      } catch (error) {
        setMensagemAlert({ title: 'Erro!', body: 'Erro ao pesquisar a ativação. Tente novamente.' });
        setTipoAlert('error');
        setAbrirAlert(true);
      } finally {
        setEfeitoCarregandoPesquisar(false);
      }
    }
  };

  const handleEnviaFormularioAtivacao = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    const dataFormAtivacao = {
      NumSerie: numSerie,
      Placa: data.get('txtPlaca'),
      Renavam: data.get('txtRenavam'),
      Chassi: data.get('txtChassi'),
      CorVeiculo: data.get('txtCorVeiculo'),
      Hodometro: data.get('txtHodometro'),
      Celular: data.get('txtCelular'),
      ICCDAnterior1: data.get('txtICCD1'),
      ICCDAnterior2: data.get('txtICCD2'),
      NumSerieAnterior: data.get('txtNumSerieAnterior'),
      NumAntenaAnterior: data.get('txtNumAntena'),
      ModeloVeiculo: data.get('txtModeloVeiculo'),
      TipoServicoAtivacao: data.get('drpTipoServico'),
      TipoVeiculo: data.get('drpTipoVeiculo'),
      NumNA: data.get('txtNA'),
    };

    setEfeitoCarregandoPesquisar(true);
    try {
      const addResult = await ativacaoService.addFormAtivacao(JSON.stringify(dataFormAtivacao));
      setMensagemAlert({ title: 'Sucesso!', body: 'Formulário enviado com sucesso!' });
      setTipoAlert('success');
      setAbrirAlert(true);
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao enviar o formulário. Tente novamente.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    } finally {
      setEfeitoCarregandoPesquisar(false);
    }
  };

  return (
    <Container style={{ padding: '5px' }}>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <form onSubmit={handleFormAtivacao}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={3}>
            <TextField
              name="txtSerie"
              label={
                <span>
                  Número de Série <span style={{ color: 'red' }}>*</span>
                </span>
              }
              variant="outlined"
              fullWidth
              value={numSerie}
              onChange={(e) => setNumSerie(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Colar número de série">
                    <IconButton onClick={handlePaste} edge="end">
                      <ContentPasteIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={3}>
            <BlueButton
              fullWidth
              color="primary"
              type="submit"
              style={{ height: 55 }}
              onClick={() => setOpcaoForm(1)}
              startIcon={efeitoCarregandoInicio ? <CircularProgress size={20} /> : <RefreshIcon />}
            >
              <span style={{ fontSize: '0.7rem' }}> {efeitoCarregandoInicio ? ' INICIANDO...' : ' INICIAR ATIVAÇÃO'}</span>
            </BlueButton>
          </Grid>
          <Grid item xs={12} md={5} lg={3}>
            <BlueButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              style={{ height: 55 }}
              onClick={() => setOpcaoForm(2)}
              startIcon={efeitoCarregandoPesquisar ? <CircularProgress size={20} /> : <SearchIcon />}
            >
              <span style={{ fontSize: '0.7rem' }}>{efeitoCarregandoPesquisar ? ' PESQUISANDO...' : ' PESQUISAR ATIVAÇÃO'}</span>
            </BlueButton>
          </Grid>
        </Grid>
      </form>

      {formAtivacao && (
        <form onSubmit={handleEnviaFormularioAtivacao}>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Tipo de Serviço</InputLabel>
                <Select name="drpTipoServico" label="Tipo de Serviço">
                  <MenuItem value={1}>Instalação/Reinstalação</MenuItem>
                  <MenuItem value={2}>Manutenção</MenuItem>
                  <MenuItem value={3}>Troca de RI</MenuItem>
                  <MenuItem value={4}>Desinstalação</MenuItem>
                  <MenuItem value={5}>Instalação de acessório</MenuItem>
                  <MenuItem value={6}>Upgrade</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Tipo de Veículo</InputLabel>
                <Select name="drpTipoVeiculo" label="Tipo de Veículo">
                  <MenuItem value={0}>Motocicleta</MenuItem>
                  <MenuItem value={1}>Veículo de Passeio</MenuItem>
                  <MenuItem value={2}>Caminhão</MenuItem>
                  <MenuItem value={3}>Carreta</MenuItem>
                  <MenuItem value={4}>Volksnet</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField required
                name="txtPlaca"
                label="Placa"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtRenavam"
                label="Renavam"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtChassi"
                label="Chassi"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtCorVeiculo"
                label="Cor do Veículo"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtModeloVeiculo"
                label="Modelo do Veículo"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtCelular"
                label="Celular"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtICCD1"
                label="ICCD1 Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtICCD2"
                label="ICCD2 Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtNumSerieAnterior"
                label="Número de Série Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                name="txtNumAntena"
                label="Número Antena Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtHodometro"
                label="Hodômetro"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtNA"
                label="NA"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} sm={3} style={{ textAlign: 'right', marginTop: '20px' }}>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                style={{ height: 55 }}
                startIcon={efeitoCarregandoPesquisar ? <CircularProgress size={20} /> : <SendIcon />}
              >
                <span style={{ fontSize: '0.7rem' }}>{efeitoCarregandoPesquisar ? ' ENVIANDO...' : ' ENVIAR'}</span>
              </BlueButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Container>
  );
}
