import React from 'react';
import { Grid } from '@material-ui/core';

export function AvlLatariaVistoria({ vistoria, classes }) {
  const veiculos = ['VN', 'PS', 'CR'];

  const dados = {
    A: 'Amassado',
    R: 'Riscado',
    Q: 'Quebrado',
    F: 'Faltante',
  };

  return veiculos.includes(vistoria?.tipo_veiculo) ? (
    <>
      <span className={classes.subTitle}>Avaliação lataria externa</span>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <strong>Dianteira esquerda: </strong>{' '}
          {dados[vistoria?.avl_dianteira_esquerda] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Dianteira direita: </strong>{' '}
          {dados[vistoria?.avl_dianteira_direita] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Traseira esquerda: </strong>{' '}
          {dados[vistoria?.avl_traseira_esquerda] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Traseira direita: </strong>{' '}
          {dados[vistoria?.avl_traseira_direita] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Roda dianteira esquerda: </strong>{' '}
          {dados[vistoria?.avl_roda_dianteira_esquerda] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Roda dianteira direita: </strong>{' '}
          {dados[vistoria?.avl_roda_dianteira_direita] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Roda traseira esquerda: </strong>{' '}
          {dados[vistoria?.avl_roda_traseira_esquerda] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Roda traseira direita: </strong>{' '}
          {dados[vistoria?.avl_roda_traseira_direita] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Porta dianteira esquerda: </strong>{' '}
          {dados[vistoria?.avl_porta_dianteira_esquerda] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Porta dianteira direita: </strong>{' '}
          {dados[vistoria?.avl_porta_dianteira_direita] || 'ok'}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Teto: </strong> {dados[vistoria?.avl_teto] || 'ok'}
        </Grid>
        {vistoria?.tipo_veiculo === 'CR' && (
          <>
            <Grid item xs={12} sm={6}>
              <strong>Capô: </strong> {dados[vistoria?.avl_capo] || 'ok'}
            </Grid>
            <Grid item xs={12} sm={6}>
              <strong>Roda baú traseira esquerda: </strong>{' '}
              {dados[vistoria?.avl_roda_traseira_bau_esquerda] || 'ok'}
            </Grid>
            <Grid item xs={12} sm={6}>
              <strong>Roda baú traseira direita: </strong>{' '}
              {dados[vistoria?.avl_roda_traseira_bau_direita] || 'ok'}
            </Grid>
            <Grid item xs={12} sm={6}>
              <strong>Baú teto: </strong>{' '}
              {dados[vistoria?.avl_bau_teto] || 'ok'}
            </Grid>
          </>
        )}
      </Grid>
    </>
  ) : (
    <></>
  );
}
