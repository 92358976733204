import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {  Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import ValidacaoUtil from '../util/ValidacaoUtil';
import receitaService from '../Services/cliente/receita-service';
import enderecoService from '../Services/endereco/endereco-service';
import useForm from '../hooks/useForm';
import InputMask from 'react-input-mask';
import Grid from '@material-ui/core/Grid';
import MaskUtil from '../util/MaskUtil';
import authService from '../Services/auth/auth-service';
import clienteService from '../Services/cliente/cliente-service';
import BlueButton from '../components/button/BlueButton';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import BlueTextField from '../components/textfield/BlueTextField';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';
import './CadastratoClientePage.css'; // Import the CSS file

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%', // Altere a largura para 100% para torná-la responsiva
    },
  },
}));

export default function CadastratoClientePage(props) {
  const classes = useStyles();

  const [load, setLoad] = React.useState(false);
  const [mensagemErro, setMensagem] = React.useState('');
  const [mensagemSucesso, setMensagemSucesso] = React.useState(''); 


  const [open, setOpen] = React.useState(props.isOpen);
  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);



  useEffect(async (e) => {

  }, []);


  const pesquisaCliente = async (e) => {

    let cpfcnpj = e.target.value;

    if (cpfcnpj) {

      if (ValidacaoUtil.validarCPFCNPJ(cpfcnpj)) {
        let cliente = await receitaService.pesquisaCNPJ(cpfcnpj);

      }

    }

  }

  const salvar = async () => {

    setLoad(true);

    console.log(inputs);
    let usuario = authService.getUserInfo().usuario;

    if (inputs) {
      let _validaDados = validaDados(inputs);

      if (_validaDados) {
        let telefone = String(inputs.txtTelefone).replace(/\D/g, '');
        let celular = String(inputs.txtCelular).replace(/\D/g, '');

        let cliente = {
          telefone: telefone.substr(2, telefone.length),
          celular: celular.substr(2, celular.length),
          ddtel: telefone.substr(0, 2),
          ddcel: celular.substr(0, 2),
          email: inputs.txtEmail,
          nome: inputs.txtNome,
          cep: inputs.txtCEP,
          endereco: inputs.txtEndereco,
          bairro: inputs.txtBairro,
          numero: inputs.txtNumero,
          cidade: inputs.txtCidade,
          estado: inputs.txtEstado,
          complemento: inputs.txtComplemento,
          referencia: inputs.txtReferencia,
          cpfcnpj: MaskUtil.cpfcnpjmask(inputs.txtCPFCNPJ),
          vendedorid: usuario.iD_VENDEDOR_CRM,
          AreaAtendimentoID: usuario.tz_area_atendimento_microsigaid
        };

        let cadastroCliente = await clienteService.add(JSON.stringify(cliente));

        if(cadastroCliente)
          setMensagemSucesso('Cliente cadastrado com Sucesso !!!');
        else
          setMensagem('Não foi possivel cadastrar o cliente'); 


        console.log(cadastroCliente);
      }

    }

    setLoad(false);

  }


  const handleFechar = () => {
    changeValue({
      txtCPFCNPJ: '',
      txtNome: '',
      txtTelefone: '',
      txtCelular: '', 
      txtEmail: '',
      txtCEP: '',
      txtEndereco: '',
      txtBairro: '',
      txtNumero: '',
      txtCidade: '',
      txtEstado: '',
      txtComplemento: '',
      txtReferencia: '',
    });

    props.handleClose();
  }

  const validaDados = (inputs) => {

    let campos = '';

    if (!inputs.txtCPFCNPJ)
      campos = '[CPF/CNPJ]';
    if (!inputs.txtNome)
      campos += ' [Nome]';
    if (!inputs.txtTelefone)
      campos += ' [Telefone]';
    if (!inputs.txtCelular)
      campos += ' [Celular]';
    if (!inputs.txtEmail)
      campos += ' [Email]';
    if (!inputs.txtCEP)
      campos += ' [CEP]';
    if (!inputs.txtEndereco)
      campos += ' [Endereço]';
    if (!inputs.txtBairro)
      campos += ' [Bairro]';
    if (!inputs.txtNumero)
      campos += ' [Número]';
    if (!inputs.txtCidade)
      campos += ' [Cidade]';
    if (!inputs.txtEstado)
      campos += ' [Estado]';
    if(!ValidacaoUtil.validarCPFCNPJ(MaskUtil.cpfcnpjmask(inputs.txtCPFCNPJ)))
      campos += ' [CPF/CNPJ Inválido]';


    if (campos.length > 0) {
      setMensagem(`Por favor preencher os campos -  ${campos}`);
      return false;
    }
    return true;
  }

  const pesquisaCEP = async (e) => {

    let cep = e.target.value;
    if (cep) {
      let endereco = await enderecoService.pesquisaCEP(cep);
      console.log(endereco);
      if (endereco) {

        changeValue({
          ...inputs,
          txtEndereco: endereco.logradouro,
          txtBairro: endereco.bairro,
          txtEstado: endereco.uf,
          txtCidade: endereco.localidade
        });

        console.log(inputs);
      }
    }
  }

  const { inputs, handleInputChange, handleSubmit, changeValue } = useForm(salvar);
  return (
    <div className="container">
      <Dialog
        open={open}
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Cliente</DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                name="txtCPFCNPJ"
                label="CPF/CNPJ"
                onBlur={pesquisaCliente}
                onChange={handleInputChange}
                value={inputs.txtCPFCNPJ}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BlueTextField
                required
                name="txtNome"
                label="Nome"
                onChange={handleInputChange}
                value={inputs.txtNome}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <InputMask
                required
                onChange={handleInputChange}
                value={inputs.txtTelefone}
                mask="(99)9999-9999"
              >
                {() => (
                  <BlueTextField
                    required
                    name="txtTelefone"
                    label="Telefone"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                )}
              </InputMask>
              
            </Grid>
            <Grid item xs={6} md={6}>
              <InputMask
                required
                onChange={handleInputChange}
                value={inputs.txtCelular}
                mask="(99)99999-9999"
              >
                {() => (
                  <BlueTextField
                    required
                    name="txtCelular"
                    label="Celular"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={12} md={6}>
              <BlueTextField
                required
                name="txtEmail"
                label="Email"
                onChange={handleInputChange}
                value={inputs.txtEmail}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} md={6}>
              <BlueTextField
                required
                name="txtCEP"
                label="CEP"
                onBlur={pesquisaCEP}
                onChange={handleInputChange}
                value={inputs.txtCEP}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={8} md={6}>
              <BlueTextField
                required
                name="txtEndereco"
                label="Endereço"
                onChange={handleInputChange}
                value={inputs.txtEndereco}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BlueTextField
                required
                name="txtBairro"
                label="Bairro"
                onChange={handleInputChange}
                value={inputs.txtBairro}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <BlueTextField
                required
                name="txtNumero"
                label="Número"
                onChange={handleInputChange}
                value={inputs.txtNumero}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BlueTextField
                required
                name="txtCidade"
                label="Cidade"
                onChange={handleInputChange}
                value={inputs.txtCidade}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <BlueTextField
                required
                name="txtEstado"
                label="Estado"
                onChange={handleInputChange}
                value={inputs.txtEstado}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BlueTextField
                name="txtComplemento"
                label="Complemento"
                onChange={handleInputChange}
                value={inputs.txtComplemento}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <BlueTextField
                name="txtReferencia"
                label="Referencia"
                onChange={handleInputChange}
                value={inputs.txtReferencia}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>

          <BlueButton onClick={handleFechar} style={{}} variant="contained" color="primary">
            <CancelIcon />
          Cancelar
          </BlueButton>

          <form onSubmit={handleSubmit} style={{ paddingLeft: 10 }} autoComplete="off">

            {!load ? 
              <BlueButton
                type="submit"
                variant="contained"
                color="primary"
              >
                <SaveIcon />
              SALVAR
              </BlueButton> :
              <FacebookCircularProgress></FacebookCircularProgress>
            }


          </form>
        </DialogActions>

        {mensagemErro.length > 0 && (<div>
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Erro</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {mensagemErro}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BlueButton onClick={() => setMensagem('')} variant="contained" color="primary">
              Ok
              </BlueButton>
            </DialogActions>
          </Dialog>
        </div>)}

        {mensagemSucesso.length > 0 && (<div>
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Sucesso</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {mensagemSucesso}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BlueButton onClick={() => setMensagemSucesso('')} variant="contained" color="primary">
              Ok
              </BlueButton>
            </DialogActions>
          </Dialog>
        </div>)}

      </Dialog>
    </div>
  );


}