import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class UsuarioService {
    async usuarioEstoqueAtivo(usuarioID) {
        try {
            const response = await fetch(`${_configAPITecnico.URI_API}/usuarioEstoqueLiberado/${usuarioID}`, {
                method: 'GET',
                headers: authService.headerAPITecnico(),
            });

            if (response.status === 200) return response.json();
            return {
                error: true,
                message: 'Usuário não encontrado.',
            };
        } catch (error) {
            return {
                error: true,
                message: 'Erro ao executar operação.',
            };
        }
    }
}

export default new UsuarioService();